import { FaApple, FaGooglePlay } from "react-icons/fa";
import "./DownloadButtons.css";

const DownloadButtons = () => {
  const handleAppStoreClick = () => {
    window.location.href =
      "https://apps.apple.com/us/app/online-kosova/id6447425775";
  };
  const handlePlayStoreClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.onlinekosova.android";
  };

  return (
    <div className="download-buttons">
      <div className="download-button-style">
        <button onClick={handleAppStoreClick}>
          <li>
            <FaApple />
          </li>
          <h1>Apple Store</h1>
        </button>
      </div>
      <div className="download-button-style" id="android-Button">
        <button onClick={handlePlayStoreClick}>
          <li>
            <FaGooglePlay />
          </li>
          <h1>Google Play</h1>
        </button>
      </div>
    </div>
  );
};

export default DownloadButtons;
