import { Rule } from "antd/es/form"
import { ColProps } from "antd/es/grid/col";

type ValidationType = {
    email: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps;
    };
}

export const inputs: ValidationType = {
    email: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                type: 'email',
                message: 'Ky e-mail nuk është i vlefshëm!',
            },
            {
                required: true,
                message: 'Ju lutemi vendosni E-mail-in!',
            },
        ],
    }
}

