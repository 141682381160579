import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {changePassword} from "../../redux/actions/passwordActions";
import  classes from './ChangePassword.module.css'
import {
    Button,
    Col,
    Form,
    Input,
    Row,
} from 'antd';

import { inputs } from "./changePasswordForm";
import {selectPasswordError, setDeactivateError, setPasswordError} from "../../redux/slices/authSlice";

const ChangePassword: React.FC = () => {

    const passwordError = useSelector(selectPasswordError);
    const [errorPassword, setErrorPassword] = useState(null)

    const [oldPassword, setOldPassword] = useState(null);
    const [password, setPassword] = useState(null);

    const [buttonClicked, setButtonClicked] = useState(false);


    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(setDeactivateError(null))
        dispatch(setPasswordError(null))
    }, []);

    const handleKeyDown: (event) => void = (event) => {
        if (event.key === "Enter") {
            console.log("Enter pressed");
            changePassword(event);
        }
    };

    const passwordChange: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {

        form.validateFields().then((values) => {
            // Check if the required field is filled
            if (values.requiredField) {
                return;
            }
            setButtonClicked(true);
            if(oldPassword === password){
                setErrorPassword('Përdorni një Fjalëkalim të ri!');
                return;
            }
            const payload = {
                oldPassword,
                newPassword: password
            };



            dispatch(changePassword(payload))
                .then(() => {
                    setOldPassword(null);
                    setPassword(null);
                })
                .catch((error) => {
                });

        }).catch((error) => {
            console.error('Validation failed:', error);
        });
    };

    const [form] = Form.useForm();

    const newPasswordHandler = (e) => {
        setPassword(e.target.value);
        setErrorPassword(null);
        setButtonClicked(false);
    }

    const oldPasswordHandler = (e) => {
        setOldPassword(e.target.value);
        setButtonClicked(false);
    }

    return (

        <div className={classes.mainDiv}>
            <Row>
                <Col span={24}>
                    <div className={classes.formDiv}>
                        <Form
                            form={form}
                            name="register"
                            onFinish={passwordChange}
                            scrollToFirstError>
                            <Form.Item
                                name="oldPassword"
                                label="Vendos fjalekalimin aktual"
                                className={classes.styledInput}
                                validateStatus={passwordError ? 'error' : ''}
                                help={passwordError}
                                {...inputs.oldPassword}
                                style={{maxWidth: '400px'}}
                               >
                                <Input.Password value={oldPassword} onChange={oldPasswordHandler} placeholder='Vendos fjalekalimin tuaj'/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Vendos Fjalekalimin e ri"
                                className={classes.styledInput}
                                validateStatus={errorPassword ? 'error' : ''}
                                help={errorPassword}
                                {...inputs.password}
                                style={{maxWidth: '400px'}}
                                >
                                <Input.Password value={password} onChange={newPasswordHandler} placeholder='Vendos fjalekalimin tuaj'/>
                            </Form.Item>
                            <Form.Item
                                className={classes.styledInput}
                                name="confirm"
                                label="Perseritni Fjalekalimin"
                                {...inputs.confirmPassword}
                                dependencies={['password']}
                                style={{maxWidth: '400px'}}
                                 >
                                <Input.Password  placeholder='Ju lutemi konfirmojeni fjalekalimin tuaj' />
                            </Form.Item>
                            <Form.Item
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                                style={{width: '100%', maxWidth:'400px', paddingTop: '1.5rem'}}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{width: '100%'}}
                                    onKeyDown={handleKeyDown}
                                    onClick={passwordChange}
                                    disabled={buttonClicked}
                                >
                                    Ruaj Ndryshimet
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>

    );
};

export default ChangePassword;
