import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectNews,
    selectActiveIndexNews,
    selectCurrentPageNews,
    selectActiveNews,
    setSearchValue
} from "../../redux/slices/newsSlice";
import {
    fetchNews,
    setFirstActiveNewsAction,
    nextActiveNewsAction,
    prevActiveNewsAction, addSavedNews, removeSavedNews, fetchNewsById
} from "../../redux/actions/newsActions";
import {AppDispatch} from "../../redux/store";
import {Button, Col, Input, message, Row, Typography} from 'antd';
import classes from './News.module.css'
import logo from '../../assets/images/logo.png'
import noImage from '../../assets/images/noNewsImage.png'
import {RxHamburgerMenu, RxDividerVertical} from 'react-icons/rx'
import {IoIosArrowRoundForward, IoIosShareAlt} from "react-icons/io";
import {Image} from 'antd';
import 'swiper/css';
import 'swiper/css/autoplay'
import {Mousewheel, Keyboard, Navigation, A11y, Autoplay} from 'swiper/modules';
import {SwiperNavButtons} from '../../components/SwiperNavButtons/SwiperNavButtons'
import {fetchCategories, fetchLatestPostByCategory} from "../../redux/actions/categoriesActions";
import {selectCategoriesData} from "../../redux/slices/categoriesSlice";
import {Card} from 'antd';
import NewsModal from "../../components/Modal/NewsModal/NewsModal"
import SideDrawer from "../../components/NewsPage/SideDrawer";
import ShareModal from '../../components/NewsPage/ShareModal';
import {selectAuthenticated, selectUserInfo} from "../../redux/slices/authSlice";
import {selectLatestPostByCategory} from '../../redux/slices/latestPostByCategorySlice'
import {loginAnonymously} from '../../redux/actions/auth'
import {HiArrowLeft} from "react-icons/hi2";
import {FaBookmark, FaRegBookmark} from "react-icons/fa";
import {useLocation} from "react-router-dom";

const News: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const news = useSelector(selectNews);
    const newsActiveIndex = useSelector(selectActiveIndexNews);
    const currentPage = useSelector(selectCurrentPageNews);
    const activeNews = useSelector(selectActiveNews);
    const currentUserId = useSelector(selectUserInfo);
    const swiperRef = useRef(null);
    const isAuthenticated = useSelector(selectAuthenticated);
    const [search, setSearch] = useState('');
    const categories = useSelector(selectCategoriesData);
    const [page, setPage] = useState(1);
    const [activeCategory, setActiveCategory] = useState('');
    const navigate = useNavigate();

    const [showNewsModal, setShowNewsModal] = useState(false);

    const sourceIds = localStorage.getItem('sourceIds');
    const country = localStorage.getItem('country');
    const {Search} = Input;

    useEffect(() => {
        const fetchData = () => {
            dispatch(fetchNews(0, activeCategory, sourceIds,country)).then(() => {
                dispatch(setFirstActiveNewsAction());
                resetSwiperActiveIndex();
            });
        }

        isAuthenticated
            ? fetchData()
            : dispatch(loginAnonymously())
                .then(fetchData)
                .catch((error) => {
                    console.error('Anonymous login failed:', error);
                });
    }, [activeCategory]);


    useEffect(() => {
        const fetchData = () => {
            dispatch(fetchNews(0, activeCategory, sourceIds,country)).then(() => {
                dispatch(setFirstActiveNewsAction());
                const id = new URLSearchParams(location.search).get('id');
                if(id){
                    dispatch(fetchNewsById(id));
                }
            });
            dispatch(fetchCategories());
            dispatch(fetchLatestPostByCategory());
        };


        isAuthenticated
            ? fetchData()
            : dispatch(loginAnonymously())
                .then(fetchData)
                .catch((error) => {
                    console.error('Anonymous login failed:', error);
                });
    }, [isAuthenticated]);


    const [shareUrl, setShareUrl] = useState(window.location.origin + location.pathname + location.search);
    const [title, setTitle] = useState('Online Kosova');

    useEffect(() => {
        const baseURL = new URL(shareUrl).origin;
        setShareUrl(`${baseURL}/news?id=${activeNews?.id}`)
        setTitle(activeNews?.title)
    }, [activeNews]);


    const latestPostByCategory = useSelector(selectLatestPostByCategory);

    useEffect(() => {
        if (news.length !== 0 && (newsActiveIndex === news.length - 3)) {
            dispatch(fetchNews(currentPage + 1, activeCategory, sourceIds,country));
        }
    }, [newsActiveIndex]);

    const resetSwiperActiveIndex = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0, 0); // Setting the slide index to 0
            swiperRef.current.swiper.activeIndex = null; // Setting active index to null
        }
    };


    const onNextNewsHandler = () => {
        dispatch(nextActiveNewsAction());
    }

    const onPrevNewsHandler = () => {
        dispatch(prevActiveNewsAction());
    }

    function convertTimestampToHoursAgo(timestamp: string): string {
        const parseTimestamp = new Date(timestamp);
        const currentTime = new Date();

        const timeDifferenceInMilliseconds = currentTime.getTime() - parseTimestamp.getTime();
        const timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);

        const minutesAgo = Math.floor(timeDifferenceInMinutes);
        if (minutesAgo < 60) {
            if (minutesAgo < 1) {
                return `tani`;
            }
            return `${minutesAgo} ${minutesAgo === 1 ? 'minut' : 'minuta'} më parë`;
        }


        const hoursAgo = Math.floor(timeDifferenceInMinutes / 60);
        return `${hoursAgo} orë më parë`;
    }
    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const getColor = (name) => {
        switch (name) {
            case 'Lajme':
                return '#0D5A03';
            case 'Politike':
                return '#285ADC';
            case 'Lifestyle':
                return '#1588FF';
            case 'Sport':
                return '#F4BF4E';
            case 'Showbiz':
                return '#0D5A03';
            case 'Te tjera':
                return 'red';
        }
    }

    const backButton = () => {
        setActiveCategory('');
    }

    const [placeholder, setPlaceholder] = useState('Kerko lajmin');
    const onFocus = () => {
        setPlaceholder(''); // Clear the placeholder when the input is focused
    };

    const onBlur = () => {
        if (!search) {
            setPlaceholder('Kerko lajmin'); // Restore the placeholder if the input is empty
        }
    };

    const handleInputChange = (e) => {
        setSearch(e.target.value);

        const trimmedValue = e.target.value.toString().trim();

        if(trimmedValue.length >3){
            dispatch(setSearchValue(e.target.value));
        }


    }

    const handleSearch = (query) => {

        const trimmedValue = query.toString().trim();

        if (trimmedValue.length >= 3) {
            navigate('/search')
        }
    }

    const [currentNew, setCurrentNew] = useState(null);

    const showNewsModalFunction = (currentNews) => {
        setCurrentNew(currentNews)
        setShowNewsModal(true);
    };

    const [favoriteStatus, setFavoriteStatus] = useState({});

    useEffect(() => {
        const initialFavoriteStatus = {};
        news.forEach(newsItem => {
            initialFavoriteStatus[newsItem.id] = newsItem.isFavorite || false;
        });
        setFavoriteStatus(initialFavoriteStatus);
    }, [news]);

    const handleBookmarkToggle = (currentNews) => {
        if(isAuthenticated) {
            const updatedFavoriteStatus = { ...favoriteStatus };
            updatedFavoriteStatus[currentNews.id] = !updatedFavoriteStatus[currentNews.id];
            setFavoriteStatus(updatedFavoriteStatus);

            if (updatedFavoriteStatus[currentNews.id]) {
                dispatch(addSavedNews(currentNews.id));
            } else {
                dispatch(removeSavedNews(currentNews.id));
            }
        } else {
            message.info('Ju lutemi kyquni per te ruajtur lajmet');
            navigate('/login');
        }
    };

    const [shareModalOpen, setShareModalOpen] = useState(false);

    const openShareModal = () => {
        setShareModalOpen(true);
    };
   
    const handleOpenApp = () => {
        // Determine if the user is on an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
        // Generate the appropriate app store URL based on the device
        const storeUrl = isIOS
          ? 'https://apps.apple.com/us/app/online-kosova/id6447425775' // Replace with your iOS App Store URL
          : 'https://play.google.com/store/apps/details?id=com.onlinekosova.android&hl=en_US&pli=1'; // Replace with your Play Store URL
    
        // Open the app store URL in a new tab
        window.open(storeUrl, '_blank');
      };

    return (
        <div>
            <NewsModal  showModal={showNewsModal} activeNews={currentNew} setShowModal={setShowNewsModal}/>
            <ShareModal showModal={shareModalOpen} shareUrl={shareUrl} setShowModal={setShareModalOpen}/>
            {activeNews && (
                <div style={{
                    width: '100%',
                    height: '100vh',
                    backgroundColor: activeNews.imageUrl || activeNews.imageUrl === '' ? 'black' : '',
                    backgroundImage: activeNews.imageUrl ? `url(${activeNews.imageUrl})` : '',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <SideDrawer isOpen={openDrawer} onClose={onCloseDrawer} isAuthenticated={isAuthenticated}/>
                    <div className={classes.centerDiv}>
                        <div className={classes.blurOverlay}/>
                        <Row className={classes.headerRow}>
                          
                            <Col xs={12} sm={18} md={12} lg={12} xl={12}>
        
                                <div className={classes.headerLogo}>
                                    <img src={logo} alt="logoImage"/>
                                    <h2>Online</h2>
                                    <h2>Kosova</h2>
                                </div>
                                <Button  style={{borderStyle:"none" ,backgroundColor:"transparent",marginLeft:"27px",display: window.innerWidth > 900? 'none' : 'block'}}onClick={handleOpenApp}>
                                <p style={{textAlign:"center", fontSize:"13px",color:"#EDB95E", }}>Download now !</p>


                                </Button>
                            
                            </Col>
                          
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div className={classes.headerRight}>
                                    <Search
                                        className={classes.styledSearchInput}
                                        placeholder={placeholder}
                                        onSearch = {handleSearch}
                                        value = {search}
                                        onChange={handleInputChange}
                                        enterButton
                                        style={{width: '100%'}}
                                        onFocus={onFocus}
                                        onBlur={onBlur}/>
                                    <RxDividerVertical style={{
                                        color: 'white',
                                        fontSize: '1.5rem'
                                    }}
                                    />
                                    <RxHamburgerMenu
                                        className={classes.styledHamburgerMenu}
                                        style={{
                                            color: 'white',
                                            fontSize: '1.5rem'
                                        }}
                                        onClick={showDrawer}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {activeCategory && (
                            <Row >
                                <Col span={24}>
                                    <div className={classes.backButton} onClick={backButton}>
                                        <HiArrowLeft
                                            fontSize='1.2rem'
                                            color='white'
                                        />
                                        <h2>Rikthehuni</h2>
                                    </div>
                                </Col>
                            </Row> )}
                            <Swiper
                                ref={swiperRef}
                                spaceBetween={20}
                                grabCursor='true'
                                keyboard={{
                                    enabled: true,
                                }}
                                slidesPerView={1}  // Ensure this is set to 1
                                mousewheel={{
                                    forceToAxis: true,
                                    releaseOnEdges: true,
                                    sensitivity: 1,  // Adjust sensitivity
                                    thresholdDelta: 10,  // Higher value means less sensitive to small scrolls
                                }}
                                freeMode={false}  // Ensure free mode is disabled
                                freeModeSticky={true}  // Ensure slider "snaps" to the slide
                                direction="vertical"
                                className={classes.swiperDiv}
                                modules={[Mousewheel, Keyboard, Navigation, A11y]}
                                onSlideNextTransitionStart={onNextNewsHandler}
                                onSlidePrevTransitionStart={onPrevNewsHandler}
                            >
                                    {news.map((currentNews) => (       
                            <SwiperSlide className={classes.swiperSliderDiv}>
                                    <Row className={classes.mainRow} gutter={[16, 8]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={classes.mainRowNews} onClick={() => showNewsModalFunction(currentNews)}>
                                            <div className={classes.categoriesDiv}>
                                                <div className={classes.categoriesBox}>
                                                    <h4>{currentNews.categories[0].name}</h4>
                                                </div>
                                                <div>
                                                    <h4>{convertTimestampToHoursAgo(currentNews.publishedDate)}</h4>
                                                </div>
                                            </div>
                                            <div className={classes.titleSection}>
                                                <h1>{currentNews.title}</h1>
                                            </div>
                                            <div className={classes.subtitleSection}>
                                                <h3>{currentNews.subTitle.length <= 150 ? currentNews.subTitle : currentNews.subTitle.slice(0, 250) + '...'}</h3>
                                            </div>
                                            <div className={classes.modalSection}>
                                                <h4 style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.2rem',
                                                    fontSize: '1rem',
                                                    fontWeight: '700',
                                                    color: '#1588FF'
                                                }}>
                                                    Lexoni lajmin e plote <IoIosArrowRoundForward style={{fontSize: '1.5rem'}}/>
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}
                                             className={classes.rightSectionNews}>
                                            <div className={classes.imageRowNews}>
                                                <div className={classes.mainImageRow}  style={{
                                                    backgroundImage: `url("${activeNews.imageUrl || noImage}")`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                </div>
                                                <div className={classes.sourceSaveShareSection}>
                                                    <div className={classes.sourceSection}>
                                                        <Image
                                                            preview={false}
                                                            width={'2rem'}
                                                            height={'2rem'}
                                                            src={currentNews.source.sourceImage}
                                                            style={{borderRadius: '50%', objectFit: 'cover'}}
                                                        />
                                                        <h4>
                                                            {currentNews.source.name}
                                                        </h4>
                                                    </div>
                                                    <div className={classes.saveShareSection}>
                                                        <div onClick={() => handleBookmarkToggle(currentNews)} className={classes.saveButton} >
                                                            {favoriteStatus[currentNews.id] ? (
                                                                <FaBookmark fontSize={'1.2rem'} color={'white'}  className={classes.isFavoriteIcon}/>
                                                            ) : (
                                                                <FaRegBookmark  fontSize={'1.2rem'} color={'white'}  className={classes.isNotFavoriteIcon}/>
                                                            )}
                                                        </div>
                                                        <div onClick={openShareModal} className={classes.shareButton}>
                                                            <IoIosShareAlt
                                                                fontSize={'1.5rem'}
                                                                color={'white'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <SwiperNavButtons/>
                                            </div>
                                        </Col>
                                    </Row>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <Row className={classes.categoryPart}>
                            <Col span={24}>
                                <div className={classes.bottomPart}>
                                    <h2>| TOP NGJARJET</h2>
                                    {/*<SwiperCategoryButtons/>*/}
                                </div>
                                <div>
                                    {categories && (
                                        <Swiper
                                            grabCursor='true'
                                            keyboard={{
                                                enabled: true,
                                            }}
                                            slidesperview={5}
                                            modules={[Keyboard, Navigation, A11y, Autoplay]}
                                            breakpoints={{
                                                // Define breakpoints and settings for different screen sizes
                                                1460: {
                                                    spaceBetween: 4,
                                                    slidesPerView: 4,
                                                },
                                                1120: {
                                                    spaceBetween: 4,
                                                    slidesPerView: 3,
                                                },
                                                768: {
                                                    spaceBetween: 20,
                                                    slidesPerView: 2,
                                                },
                                                480: {
                                                    spaceBetween: 20,
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            autoplay={{
                                                delay: 1000,
                                                disableOnInteraction: false,
                                                pauseOnMouseEnter: true,
                                            }}
                                        >
                                            {latestPostByCategory.content.map((category) => (
                                                <SwiperSlide>
                                                    <Card
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            width: '100%',
                                                            height: '5rem',
                                                            backgroundSize: 'cover', // You can adjust this as needed
                                                            backgroundRepeat: 'no-repeat', // You can adjust this as needed
                                                            borderRadius: 0,
                                                            border: 'none',
                                                            backgroundColor: 'transparent',
                                                            overflow: 'hidden',
                                                            paddingLeft: '-20px'
                                                        }}>
                                                        <div className={classes.categoriesBoxCard}
                                                             onClick={() => setActiveCategory(category.categories[0].id)}>
                                                            <div className={classes.categoriesCardName}>
                                                                <RxDividerVertical style={{
                                                                    color: getColor(category.categories[0].name),
                                                                    fontSize: '1.5rem'
                                                                }}/>
                                                                <h4 >{category.categories[0].name}</h4>
                                                            </div>
                                                            <div className={classes.categoriesPost}>
                                                                {category.title}
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </div>
    )
}

export default News;