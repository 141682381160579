import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RoleModel = {
    id: string;
    name: string;
}

type UserInfoModel = {
    id: string;
    role: RoleModel[];
    email: string;
    fullName: string;
    verified: boolean;
}

interface AuthState {
    email: string | null;
    token: string | null;
    authenticated: boolean;
    refreshToken: string | null;
    isAdmin: boolean;
    isUser: boolean;
    emailError: string | null;
    userInfo: UserInfoModel | null;
    isAnonymous: boolean;
    passwordError: string | null;
    deactivateError: string | null;
}

const initialState: AuthState = {
    token: null,
    email: null,
    authenticated: false,
    refreshToken: null,
    isAdmin: false,
    isUser:true,
    emailError: null,
    userInfo: null,
    isAnonymous: false,
    passwordError : null,
    deactivateError: null
};

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginReducer: (state, action: PayloadAction<AuthState>) => {
            state.email = action.payload.email;
            state.token = action.payload.token;
            state.authenticated= true;
            state.refreshToken = action.payload.refreshToken;
            state.isAnonymous = false;
        },
        refresh: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
        },
        logoutReducer: (state) => {
            state.email = null;
            state.token = null;
            state.authenticated= false;
            state.refreshToken = null;
            state.isAdmin = false;
            state.emailError = null;
            state.userInfo = null;
            state.isAnonymous = false;
            state.passwordError = null;
        },
        isAdminReducer: (state, action) => {
            state.isAdmin = action.payload;
        },
        isUserReducer: (state, action) => {
            state.isUser = action.payload;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setUserId: (state, action) => {
            state.userInfo = action.payload;
        },
        loginAnonymouslyReducer: (state, action) => {
            state.isAnonymous = true;
            state.token = action.payload.token;
            state.authenticated= false;
        },
        setPasswordError : (state, action) => {
            state.passwordError= action.payload;
        },
        setDeactivateError : (state, action) => {
            state.deactivateError= action.payload;
        },

    }
});

export const { loginReducer, logoutReducer, isAdminReducer,  refresh, setEmailError, setUserId, loginAnonymouslyReducer, setPasswordError, setDeactivateError } = authSlice.actions;

export const selectToken = (state: { user: AuthState }) => state.user.token;
export const selectEmail = (state: { user: AuthState }) => state.user.email;
export const selectAuthenticated = (state: { user: AuthState }) => state.user.authenticated;
export const selectRefreshToken = (state: { user: AuthState }) => state.user.refreshToken;
export const selectIsAdmin = (state: { user: AuthState }) => state.user.isAdmin;
export const selectIsUser = (state: { user: AuthState }) => state.user.isUser;
export const selectEmailError = (state: { user: AuthState }) => state.user.emailError;
export const selectUserInfo = (state: { user: AuthState }) => state.user.userInfo;
export const selectIsAnonymous  = (state: { user: AuthState }) => state.user.isAnonymous;
export const selectPasswordError = (state: {user: AuthState}) => state.user.passwordError;
export const selectDeactivateError = (state: {user: AuthState}) => state.user.deactivateError;
export default authSlice.reducer;



