import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SourceModel {
    id: string;
    name: string;
    url : string;
    country:string;
    sourceImage: string;
}

interface SourceDataState {
    data: {
        content: SourceModel[],
        totalPages: number,
        totalElements: number
    }
    loading: boolean;
    currentPage:number;
}

const initialSourceDataState: SourceDataState = {
    data: {
        content: [],
        totalPages: 0,
        totalElements: 0
    },
    loading: false,
    currentPage: 1
};

export const sourceSlice = createSlice({
    name: "sourceData",
    initialState: initialSourceDataState,
    reducers: {
        addSourceReducers: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setSources: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setSourceLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setSourceCurrentPage: (state, action) => {
            state.currentPage = action.payload
        }
    }
});

export const { addSourceReducers, setSources, setSourceLoading , setSourceCurrentPage } = sourceSlice.actions;

export const selectSourceData = (state: { sourceData: SourceDataState }) => state.sourceData.data;

export const selectSourceLoading = (state: { sourceData: SourceDataState }) =>
    state.sourceData.loading;

export default sourceSlice.reducer;
