import { Rule } from "antd/es/form"
import { ColProps } from "antd/es/grid/col";

type ValidationType = {
    name: {
        label: string,
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps,
    }
}

export const inputs: ValidationType ={
    name: {
        label: 'Vendos emrin e ri',
        rules: [{required: true, message:'Ju lutemi vendosni emrin e ri'}],
        wrapperCol:{
            span:24
        },
        labelCol: {
            span: 24
        }
    }
}