import "./AppUse.css";
import logo from "../../../assets/images/Picture1.png";
import { FaAngleDown, FaAngleRight, FaAngleUp } from "react-icons/fa";

const AppUse = () => {
  return (
    <div className="appUse-section">
      <div className="appUse-left-section specialized-in">
        <h1 id="title">
        Përdorimi i<br/>
          <span className="yellowText">
         Aplikacionit
          </span>
        </h1>

        <div className="steps-section">
          <div className="steps-description">
            
              <li className="slide-in-top">
                <FaAngleDown />
              </li>
            <h1>Lëvizni poshtë</h1>
            <p>Lajmet e fundit</p>
          </div>
          <div className="steps-description">
            
              <li className="slide-in-top">
                <FaAngleUp />
              </li>
  
            <h1>Lëvizni lartë</h1>
            <p>Lajmet e mëparshme</p>
          </div>
          <div className="steps-description">
         
              <li className="slide-in-top">
                <FaAngleRight />
              </li>
         
            <h1>Klikoni mbi titull</h1>
            <p>Lajmi i plotë në platformë</p>
          </div>
        </div>
      </div>

      <div className="appUse-right-section">
        <img src={logo} alt="" className="slide-in-right"/>
      </div>
    </div>
  );
};

export default AppUse;
