import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Button, DatePicker, Form, Input, Modal, Select, Upload} from 'antd';
import classes from './AddEditPostModal.module.css'

import {selectCategoriesData} from "../../redux/slices/categoriesSlice";
import {selectSourceData} from "../../redux/slices/sourceSlice";
import {updatePost} from "../../redux/actions/postsActions";
import {UploadOutlined} from "@ant-design/icons";
import type {AppDispatch} from '../../redux/store';
import dayjs from 'dayjs';
import {UploadProps} from "antd/es/upload/interface";


interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    editingPostRecord: { id: string, publishedDate: string, title: string, subTitle: string, imageUrl: string, categories: { id: string, name: string, categoryImage: string }[], source: { id: string, name: string, url: string, sourceImage: string }, originalPostUrl: string }
}


const EditPostsModal: React.FC<Props> = ({showModal, setShowModal, editingPostRecord}) => {

    const dispatch = useDispatch<AppDispatch>();
    const [editingPost, setEditingPost] = useState(editingPostRecord);

    const [form] = Form.useForm();

    // useEffect(() => {
    //     if (showModal) {
    //         setEditingPost(editingPostRecord);
    //         console.log("useEffect", editingPost)
    //     }
    // }, [showModal]);


    useEffect(() => {
        console.log("EDITING POST", editingPostRecord);
        setEditingPost(editingPostRecord);
        if(showModal){
            const values = {
                title: editingPostRecord?.title,
                subTitle: editingPostRecord?.subTitle,
                originalPostUrl: editingPostRecord?.originalPostUrl,
                categories: editingPostRecord?.categories[0]?.name,
                source: editingPostRecord?.source.id
            }
            form.setFieldsValue(values);
        }
    }, [editingPostRecord, form, showModal]);

    useEffect(() => {
        return () => {
            form.resetFields();
            setEditingPost(null);
        }
    }, []);

    const categoriesData = useSelector(selectCategoriesData);
    const sourcesData = useSelector(selectSourceData);

    const [categoryIds, setCategoryIds] = useState<any[]>([]);
    const [sourceId, setSourceId] = useState<string>('');

    const [saveChangesButton, setSaveChangesButton] = useState<boolean>(true);

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [fileList, setFileList] = useState<any>([]);
    const [isValidURL, setIsValidURL] = useState(true);

    const handleOk = (editingPost: any) => {

        const formData = new FormData();
        formData.append('file', selectedFile);

        console.log(editingPost)


        const payload = {
            id: editingPost.id,
            title: editingPost.title,
            subTitle: editingPost.subTitle,
            originalPostUrl: editingPost.originalPostUrl,
            imageUrl: editingPost.imageUrl,
            categoryIds: categoryIds.length > 0 ? categoryIds : [editingPost.categories[0].id],
            sourceId: editingPost.source.id,
            publishedDate: editingPost.publishedDate
        }
        dispatch(updatePost(payload, selectedFile !== null ? formData : ''))
        setShowModal(false);
        setEditingPost(null);
        setSaveChangesButton(true);
        setFileList(null);
    };

    const handleCancel = () => {
        setFileList(null);
        setSelectedFile(null);
        setShowModal(false);
    };

    const categoryChangeHandler = (event: any) => {
        // console.log(event);

        setCategoryIds([event])
        setEditingPost(prev => {
            setSaveChangesButton(false);
            return {...prev, categories: [...categoryIds]};
        })
    }


    const selectCategoryOption: any[] = [];
    for (let i = 0; i < categoriesData.content?.length; i++) {
        selectCategoryOption.push({
            label: categoriesData.content[i].name,
            value: categoriesData.content[i].id
        });
    }

    const selectSourceOption: any[] = [];
    for (let i = 0; i < sourcesData.content?.length; i++) {
        selectSourceOption.push({
            label: sourcesData.content[i].name,
            value: sourcesData.content[i].id
        });
    }

    const props: UploadProps = {

        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            setSaveChangesButton(false);
            let newFileList = [...info.fileList].slice(-1);


            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList(newFileList);

            setSelectedFile(newFileList[0].originFileObj);
        }
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    }

    const onChangeDateHandler = (value) => {
        setEditingPost(prev => {
            setSaveChangesButton(false);
            return {...prev, publishedDate: value?.toISOString()};
        })
    };

    const originalPostUrlChangeHandler = (event) => {
        const inputValue = event.target.value;
        setSaveChangesButton(false)
        setEditingPost(prev => {
            return {...prev, originalPostUrl: inputValue}
        })
        if (inputValue === '') {
            setIsValidURL(true)
        } else {
            setIsValidURL(isLink(inputValue));
        }
    }


    function isLink(str) {
        const regex = /^((http[s]?|ftp):\/\/)?(www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(:[0-9]+)?((\/\S+)*)?$/;
        return regex.test(str);
    }

    return (
        <Modal destroyOnClose={true} maskStyle={{backgroundColor: "hsla(0, 3%, 13%,0.1)"}} centered
               bodyStyle={{height: 620}}
               open={showModal} onOk={form.submit} onCancel={handleCancel}
               width={800} okText="Save Changes"
               okButtonProps={{
                   // style: {backgroundColor: '#2561F9', borderColor: '#24a0ed' },

                   style: {
                       backgroundColor: saveChangesButton ? '#D2D7D3' : '#2561F9',
                       color: saveChangesButton ? 'black' : 'white'
                   },
                   disabled: saveChangesButton || !isValidURL
               }}
        >
            <div className={classes.modalHeader}>
                <h2>Edit Post</h2><h4>Here you can edit posts</h4>
            </div>
            <div className={classes.body}>
                <Form
                    preserve={false}
                    id='post-editor-form'
                    name='basic'
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    autoComplete="off"
                    form={form}
                    onFinish={() => handleOk(editingPost)}

                >
                    <Form.Item
                        name="title"
                        className={classes.formItem}
                        rules={[{required: true, message: 'Please input title!'}]}
                    >
                        <Input id='title' className={classes.titleArea} name='title' placeholder='Post title (required)'
                               onChange={(e) => {
                                   setSaveChangesButton(false)
                                   setEditingPost(prev => {
                                       return {...prev, title: e.target.value}
                                   })
                               }} style={{resize: 'none'}}/>
                    </Form.Item>

                    <Form.Item
                        name="subTitle"
                        className={classes.formItem}
                        rules={[{required: true, message: 'Please input subtitle!'}]}
                    >
                        <Input id='subtitle' className={classes.subtitleArea} name='subtitle'
                               placeholder='Subtitle (required)'
                               onChange={(e) => {
                                   setSaveChangesButton(false)
                                   setEditingPost(prev => {
                                       return {...prev, subTitle: e.target.value}
                                   })
                               }} style={{resize: 'none'}}/>
                    </Form.Item>

                    <Form.Item
                        name="originalPostUrl"
                        className={classes.formItem}
                        rules={[{required: true, message: 'Please input original post url!'}]}
                    >
                        <Input className={classes.originalPostUrlArea} placeholder='Originali Post Url'
                               name='originalPostUrl'

                            //        onChange={(e)=>{
                            //     setSaveChangesButton(false)
                            //     setEditingPost(prev =>{
                            //         return{ ...prev, originalPostUrl:e.target.value}})
                            // }}
                               onChange={originalPostUrlChangeHandler}
                               style={{resize: 'none'}}/>
                    </Form.Item>
                    <div className={classes.isValidUrl}>
                        {!isValidURL && (
                            <p style={{color: 'red'}}>Input is not a valid URL</p>
                        )}
                    </div>
                    <Form.Item
                        label='Date and time'
                        rules={[{required: true, message: 'Please select a time!'}]}
                        className={classes.datePicker}
                    >
                        <DatePicker
                            showTime
                            defaultValue={editingPost?.publishedDate && dayjs(editingPost.publishedDate)}
                            allowClear={false}
                            disabledDate={disabledDate}
                            onChange={onChangeDateHandler}/>

                    </Form.Item>
                    <div className={classes.secondRow}>

                        <div className={classes.dropdownsSelect}>


                            <Form.Item
                                label="Category"
                                name="categories"
                                className={classes.selectInputForm}
                                rules={[{required: true, message: 'Please select category!'}]}
                            >
                                <Select
                                    className={classes.selectInput}

                                    bordered={false}
                                    style={{
                                        width: 150,
                                    }}
                                    onChange={categoryChangeHandler}
                                    options={selectCategoryOption}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Sources"
                                name="source"
                                className={classes.selectInputForm}
                                rules={[{required: true, message: 'Please select source!'}]}
                            >
                                <Select
                                    className={classes.selectInput}
                                    bordered={false}
                                    style={{
                                        width: 150
                                    }}
                                    onChange={(event) => {
                                        setSourceId(event)
                                        setSaveChangesButton(false)
                                    }}
                                    options={selectSourceOption}
                                />
                            </Form.Item>
                            <Form.Item
                                label="File"
                                name="imageUrl"
                                className={classes.fileInput}
                                rules={[{required: false, message: 'Please select a image!'}]}
                            >
                                <Upload listType={"text"} {...props}>
                                    <Button className={classes.uploadICon} icon={<UploadOutlined/>}>Click to
                                        Upload</Button>
                                    <Upload listType={"picture-card"} showUploadList={{
                                        showRemoveIcon: false,
                                        showPreviewIcon: false,
                                        removeIcon: null,
                                        previewIcon: null
                                    }} {...props}>
                                    </Upload>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default EditPostsModal;