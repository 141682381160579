import "./Landing.css";
import Intro from "./sections/Intro/Intro";
import AboutUs from "./sections/About/AboutUs";
import AppUse from "./sections/AppUse/AppUse";
import WebSection from "./sections/WebSection/WebSection";
import Footer from "./sections/Footer/Footer";
import Advertisement from "./sections/Advertisement/Advertisement";

const LandingPage = () => {
  return (
    <div>
      <Intro />
      <AboutUs />
      <AppUse />
      <Advertisement />
      <WebSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
