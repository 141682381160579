import {Outlet, Navigate} from "react-router-dom";

import {selectToken} from "../redux/slices/authSlice";
import {useSelector} from "react-redux";


const PrivateRoutesUser = () => {


    const token = useSelector(selectToken);

    return (

        (token) ?  <Outlet/> : <Navigate to='/login'/>
    )

}





export default PrivateRoutesUser;