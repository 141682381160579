import { auth, signInWithEmailAndPassword } from "../../firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import {isAdminReducer, loginReducer, logoutReducer,setEmailError, loginAnonymouslyReducer} from "../slices/authSlice";
import axios from "axios";
import {message} from "antd";
import {getCurrentUser} from "./userAction";

export function login(data) {
  return function (dispatch) {
    return signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userAuth) => {
        dispatch(getCurrentUser());
        dispatch(getUserRole(data.email));
        return userAuth.user.getIdTokenResult(true).then((idTokenResult) => {
          const token = idTokenResult.token;
          const refreshToken = userAuth.user.refreshToken;
          if (refreshToken) {
            dispatch(
              loginReducer({
                email: data.email,
                token: token,
                refreshToken: refreshToken,
              })
            );
          } else {
            dispatch(logoutReducer());
          }
        });
      })
  };
}

export const loginAnonymously = () => {
  return async (dispatch) => {
    const auth = getAuth();

    try {
      const userCredential = await signInAnonymously(auth);
      const user = userCredential.user;
      const token = await user.getIdToken();
      localStorage.setItem('firebaseAuthToken', token);
     dispatch(loginAnonymouslyReducer(token));

    } catch (error) {
    }
  };
};

export const getUserRole = (payload) => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}users/isAdmin?email=${payload}`
    );
    if (response.status === 200) {
      console.log("res", response);
      await dispatch(isAdminReducer(response.data));
      return response;
    }
    if (!response.data) {
      message.config({
        maxCount: 1,
      });
      message.error("Ju nuk keni leje te kyqeni si Admin!");
    }
  } catch (err) {
    if (err.response.status >= 400) {
      message.config({
        maxCount: 1,
      });
      dispatch(setEmailError('Kontrolloni emailin!'))
    }
    throw new Error(err);
  }
};



