import { setCategories, setCategoriesLoading } from "../slices/categoriesSlice";
import { setLatestPostByCategory } from "../slices/latestPostByCategorySlice";
import { message } from "antd";
import { logoutReducer } from "../slices/authSlice";
import API from "../api";

export const fetchAlbanianCategories = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    dispatch(setCategoriesLoading(true));
    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}categories`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setCategories(response.data));
    dispatch(setCategoriesLoading(false));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

// export const createCategories =
//   (payload, imagePayLoad) => async (dispatch, getState) => {
//     try {
//       const token = getState().user.token;
//       const headers = {
//         Authorization: "Bearer " + token,
//       };
//       const response = await API.post(
//         `${process.env.REACT_APP_BACKEND_API}categories`,
//         payload,
//         { headers: headers }
//       );

//       if (response.status === 200) {
//         if (imagePayLoad) {
//           dispatch(categoriesImageUpload(imagePayLoad, response.data.id)).then(
//             () => {
//               dispatch(fetchCategories());
//             }
//           );
//         }
//         dispatch(fetchCategories());
//         message.success("Category added!");
//       } else if (response.status === 201) {
//         console.log(response);
//         alert(
//           "PENDING! Please wait and check it in the source page, before you add it again!!"
//         );
//         dispatch(fetchCategories());
//       } else {
//         alert("Please try again!");
//       }
//     } catch (err) {
//       throw new Error(err);
//     }
//   };

// export const categoriesImageUpload =
//   (payload, id) => async (dispatch, getState) => {
//     try {
//       const token = getState().user.token;
//       const headers = {
//         Authorization: "Bearer " + token,
//       };
//       const response = await API.post(
//         `${process.env.REACT_APP_BACKEND_API}categories/${id}/feature-image`,
//         payload,
//         { headers: headers }
//       );
//     } catch (err) {
//       throw new Error(err);
//     }
//   };

export const deleteAlbanianCategory =
  (payload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };
      const response = await API.delete(
        `${process.env.REACT_APP_BACKEND_API}categories/${payload}`,
        { headers }
      );

      if (response.status === 200) {
        message.success("Kategoria eshte fshire!");
        dispatch(fetchAlbanianCategories());
      }
    } catch (err) {
      if (err.response.status === 403) {
        message.error("Kategoria ka poste, nuk mund te fshihet!");
      }
      console.log(err);

      throw new Error(err);
    }
  };

// export const updateCategory = (payload) => async (dispatch, getState) => {
//   try {
//     const token = getState().user.token;
//     const headers = {
//       Authorization: "Bearer " + token,
//     };
//     const data = {
//       name: payload.name,
//       categoryImage: payload.categoryImage,
//     };
//     const response = await API.put(
//       `${process.env.REACT_APP_BACKEND_API}categories/${payload.id}`,
//       data,
//       { headers }
//     );
//     if (response.status >= 400) {
//       message.info("Kategoria nuk eshte ndryshu!");
//     }
//     dispatch(fetchCategories());
//   } catch (err) {
//     throw new Error(err);
//   }
// };

// export const getPostByCategoryId = (id) => async (dispatch, getState) => {
//   try {
//     const token = getState().user.token;
//     dispatch(setCategoriesLoading(true));
//     const response = await API.get(
//       `${process.env.REACT_APP_BACKEND_API}categories/${id}`,
//       {
//         headers: { Authorization: "Bearer " + token },
//       }
//     );
//     console.log(response.data);
//     dispatch(setCategories(response.data));
//     dispatch(setCategoriesLoading(false));
//   } catch (err) {
//     if (err.response.data.code === 401) {
//       message.config({
//         maxCount: 1,
//       });
//       dispatch(logoutReducer());
//     }
//     throw new Error(err);
//   }
// };

// export const fetchLatestPostByCategory = () => async (dispatch, getState) => {
//   try {
//     const token = getState().user.token;
//     const response = await API.get(
//       `${process.env.REACT_APP_BACKEND_API}posts/latest-by-category `,
//       {
//         headers: { Authorization: "Bearer " + token },
//       }
//     );
//     dispatch(setLatestPostByCategory(response.data));
//   } catch (err) {
//     throw new Error(err);
//   }
// };
