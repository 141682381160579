import styles from "./Header.module.css";
import { logoutReducer, selectEmail, selectUserInfo } from "../../../redux/slices/authSlice";
import { auth } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiArrowDownSLine } from "react-icons/ri";
import logo from "../../../assets/images/logo.png";
import { Button, Dropdown, Layout, Menu } from "antd";
import AdsCards from "../Cards/AdsCards";

interface Props {
  showAdsCards?: boolean;
}

export default function Header({ showAdsCards = true }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutOfApp = () => {
    dispatch(logoutReducer());
    auth.signOut();
    navigate("/login");
  };

  const currentUser = useSelector(selectUserInfo);

  const items = [
    {
      key: "1",
      label: (
        <Button onClick={logoutOfApp} type="link">
          
           Shkyçu
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.Header}>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className={styles.divHeader}>
          <img src={logo} alt="logo" style={{ width: "2rem" }} />
          <h2 style={{ fontSize: "1rem", color: "white", fontWeight: "500" }}>
            Online Kosova
          </h2>
        </div>
        <div style={{ justifyContent: "right", display: "flex" }}>
          <Dropdown
            className="button"
            menu={{ items }}
            placement="bottomRight"
            arrow
          >
            <Button
              style={{
                backgroundColor: "inherit",
                border: "inherit",
                color: "white",
                margin: " 1rem 1rem 3.5rem",
              }}
            >
              {currentUser.fullName}
              <RiArrowDownSLine />
            </Button>
          </Dropdown>
        </div>
        <div className={styles.divLine}>
          <h2> Promovimi i produktit tuaj nuk ka qenë kurrë më i lehtë , </h2>
          <div className={styles.line}>
            <h2 className={styles.lineUp}>
                      
          ndaj zgjidhni Online Kosova për ta realizuar
            </h2>
          </div>
        </div>
        {showAdsCards && (
          <div className={styles.divCards}>
            <AdsCards />
          </div>
        )}
      </div>
    </div>
  );
}
