import React, {useState} from "react";
import classes from "./ResetPassword.module.css";
import logo from "../../assets/images/logo.png";
import TextInput from "../../components/TextInput/TextInput";
import type {AppDispatch} from '../../redux/store';
import {useDispatch} from "react-redux";
import {resetPassword} from "../../redux/actions/passwordActions";

import {useLocation} from "react-router-dom";

const ResetPassword: React.FC = () => {

    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    const [ newPassword, setNewPassword] = useState('');
    const [confirmNewPassword,setConfirmNewPassword] = useState('');
    const [resetForm, setResetForm]=useState(true);
    const [samePassword, setSamePassword]= useState(true);

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        event.preventDefault();

        const token = new URLSearchParams(location.search).get('token');

        if(token) {
            const payload = {
                newPassword,
                confirmNewPassword,
            };
            if(newPassword === confirmNewPassword){
                setSamePassword(true);
                dispatch(resetPassword(payload, token)).then(() => {
                    setResetForm(false);
                })
            } else {
                setSamePassword(false);
            }
        }
    }


    return (
        <div className={classes.logIn}>
            <img className={classes.logInLogo} src={logo} alt="logo" />
            <h1>Online Kosova </h1>
            {resetForm ?
                <form>
                    <TextInput
                        type="password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        placeholder="New Password"
                        required
                    />
                    <TextInput
                        type="password"
                        value={confirmNewPassword}
                        onChange={ e => setConfirmNewPassword(e.target.value)}
                        placeholder="Confirm New Password"
                        required
                    />
                    {samePassword ? "" : 'Those passwords didn’t match. Try again. !!'}
                    <button type="button" className={classes.logInButton} onClick={onClickHandler}>
                        Reset Password
                    </button>
                </form>
                :
                <div>
                    <h2>Your password is reset, please close this tab!</h2>
                </div>
            }

        </div>
    );

}

export default ResetPassword;