import React, { useEffect, useState } from "react";
import classes from './AddEditCategoriesModal.module.css';
import { useDispatch, useSelector } from "react-redux";
import { createCategories, fetchCategories } from "../../redux/actions/categoriesActions";
import { fetchSource } from "../../redux/actions/sourcesActions";
import { Button, Form, Input, Modal, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { AppDispatch } from '../../redux/store';
import { UploadProps } from "antd/es/upload/interface";
import { selectCategoriesData } from "../../redux/slices/categoriesSlice";

interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddCategoriesModal: React.FC<Props> = ({ showModal, setShowModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchSource());
    }, [dispatch]);

    const categoryData = useSelector(selectCategoriesData);

    const [name, setName] = useState('');
    const [categoryImage, setCategoryImage] = useState('img');
    const [selectedFile, setSelectedFile] = useState(null);

    const [saveChangesName, setSaveChangesName] = useState(true);
    const [nameExist, setNameExist] = useState(false);

    const [characterCount, setCharacterCount] = useState(0);

    const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const stringExists = categoryData.content.some((obj) => obj.name.toLocaleUpperCase() === inputValue.toLocaleUpperCase());

        if (stringExists || inputValue === '') {
            setSaveChangesName(true);
        }
        if (stringExists) {
            setNameExist(true);
        } else if (!stringExists) {
            setSaveChangesName(false);
            setNameExist(false);
            setName(inputValue);
        }

        setCharacterCount(inputValue.length);
    }

    const handleOk = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const payload = {
            name,
            categoryImage
        }

        dispatch(createCategories(payload, formData));

        setName('');
        setCategoryImage('img');

        setTimeout(() => {
            setShowModal(false);
        }, 2000);
        setFileList(null);
    }

    const handleCancel = () => {
        setShowModal(false);
        setFileList(null);
    }

    const [fileList, setFileList] = useState([]);

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            let newFileList = [...info.fileList].slice(-1);


            newFileList = newFileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList(newFileList);
            setSelectedFile(newFileList[0].originFileObj);
        }
    };

    return (
        <Modal destroyOnClose={true} okText="Add Category" title="Add Category" centered bodyStyle={{ height: 400 }} open={showModal}
            onOk={form.submit} onCancel={handleCancel} width={800}
            okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed' }, disabled: saveChangesName }}>
            <div className={classes.modalHeader}>
                <h2>Add Category</h2><h4>Here you can add categories</h4>
            </div>
            <div>
                <Form
                    preserve={false}
                    className={classes.addCategoriesForm}
                    name='basic'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    form={form}
                    onFinish={handleOk}
                >
                    <Form.Item
                        name="title"
                        className={classes.formItem}
                        rules={[{ required: true, message: 'Please input title!' }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (nameExist) {
                                    return Promise.reject(new Error('This category exists in our database'));
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Input placeholder="Name of Category" id='title' className={classes.inputArea} name='title' value={name}
                            onChange={nameChangeHandler} style={{ resize: 'none' }} required maxLength={15} />
                        <div>{characterCount}/15</div>
                    </Form.Item>
                    <Form.Item
                        label="File"
                        name="uploadImage"
                        className={classes.fileInput}
                        rules={[{ required: true, message: 'Please select an image!' }]}
                    >
                        <Upload listType={"text"} {...props}>
                            <Button className={classes.uploadICon} icon={<UploadOutlined />}>Click to Upload</Button>
                            <Upload listType={"picture-card"} showUploadList={{
                                showRemoveIcon: false,
                                showPreviewIcon: false,
                                removeIcon: null,
                                previewIcon: null
                            }} {...props}>
                            </Upload>
                        </Upload>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}

export default AddCategoriesModal;
