import { setSources } from "../slices/sourceSlice";
import axios from "axios";
import { message } from "antd";
import { logoutReducer } from "../slices/authSlice";
import API from "../api";

export const fetchAlbanianSource = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}v2/sources?country=AL`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    dispatch(setSources(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const createAlbanianSource =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = { Authorization: "Bearer " + token };
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}sources`,
        payload,
        { headers: headers }
      );

      if (response.status === 200) {
        if (imagePayload) {
          dispatch(
            sourceAlbanianImageUpload(imagePayload, response.data.id)
          ).then(() => {
            dispatch(fetchAlbanianSource());
          });
        }
      }
      if (response.status === 201) {
        if (imagePayload) {
          dispatch(
            sourceAlbanianImageUpload(imagePayload, response.data.id)
          ).then(() => {
            dispatch(fetchAlbanianSource());
          });
        }
      }
    } catch (err) {
      message.error(err.response.data.message);
      throw new Error(err);
    }
  };

export const sourceAlbanianImageUpload =
  (payload, id) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      console.log(token);

      const headers = {
        Authorization: "Bearer " + token,
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}sources/${id}/feature-image`,
        payload,
        { headers: headers }
      );
    } catch (err) {
      throw new Error(err);
    }
  };

export const deleteAlbanianSource = (payload) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.delete(
      `${process.env.REACT_APP_BACKEND_API}sources/${payload}`,
      { headers }
    );
    if (response.status === 200) {
      message.success("Source eshte fshire!");
      dispatch(fetchAlbanianSource());
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAlbanianSource =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };
      const data = {
        name: payload.name,
        url: payload.url,
        sourceImage: payload.sourceImage,
      };
      const response = await API.put(
        `${process.env.REACT_APP_BACKEND_API}sources/${payload.id}`,
        data,
        { headers }
      );
      if (response.status === 200) {
        if (imagePayload) {
          dispatch(sourceAlbanianImageUpload(imagePayload, payload.id)).then(
            (res) => {
              dispatch(fetchAlbanianSource());
              message.success("SOURCE EDITED!!");
            }
          );
        } else {
          message.success("POST edited!");
          dispatch(fetchAlbanianSource());
        }
      } else {
        message.info("Please try again!");
      }
      if (response.status >= 400) {
        message.info("Source is not edited!");
      }
      dispatch(fetchAlbanianSource());
    } catch (err) {
      throw new Error(err);
    }
  };
