import React, {useEffect, useState} from "react";
import classes from '../components/Modal/CreateModalAdvertisment.module.css'
import {useDispatch, useSelector} from "react-redux";

import {Button, ConfigProvider, DatePicker, Form, Input, Modal, Select, Typography, Upload} from 'antd';
import type {UploadProps} from 'antd/es/upload/interface';
import type { AppDispatch } from "../redux/store";

import upload from "../assets/images/upload.png";
import ADpackages from "../CreateAD/ADpacakges/ADpackages";
import { updateTheAD } from "../redux/actions/adsAction";


const { Dragger } = Upload;

interface Props{
    showModal: boolean,
    setShowModal: (value: boolean) => void;
    editingAdRecord: {id: string, url: string, adImage: string, adPackage: string}
}

const EditAdsModal: React.FC<Props> = ({ showModal, setShowModal, editingAdRecord }) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();
    const[editingAd, setEditingAd] = useState(null);

    useEffect(() => {
        setEditingAd(editingAdRecord)
        if(showModal){
            const values = {
                url: editingAdRecord?.url,
                image: editingAdRecord?.adImage,
            }
            form.setFieldsValue(values);
        }

    }, [editingAdRecord, form, showModal]);
    type NewADState = {
        selectedPackage: string;
    };

    const [saveChangesButton, setSaveChangesButton] = useState<boolean>(true);
    const [selectedPackage, setSelectedPackage] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [url, setUrl] = useState('');
    const [adImage, setAdImage] = useState('image.png');
    const [fileList, setFileList] = useState([]);
    const [isValidURL, setIsValidURL] = useState(true);


    const handleCancel = ()=> {
        setEditingAd(null);
        setIsValidURL(true);
        setSelectedFile(null);
        setFileList(null);
        setShowModal(false);
        setSaveChangesButton(true);
    }

    const handlePackageChange = (selectedPackage: string) => {
        setSaveChangesButton(false);
        if(selectedPackage){
            setSelectedPackage(selectedPackage);
        }
        else {
            const errorMessage = 'Please select a package!';
            console.error(errorMessage);
        } };

    const originalAdUrlChangeHandler = (event) => {
        setSaveChangesButton(false);
        const inputValue = event.target.value;
        setEditingAd( prev =>{
            return{ ...prev, url: inputValue}
        })
        if (inputValue === '') {
            setIsValidURL(true)
        } else {
            setIsValidURL(isUrl(inputValue));
        }
    };
    const handleOk = (editingAd: any) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        const payload = {
            id: editingAd.id,
            adImage: editingAd.adImage,
            url: editingAd.url,
            adPackage: selectedPackage === "" ? editingAd.adPackage : selectedPackage,
        };
    
        dispatch(updateTheAD(payload, selectedFile != null ? formData : '')).then(() => {
            setShowModal(false);
            setEditingAd(null);
            setUrl('');
            setSelectedPackage("");
            setFileList([]);
            setSaveChangesButton(false);
    
            // Reload the entire page
            window.location.reload();
        });
    };

    function isUrl(str) {
        const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return urlRegex.test(str);
    }

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            setSaveChangesButton(false);
            const newFileList = [...info.fileList].slice(-1);
            // 2. Read from response and show file link
            newFileList.forEach((file) => {
                if (file.response) {
                    setAdImage(file.response.url);
                }
            });
            setFileList(newFileList);
            setSelectedFile(newFileList[0]?.originFileObj);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <ConfigProvider
        theme={{
          components: {
            Modal: {
              colorBgElevated: '#F0F4FC' // here
            },
           
          },
        }}
      >
        <Modal destroyOnClose={true} okText="Save changes" centered bodyStyle={{height: 650 ,backgroundColor:"F0F4FC"}} open={showModal} 
               onOk={form.submit}
               onCancel={handleCancel} width={900}
               okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed'}, disabled: saveChangesButton || !isValidURL}}>
         
                <h2 style={{textAlign:"center",marginBottom:"40px"}}>Edito reklamën </h2>
          
            <div>
                <div className={classes.mainContainer}>
                    <div className={classes.leftContainer}>
                        <div className={classes.phoneContainer}>
                            {selectedFile ? (
                                <div>
                                    <img
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="ad image"
                                        className={classes.uploadImageBox}
                                    />
                                    {/*<div className={classes.phoneContainer} style={{top:"0px"}}/>*/}
                                </div>
                            ) : (
                                <img
                                    src={editingAd?.adImage}
                                    alt="ad image"
                                    className={classes.uploadImageBox}
                                />
                            )}
                        </div>

                    </div>
                    <div className={classes.rightContainer}>
                        <Form
                            preserve={false}
                            initialValues={{
                                url:editingAdRecord?.url,
                                image: editingAdRecord?.adImage,
                         }}
                            autoComplete="off"
                            form={form}
                            onFinish={() => handleOk(editingAd)}
                            className={classes.card}
                            style={{
                                width: '550px',
                                float: "right",
                                marginTop:"40px",
                                height:"450px",
                            
                                justifyContent: "center",
                            
                                backgroundColor: "#F0F4FC",

                            }}
                        >
                            <div style={{ justifyContent: "space-evenly", padding: "50px", width: '95%' }}>
                              
                                <div className={classes.isValidUrl}>
                                    {!isValidURL && (
                                        <p style={{ color: 'red' }}>Input is not a valid URL</p>
                                    )}
                                </div>
                                <Typography style={{color:"#304166" ,fontWeight:500}}>URL</Typography>
                                <Form.Item  name="url" className={classes.formItem} >
                                    <Input
                                        onChange={originalAdUrlChangeHandler}
                                        value={url}
                                        size="large"
                                        placeholder="Shkruani URL-në"
                                        id='url'
                                        name='url'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: 3,
                                            marginBottom: "5px",
                                            height: "50px",
                                        }}
                                    />
                                </Form.Item>
                                <Typography style={{color:"#304166" ,fontWeight:500}}>Imazh</Typography>
                                <div className={classes.App}>
                                    <Form.Item name="image" className={classes.formItem} rules={[{ required: true, message: 'Ju lutemi zgjidhni një imazh!' }]}>
                                        <div className={classes.fileUploadBox} >

                                            <Dragger listType={"text"} {...props} name="image" id="name" >
                                                <p className="ant-upload-drag-icon">
                                                    <img src={upload} style={{height:"50px",width:"60px"}}/>
                                                    </p>
                                                    <p className="ant-upload-text">
                               Merreni dhe zhvendoseni imazhin tuaj ,apo shkarkojeni</p>
                                <p className="ant-upload-hint">
                                Madhësia e imazhit duhet të jetë 1080×1920 më pak se 1 MB
                                </p>
                                            </Dragger>
                                        </div>
                                    </Form.Item>
                                </div>
                                
                                {/* <div className={classes.AdPackagesSection}>
                                    <ADpackages onPackageChange={handlePackageChange} preSelectedPackage={editingAd?.adPackage} />
                                    {!selectedPackage && <span style={{ color: 'red' }}></span>}
                                </div> */}
                                {/*<Button*/}
                                {/*    onClick={form.submit}*/}
                                {/*    disabled={!isValidURL || !selectedPackage ||!selectedFile }*/}
                                {/*    className={classes.button}*/}
                                {/*    style={{*/}
                                {/*        borderRadius: 5,*/}
                                {/*        float: "right",*/}
                                {/*        marginTop: "40px",          }}*/}
                                {/*    size="large"*/}
                                {/*>*/}
                                {/*    Continue to Payment <RightOutlined />*/}
                                {/*</Button>*/}
                                {/* <h3
                                    style={{
                                        padding: "10px",
                                        fontWeight: "normal",
                                    }} >
                                  Information
                                </h3> */}
                                   <h4
                                    style={{
                                        paddingTop:"10px",
                                      fontSize:"15px",
                                      
                                    
                                    }} >
                                   Informatë!
                                </h4>
                                <h4
                                    style={{
                                        paddingTop:"10px",
                                      fontSize:"15px",
                                      fontStyle:"italic",
                                      fontWeight:300
                                    }} >
                                  Kjo reklamë nuk do të shfaqet në aplikacion derisa administratori ta konfirmojë.
                                </h4>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
        </ConfigProvider>
    );
}

export default EditAdsModal;



