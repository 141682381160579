
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CardStats {
    
  totalAds: string;
  totalActiveAds: string;
  totalInactiveAds: string;
  totalImpressionsBought: string;
  totalImpressionsReached: string;
}

interface CardStatsState {
    content: CardStats [],
    loading: boolean;
    error: string | null;
  }
  
  const initialState: CardStatsState = {
    content: [],
    loading: false,
    error: null,
  };

export const cardStatsSlice = createSlice({
  name: 'cardStats',
  initialState : initialState,
  reducers: {
    addCardReducer: (state, action) => {
        state.content = action.payload;
    },
    setCards: (state, action) => {
        state.content = action.payload;
    },
    setCardsLoading: (state, action) => {
        state.loading = action.payload;
    },

   
  
    // Add other reducers as needed
  },

});


export const { setCards } = cardStatsSlice.actions;
export const selectCardStats = (state: { cardStats: CardStatsState }) => state.cardStats.content[0];

export const selectCardsLoading = (state: { cardStats: CardStatsState }) => state.cardStats.loading;

export default cardStatsSlice.reducer;
