import React, {useEffect, useState} from "react";
import classes from './DeleteModal.module.css'
import {useDispatch} from "react-redux";

import { CSSProperties } from 'react';

import {Modal, Button} from 'antd';



import type {AppDispatch} from '../../redux/store';
import {deleteSource} from "../../redux/actions/sourcesActions";

interface Props{
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    sourceId: string
}

const DeleteSourceModal: React.FC<Props> = ({ showModal, setShowModal, sourceId }) => {


    const dispatch = useDispatch<AppDispatch>();
    const [deleteSourceId, setDeleteSourceId] = useState(null);


    useEffect(()=>{
        setDeleteSourceId(sourceId);
    }, [showModal]);


    const handleOk = () => {

        dispatch(deleteSource(deleteSourceId))
        console.log('handleokeeej',deleteSourceId);
        setDeleteSourceId(null);
        console.log(deleteSourceId)
        setShowModal(false);
    };

    const handleCancel = () =>{
        setShowModal(false);
        setDeleteSourceId(null);
    }

    const centerStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <Modal destroyOnClose={true}  maskStyle={{ backdropFilter: 'blur(8px)' }} okText="Submit" centered bodyStyle={{height: 130}} open={showModal}
               onCancel={handleCancel}
               width={800}
               okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed' } }}
               footer={
                   <div style={centerStyle}>
                       <Button key="cancel" onClick={handleCancel}>
                           Cancel
                       </Button>
                       <Button key="submit" type="primary" onClick={handleOk}>
                           Submit
                       </Button>
                   </div>
               }
        >
            <div className={classes.modalHeader}>
                <h2>Delete the Source?</h2>
                <br/>
                <h4>This will disappear from your Sources List and anywhere else. You wont be able to recover it.</h4>
            </div>
        </Modal>
    );
}

export default DeleteSourceModal;



