
import {createSlice} from "@reduxjs/toolkit";

interface adPackage {
    id?: number;
    name: string;
    price: number;
    impressions: number;
  }
  
  interface PackagesDataState {
        content: adPackage[],
    
    
    loading: boolean;

}
const initialPackagesDataState: PackagesDataState = {
    
        content: [],
    
    loading: false,
   
};
export const packagesSlice = createSlice({
    name: 'adPackage',
    initialState: initialPackagesDataState,
    reducers: {
        addPackagesReducer: (state, action) => {
            state.content = action.payload;
        },
        setPackages: (state, action) => {
            state.content = action.payload;
        },
        setPackagesLoading: (state, action) => {
            state.loading = action.payload;
        },
       
    },
});

export const { setPackages, setPackagesLoading} = packagesSlice.actions;

// export const selectPostData = (state) => state.postsData.data;
// export const selectPostLoading = (state) => state.postsData.loading;

export const selectPackagesData = (state: {adPackage: PackagesDataState}) => state.adPackage.content;
export const selectPackagesLoading = (state: {adPackage: PackagesDataState}) => state.adPackage.loading;

export default packagesSlice.reducer;
