import {createSlice} from "@reduxjs/toolkit";

export interface CategoryModel {
    id: string;
    name: string;
    categoryImage: string;
}

interface CategoriesDataState {
    data: {
        content: CategoryModel[],
        totalPages: number,
        totalElements: number
    }
    loading: boolean;
    currentPage: number;
}

const initialCategoriesDataState: CategoriesDataState = {
    data: {
        content: [],
        totalPages: 0,
        totalElements: 0
    },
    loading: false,
    currentPage: 1
};

export const categoriesSlice = createSlice({
    name: 'categoriesData',
    initialState: initialCategoriesDataState,
    reducers: {
        addCategories: (state, action) => {
            state.data = action.payload;
        },
        setCategories: (state, action) => {
            state.data = action.payload;
        },
        setCategoriesLoading : (state, action)=>{
            state.loading = action.payload;
        },
        setCategoriesCurrentPage : (state, action)=>{
            state.currentPage = action.payload;
        }

    }
});

export const { setCategories, setCategoriesLoading, setCategoriesCurrentPage } = categoriesSlice.actions;

export const selectCategoriesData = (state: {categoriesData: CategoriesDataState}) => state.categoriesData.data;
export const selectCategoriesLoading = (state: {categoriesData: CategoriesDataState}) => state.categoriesData.loading;
export default categoriesSlice.reducer;
