import authReducer from '../slices/authSlice';
import postReducer from '../slices/postSlice';
import usersReducer from '../slices/userSlices';
import newsReducer from '../slices/newsSlice';
import latestPostByCategoryReducer from '../slices/latestPostByCategorySlice'

import categoriesReducer from "../slices/categoriesSlice";
import sourceReducer from "../slices/sourceSlice";
import adsReducer from "../slices/adsSlice";
import packagesReducer from "../slices/packagesSlice"
import cardReducer from "../slices/cardSlice"
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {combineReducers} from '@reduxjs/toolkit';
import {configureStore, applyMiddleware} from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['user']
};

const rootReducer = combineReducers({
    user: authReducer,
    postsData: postReducer,
    categoriesData: categoriesReducer,
    sourceData: sourceReducer,
    adsData: adsReducer,
    adPackage: packagesReducer,
    usersData: usersReducer,
    news: newsReducer,
    cardStats: cardReducer,
    latestPostByCategoryData:  latestPostByCategoryReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const index = configureStore({
    reducer: persistedReducer
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof index.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof index.dispatch