import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message} from "antd";
import classes from './EditName.module.css'
import {inputs} from './editNameForm'
import {useDispatch, useSelector} from "react-redux";
import {selectUserInfo} from "../../redux/slices/authSlice";
import {updateUsername} from "../../redux/actions/userAction";
import {AppDispatch} from "../../redux/store";


const EditName: React.FC = () => {

    const [form] = Form.useForm();

    const currentUser = useSelector(selectUserInfo);

    const dispatch = useDispatch<AppDispatch>();

    const [newUsername, setNewUsername] = useState(currentUser.fullName);
    const [buttonClicked, setButtonClicked] = useState(true);

    useEffect(() => {
        setNewUsername(currentUser.fullName);
        const values = {
            name: currentUser.fullName
        }
        form.setFieldsValue(values);

    },[]);


    const editUsername: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {

        form.validateFields().then((values) => {
            // Check if the required field is filled

            if (values.requiredField) {
                return;
            }

            setButtonClicked(true);
            const payload = {
                fullName: newUsername
            };

            dispatch(updateUsername(payload))
                .then(() => {
                    message.success('Emri u ndrrua me sukses')
                })
                .catch((error) => {
                });


        }).catch((error) => {
            console.error('Validation failed:', error);
        });
    };

    const changeUsernameHandler = (e) =>{
        setNewUsername(e.target.value);
        setButtonClicked(false);
    }


    return (
        <div className={classes.editNameDiv}>
            <Form
                preserve={false}
                form={form}
                onFinish={editUsername}
                name="register"
                scrollToFirstError>
                <Form.Item
                    name="name"
                    className={classes.styledInput}
                    // style={{width: '400px'}}
                    {...inputs.name}>
                    <Input id='name' name='name' placeholder='Vendos emrin e ri' onChange={changeUsernameHandler}
                          />
                </Form.Item>
                <Form.Item
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    style={{width: '100%'}}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{width: '100%'}}
                        disabled={buttonClicked}
                    >
                        Ndrysho Emrin
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditName;