import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchAllAds,
    fetchMyAds,
    fetchActiveAds,
    fetchInActiveAds,
    deActivateAd,
    changeAdsCurrentPage
} from "../../redux/actions/adsAction";
import classes from './AdsPanel.module.css'
import Card from '../../components/UI/Card';

import type { AppDispatch } from "../../redux/store";
import {selectAdData, selectCurrentPageOfAds} from "../../redux/slices/adsSlice";
import noImage from "../../assets/images/noImage.jpg";
import {Button, Pagination, Spin} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {BiTrash} from 'react-icons/bi';
import { useState } from 'react';
import { Select,Space } from 'antd';
import {ColumnsType} from "antd/es/table";
import { activateAd } from "../../redux/actions/adsAction";
import DeleteAdModal from "../../adsPages/Ads/DeleteAdModal"
import EditAdsModal  from "../../components/Modal/EditAdsModal";
import {selectIsAdmin } from "../../redux/slices/authSlice";
import DataTable from "../../components/Table/DataTable";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import CrateAdvertisement from '../../components/Modal/CreateAdvertismentModal'
import {RiPencilFill} from "react-icons/ri";



interface AdsData {
    key:React.Key;
    title: string;
    dataIndex: string;
    width: string;
    textWrap: any;
    ellipsis: boolean;
    content: any;
    nr: number;
    activate:boolean
}


const AdsPanel: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isAdmin = useSelector(selectIsAdmin);

    const AdsData = useSelector(selectAdData);

    const activeAds=useSelector(selectAdData);
    const inactiveAds=useSelector(selectAdData);

    const currentPage = useSelector(selectCurrentPageOfAds);

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

    }

    const [showAddCreateAdvertisementModal, setShowAddCreateAdvertisementModal] = useState(false);


    const [activeRowStatus, setActiveRowStatus] = useState({});
    const [filterOption, setFilterOption] = useState("all");

    useEffect(() => {
        console.log(currentPage);
        setLoading(true);
        if (isAdmin) {
            if (filterOption === "active") {
                dispatch(fetchAllAds(currentPage, true))
                    .then(() => setLoading(false));
            } else if (filterOption === "inactive") {
                dispatch(fetchAllAds(currentPage, false))
                    .then(() => setLoading(false));
            } else {
                dispatch(fetchAllAds(currentPage))
                    .then(() => setLoading(false));
            }

        } else {
            if (filterOption === "active") {
                dispatch(fetchActiveAds())
                    .then(() => setLoading(false));
            } else if (filterOption === "inactive") {
                dispatch(fetchInActiveAds())
                    .then(() => setLoading(false));
            }   else{
                dispatch(fetchMyAds())
                    .then(() => setLoading(false));
            }
        }
    }, [dispatch, isAdmin, filterOption, currentPage]);

    const updateAdActiveStatus = (id: number, active: boolean) => {
        if(!active){
            dispatch(activateAd({ active: !active }, id))
                .then(() => {
                    setActiveRowStatus((prevStatus) => ({
                        ...prevStatus,
                        [id]: !active,
                    }));
                })
                .catch((error) => {
                    console.error('Error updating active status:', error);
                });
        } else if(active){
            dispatch(deActivateAd(id))
                .then(() => {
                    setActiveRowStatus((prevStatus) => ({
                        ...prevStatus,
                        [id]: !active,
                    }));
                })
                .catch((error) => {
                    console.error('Error updating active status:', error);
                });
        }

    };





    const [showDeleteAdModal, setShowDeleteAdModal]= useState(false);
    const [showEditAdModal, setShowEditAdModal] = useState(false);
    const [deletingAd, setDeletingAd] = useState(null);
    const [editingAd, setEditingAd] = useState(null);

    const [loading, setLoading] = useState(false);

    const showDeleteModal = (id) =>{
        setShowDeleteAdModal(true);
        setDeletingAd(id);
    }

    const showEditModal = (record) => {
        setShowEditAdModal(true);
        setEditingAd(record);
    };


    const columns:ColumnsType<AdsData> = [
        {
            title: 'Nr',
            dataIndex: 'nr',
            key: 'nr',
            width: '5%',
            align: 'center',
            render: ((value, item, index) => (currentPage - 1) * AdsData.size + index + 1),
        },
        {
            title: 'Image',
            dataIndex: 'adImage',
            key: 'adImage',
            width: '6rem',
            render: (adImage) => (
                <img onError={(e : any) => {
                    e.target.onerror= null;
                    e.target.src = noImage;
                }
                } src={adImage} alt={adImage} />
            )
        },
        {
            title: 'Link',
            dataIndex: 'url',
            key: 'url',
            width: '15rem',
            ellipsis: true
        },
        {
            title: 'Package ',
            dataIndex: 'adPackage',
            key: 'adPackage',
        },
        {
            title: 'Impressions',
            dataIndex: 'impressionsReached',
            key: 'impressionsReached',
            render: (impressions) => (
                <div>{impressions} Impressions</div>
            )
        },
        {
            title: 'Publish date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (publishedDate) => (
                <div>{formatDate(publishedDate)}</div>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '5rem',
            render: (price) => (
                <div>{price/100}€ </div>
            )

        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            width:'6rem',
            render: (text: string, record: any) => {

                return (
                    <div
                        className={classes.cancelProps}
                    >
                        {record.active ? (<FaCheckCircle size={20} color='green'/>) : (<FaTimesCircle size={20} color='red'/>)}
                    </div>

                );

            },
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: '',
            width: '15rem',
            render: (text: string, record: any) => {

                if(isAdmin){
                    return (
                        <div className={classes.actionButtons}>
                            <Button
                                className={classes.cancelActionProps}
                                type="text"
                                style={{ color: 'gray', fontSize: 'small', width: '6rem' }}
                                onClick={() => updateAdActiveStatus(record.id, record.active)}
                            >
                                {record.active ? 'Deactivate' : 'Activate'}
                            </Button>
                            <Button type="text"
                                    icon={<RiPencilFill style={{color: '#242424', fontSize: 22}}/>}
                                    onClick={()=>showEditModal(record)} />
                            <Button
                                className={classes.cancelProps}
                                icon={<BiTrash style={{ color: 'red', fontSize: 23 }} onClick={() => showDeleteModal(record.id)} />}
                            />
                        </div>


                    );
                } else {
                    return(
                        <div className={classes.actionButtons}>
                            {record.active ? (
                                <Button
                                    className={classes.cancelProps}
                                    icon={<BiTrash style={{ color: 'red', fontSize: 23 }} onClick={() => showDeleteModal(record.id)} />}
                                />
                            ) : (
                                <Button className={classes.cancelProps} disabled={true} icon={<BiTrash style={{ color: 'gray', fontSize: 23 }} />} />
                            )}
                        </div>
                    );
                }},},
    ];

    const changeCurrentPageOfAds = (page: number) => {
        dispatch(changeAdsCurrentPage(page));
    }

    return (
        <div className={classes.adsList}>
            {loading ? (
                <Spin
                    size="large"
                    tip={'The post page is loading please wait!'}
                    style={{
                        marginTop:'15%'
                    }}
                />
            ) : (
                <Card className={classes.adsListCard}>
                    <DeleteAdModal showModal={showDeleteAdModal} setShowModal={setShowDeleteAdModal} adId={deletingAd}/>
                    <CrateAdvertisement showModal={showAddCreateAdvertisementModal} setShowModal={setShowAddCreateAdvertisementModal} />
                    <EditAdsModal showModal={showEditAdModal} setShowModal={setShowEditAdModal} editingAdRecord={editingAd} />
                    <div className={classes.adsListCardHeader}>
                        <div className={classes.adsListCardTitles}>
                            <Space wrap>
                                <Select
                                    defaultValue={filterOption}
                                    style={{ width: 230 }}
                                    bordered={false}
                                    size="large"
                                    options={[
                                        { value: "all", label: "All Ads" },
                                        { value: "active", label: "Active Ads" },
                                        { value: "inactive", label: "Inactive Ads" },
                                    ]}
                                    onChange={value => setFilterOption(value)}
                                />
                                <h4 className={classes.adsListSubtitle}> Total Ads: {AdsData.totalElements}</h4>
                            </Space>
                        </div>
                        <div>
                            <Button className={classes.addButton} type='primary' onClick={()=>{setShowAddCreateAdvertisementModal(true);}}  icon={<PlusOutlined />}  size="middle"  key='/newad' >Create advertisement</Button>
                        </div>

                    </div>

                    {loading && <h2 className={classes.loadingText}>Loading</h2>}
                    {AdsData && AdsData.content && (<DataTable data={filterOption === "all" ? AdsData.content : filterOption === "active" ? activeAds.content : inactiveAds.content} headTableData={columns}/> )}
                    {AdsData.content === undefined && <h2 className={classes.loadingText}>INCORRECT API REQUEST!</h2>}
                    {AdsData.content?.length === 0 && <h2 className={classes.loadingText}>NO SOURCES FOUND! </h2>}
                    <Pagination
                        defaultCurrent={currentPage}
                        // defaultPageSize={}
                        total={AdsData.totalPages*10}
                        onChange={(page) => {
                            changeCurrentPageOfAds(page)
                        }}
                        // showSizeChanger={false}
                    />
                </Card>)}
        </div>
    )
};

export default AdsPanel;
