import React from 'react';
import {useSwiper} from "swiper/react";
import classes from './SwiperNavButtons.module.css'
import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";

export const SwiperNavButtons = () => {

    const  swiper = useSwiper();

    return (
        <div className={classes.swiperButtons}>
            <div className={classes.circleButton} onClick={()=> swiper.slidePrev()}>
                <RiArrowUpSLine
                    fontSize='1.5rem'
                    color='white'
                />
            </div>
            <div className={classes.circleButton} onClick={()=> swiper.slideNext()}>
                <RiArrowDownSLine
                    fontSize='1.5rem'
                    color='white'
                />
            </div>
        </div>
    )
}