import React, {useEffect, useState} from "react";
import {Modal} from 'antd';
import classes from './NewsModal.module.css'
import logo from '../../../assets/images/logo.png'

interface Props {
    activeNews: any;
    showModal : boolean;
    setShowModal: (value: boolean) => void;
}

const NewsModal: React.FC<Props> = ({activeNews, showModal , setShowModal}) => {

    const [showLogo, setShowLogo] = useState(true);
    const [currentNews, setCurrentNews] = useState(null);

    useEffect(() => {
        console.log('activenewsss', activeNews)
        setCurrentNews(activeNews);
        if(showModal) {
            const timer = setTimeout(() => {
                setShowLogo(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showModal, currentNews]);

    useEffect(() => {
        return () => {
            setCurrentNews(null);
        }
    }, []);

    const handleOk = () => {
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };


    return (
                    <Modal
                        width="90%"
                        bodyStyle={{
                            height: '90svh',
                            width:'100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            borderRadius: '0px',
                        }}
                        className={classes.styledModal}
                        destroyOnClose={true}
                        okButtonProps={{style: {display: 'none'}}}
                        cancelButtonProps={{style: {display: 'none'}}}
                        open={showModal}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        style={{top: 20}}
                    >
                        {showLogo && (
                            <div className={classes.showLogo}>
                                <img
                                    src={logo}
                                    alt="Placeholder"
                                    style={{width: '5rem%', height: '5rem', objectFit: 'cover'}}
                                />
                            </div>
                        )}

                        {!showLogo && (
                            <div style={{width: '100%', height: '90%', overflow: 'hidden'}}>.
                                <iframe
                                    title="External Page Content"
                                    src={activeNews.originalPostUrl}
                                    style={{
                                        display: showLogo ? 'none' : 'block',
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        overflow: 'hidden',
                                    }}
                                />
                            </div>
                        )}
                    </Modal>
            )

}
export default NewsModal;