import {Outlet, Navigate} from "react-router-dom";

import {selectIsAdmin, selectToken} from "../redux/slices/authSlice";
import {useSelector} from "react-redux";


const PrivateRoutes = () => {


    const token = useSelector(selectToken);
    const isAdmin = useSelector(selectIsAdmin)

    return (

        (token && isAdmin) ?  <Outlet/> : <Navigate to='/login'/>
    )

}





export default PrivateRoutes;