import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {Button, Form, Input, Modal, Upload} from 'antd';
import {fetchSource, updateSource} from "../../redux/actions/sourcesActions";
import classes from './AddEditSourceModal.module.css'
import {UploadOutlined} from "@ant-design/icons";
import type {AppDispatch} from '../../redux/store';
import {UploadProps} from "antd/es/upload/interface";

interface Props {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    editingSourceRecord: { id: string, name: string, url: string, sourceImage: string }
}


const EditSourcesModal: React.FC<Props> = ({showModal, setShowModal, editingSourceRecord}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();
    
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    const [editingSource, setEditingSource] = useState(null);
    
    useEffect(() => {
        setEditingSource(editingSourceRecord)
        if (showModal) {
            const values = {
                name: editingSourceRecord.name,
                url: editingSourceRecord.url
            }
            form.setFieldsValue(values);
        }
    }, [editingSourceRecord, form, showModal]);

    useEffect(() => {
        return () => {
            form.resetFields();
            setEditingSource(null);
        }
    }, []);


    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [fileList, setFileList] = useState<any>([]);
    const [saveChangesButton, setSaveChangesButton] = useState<boolean>(true);


    const handleOk = (editingSource: any) => {

        const formData = new FormData();
        formData.append('file', selectedFile);

        const payload = {
            id: editingSource.id,
            name: editingSource.name,
            url: editingSource.url,
            sourceImage: editingSource.sourceImage
        }

        dispatch(updateSource(payload, formData))
            .then(() => dispatch(fetchSource()))
        setSaveChangesButton(true);
        setFileList(null);
        setShowModal(false);


        form
        .validateFields()
        .then(values => {
            // Your logic when form validation succeeds
        })
        .catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
        setSaveChangesButton(true);
        setFileList(null);
        setShowModal(false);
    };


    const props: UploadProps = {

        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            setSaveChangesButton(false);
            let newFileList = [...info.fileList].slice(-1);


            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList(newFileList);

            setSelectedFile(newFileList[0].originFileObj);
        }

    };
  


    return (
        <Modal destroyOnClose={true} maskStyle={{backgroundColor: "hsla(0, 3%, 13%,0.1)"}} centered
               bodyStyle={{height: 350}}
               open={showModal} onOk={form.submit} onCancel={handleCancel}
               width={800} okText="Save Changes"
               okButtonProps={{
                   style: {backgroundColor: '#2561F9', borderColor: '#24a0ed'},
                   disabled: saveChangesButton
               }}
        >
            <div className={classes.modalHeader}>
                <h2>Edit Source</h2><h4>Here you can edit sources</h4>
            </div>
            <div>

                <Form
                    preserve={false}
                    className={classes.sourceForm}
                    name='basic'
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    autoComplete="off"
                    form={form}
                    onFinish={() => handleOk(editingSource)}
                >
                    <Form.Item
                        //label='Title;
                        name="title"
                        className={classes.formItem}
                        rules={[{required: true, message: 'Please input title!'}]}
                    >
                        <Input placeholder="Name of Source" id='title' className={classes.inputArea} name='title'
                               defaultValue={editingSource?.name}
                               onChange={(e) => {
                                   setEditingSource(prev => {
                                       setSaveChangesButton(false);
                                       return {...prev, name: e.target.value}
                                   })
                               }} style={{resize: 'none'}} required/>
                    </Form.Item>

                    <Form.Item
                        // label="Url"
                        name="url"
                        className={classes.formItem}
                        rules={[
                            { required: true, message: 'Please input URL!' },
                            { pattern: urlRegex, message: 'Please enter a valid URL!' }
                        ]}
                    >
                        <Input id='url' className={classes.inputArea} name='subtitle' placeholder='url (required)'
                               defaultValue={editingSource?.url} onChange={(e) => {
                            setEditingSource(prev => {
                                setSaveChangesButton(false);
                                return {...prev, url: e.target.value}
                            })
                        }} style={{resize: 'none'}}/>
                    </Form.Item>
                    <Form.Item
                        label="File"
                        name="uploadImage"
                        className={classes.fileInput}
                    >
                        <Upload {...props} >
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}
export default EditSourcesModal;

