import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styles from "./Sidebar.module.css";
import logo from "../../../assets/images/logo.png";

import { RiBarChartFill, RiHome2Line ,RiAdvertisementFill } from 'react-icons/ri';
import { BiCategory } from 'react-icons/bi';
import {FaUsers} from 'react-icons/fa'
import { Layout, Menu, MenuProps } from 'antd';
import { MailOutlined, SettingOutlined } from '@ant-design/icons';
import classes from './Sidebar.module.css'
const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
const items: MenuItem[] = [
    {
      key: 'sub1',
      icon:  (
        <div className={classes.customOption}>
            <img src="https://cdn.britannica.com/18/114418-050-E12B5707/Flag-Kosovo.jpg" alt="Kosova" />
            KOSOVE
        </div>
    ),
      children:[
        {
            key: '/posts',
            icon: <RiHome2Line />,
            label: <Link to={'posts'}>Posts </Link>,
        },
        {
            key: '/categories',
            icon: <BiCategory />,
            label: <Link to={'categories'}>Categories </Link>,
        },
        {
            key: '/sources',
            icon: <RiBarChartFill />,
            label: <Link to={'sources'}>Sources </Link>,
        },
        {
            key: '/users',
            icon: <FaUsers />,
            label: <Link to={'users'}>Users</Link>,
        }
    ],
    },
    {
      key: 'sub4',
      label: (
        <div className={classes.customOption}>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC3oC2fAPycTMRBwivt_59S07mU48t--9m8Q&s" alt="Shqiperia" />
            ALBANIA
        </div>
    ),
      children: [
        {
            key: '/albanianposts',
            icon: <RiHome2Line />,
            label: <Link to={'albanianposts'}>Posts </Link>,
        },
        {
            key: '/albaniancategories',
            icon: <BiCategory />,
            label: <Link to={'albaniancategories'}>Categories </Link>,
        },
        {
            key: '/albaniansources',
            icon: <RiBarChartFill />,
            label: <Link to={'albaniansources'}>Sources </Link>,
        },
       
        {
            key: '/users',
            icon: <FaUsers />,
            label: <Link to={'users'}>Users</Link>,
        }
    
      ],
    },
    {
        key: '/adspanel',
        icon: <RiAdvertisementFill/>,
        label: <Link to={'adspanel'}>ADVERTISMENT</Link>
      },
  ];
  

const Sidebar: React.FC = () => {

    const location = useLocation();

    return (
        <Layout>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    width: '100rem',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 999,
                }}
            >
                <div style={{ height: '2rem', width: '10rem', marginTop: '2.6rem', margin: 16, display: "flex", alignItems: 'center', marginBottom: '3.5rem' }}>
                    <img src={logo} alt='logo' style={{ width: '2rem' }} />
                    <h2 style={{ fontSize: '1rem', color: 'white', fontWeight: '500' ,marginLeft:"7px"}}>Online Kosova</h2>
                </div>

                <div>
                    <h2 style={{ fontSize: '1rem', color: ' #757575', fontWeight: '500', paddingLeft: '2rem', marginBottom: '1.5rem' }}>Menu</h2>
                </div>

                <Menu className={styles.menuSider} theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}
                      items={items}
                />
            </Sider>
        </Layout>
    );
};

export default Sidebar;
