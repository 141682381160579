import classes from './Posts.module.css'
import Card from '../../components/UI/Card'
import DataTable from "../../components/Table/DataTable";

import {fetchPosts, changePostsCurrentPage, searchPosts} from "../../redux/actions/postsActions";
import {selectPostData, selectCurrentPageOfPosts} from "../../redux/slices/postSlice";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import AddPostModal from "../../components/Modal/AddPostModal";
import {fetchSource} from "../../redux/actions/sourcesActions";
import {fetchCategories} from "../../redux/actions/categoriesActions";
import noImage from "../../assets/images/noImage.jpg";
import {Button, Spin, Pagination, Input} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {BiTrash} from 'react-icons/bi';
import {RiPencilFill} from 'react-icons/ri';
import EditPostsModal from "../../components/Modal/EditPostsModal";
import AddPostByScrapping  from "../../components/Modal/AddPostByScrapping";
import DeletePostModal from "../../components/Modal/DeletePostModal";



import type {AppDispatch} from '../../redux/store';
import {ColumnsType} from "antd/es/table";

interface PostsData {
    key: React.Key;
    title: string;
    dataIndex: string;
    width: string;
    textWrap: any;
    ellipsis: boolean;
    content: any;
    nr: number;
}

const Posts: React.FC = () => {

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }

    const dispatch = useDispatch<AppDispatch>();

    const postData = useSelector(selectPostData);
    const currentPage = useSelector(selectCurrentPageOfPosts);
    const [search,setSearch] = useState('');


    useEffect(()=>{
        setLoading(true); // set loading to true
        dispatch(fetchSource());
        dispatch(fetchCategories());
        if(search !== ''){
            dispatch(searchPosts(currentPage,search)).then(() => setLoading(false)); // set loading to false when the data has been fetched
        } else {
            dispatch(fetchPosts(currentPage))
                .then(() => setLoading(false)); // set loading to false when the data has been fetched
        }

    },[dispatch]);

    useEffect(() => {
        setLoading(true); // set loading to true

        if(search !== ''){
            dispatch(searchPosts(currentPage,search)).then(() => setLoading(false)); // set loading to false when the data has been fetched
        } else {
            dispatch(fetchPosts(currentPage))
                .then(() => setLoading(false)); // set loading to false when the data has been fetched
        }
    }, [currentPage, dispatch])


    const [showAddPostModal, setShowAddPostModal] = useState(false);
    const [showAddPostScrappingModal, setShowAddPostScrappingModal ]= useState(false)

    const [showEditPostModal, setShowEditPostModal] = useState(false);
    const [showDeletePostModal, setShowDeletePostModal]= useState(false);

    const [editingPost, setEditingPost] =useState(null);
    const [deletingPost, setDeletingPost] = useState(null);

    const [loading, setLoading] = useState(false);

    const showEditModal = (record) => {
        console.log("record clicked", record);
        setEditingPost(record);
        setShowEditPostModal(true);
    };

    const showDeleteModal = (id) =>{
        setShowDeletePostModal(true);
        setDeletingPost(id);
    }

    useEffect(() => {
        return () => {
            dispatch(changePostsCurrentPage(1));
        }
    },[dispatch])

    const changeCurrentPageOfPosts = (page: number) => {
        dispatch(changePostsCurrentPage(page));
    }

    const columns: ColumnsType<PostsData> = [
        {
            title: 'Nr',
            dataIndex: 'nr',
            key: 'nr',
            width: "3rem",
            align: 'center',
            render: ((value, item, index) => (currentPage- 1) * 10 + index + 1),

        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            width: '6rem',
            render: (imageUrl) => (
                <img onError={(e : any) => {
                    e.target.onerror= null;
                    e.target.src = noImage;
                }
                } src={imageUrl} alt={imageUrl} />
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // textWrap: 'word-break'
        },
        {
            title: 'Subtitle',
            dataIndex: 'subTitle',
            key: 'subTitle',
            // textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Post Url',
            dataIndex: 'originalPostUrl',
            key: 'originalPostUrl'
        },
        {
            title: 'Category ',
            dataIndex: 'categories',
            key: 'categories',
            width: 150,
            render: (categories) => (
                <div> {categories[0]?.name}</div>
            )
        },
        {
            title: 'Date and Time',
            dataIndex: 'publishedDate',
            key: 'publishedDate',
            render: (publishedDate) => (
                <div>{formatDate(publishedDate)}</div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 100,
            render: ( text: string, record: any) =>(
                <div className={classes.actionButtons}>

                    <Button type="text"
                            icon={<RiPencilFill style={{color: '#242424', fontSize: 22}}/>}
                            onClick={()=>showEditModal(record)} />
                    <Button
                        type="text"
                        icon={<BiTrash  style={{color: '#E00202',  fontSize: 23}}
                                        onClick={()=> showDeleteModal(record.id)}/>}
                    />
                </div>

            )
        }
    ]

    const handleSearch = (query) => {
        setSearch(query);
        if(query.length >= 3){
            dispatch(searchPosts(currentPage,query))
        }
    }

    const handleClear = () =>{
        setSearch('');
        dispatch(fetchPosts(currentPage))
            .then(() => setLoading(false)); // set loading to false when the data has been fetched
        console.log('x eshte klikuar');
    }

    const handleInputChange = (e) =>{
        if (!e.target.value) {
            handleClear();

        } else {
            setSearch(e.target.value);
        }
    }



    return (
        <div className={classes.postsLists}>
            {loading ? (
                <Spin
                    size="large"
                    tip={'The post page is loading please wait!'}
                    style={{
                        marginTop:'15%'
                    }}
                />
            ) : (
                <Card className={classes.postsListsCard}>
                    <EditPostsModal showModal={showEditPostModal} setShowModal={setShowEditPostModal} editingPostRecord={editingPost}/>
                    <DeletePostModal showModal={showDeletePostModal} setShowModal={setShowDeletePostModal} postId={deletingPost}/>
                    <div className={classes.postsListCardHeader}>
                        <div className={classes.postsListCardTitles}>
                            <h1 className={classes.postsListTitle}>Posts List</h1>
                            <h2 className={classes.postsListSubtitle}>Here are all posts in the database</h2>
                            <h2 className={classes.postsListSubtitle}>Total Posts: {postData.totalElements}</h2>
                        </div>
                        <div className={classes.searchBar}>
                            <Input.Search
                                placeholder="Search post by title"
                                onSearch = {handleSearch}
                                value = {search}
                                onChange={handleInputChange}
                                enterButton
                                allowClear
                            />
                        </div>
                        <div>
                            <Button className={classes.addButton} type='primary' icon={<PlusOutlined />} onClick={()=>{setShowAddPostModal(true);}}  size="middle">Add Post</Button>
                            <Button className={classes.addByScrappingButton} type='primary' icon={<PlusOutlined />} onClick={()=>{setShowAddPostScrappingModal(true);}}  size="middle">Add Post by Scrapping</Button>
                        </div>
                        <AddPostModal showModal={showAddPostModal} setShowModal={setShowAddPostModal} />
                        <AddPostByScrapping  showModal={showAddPostScrappingModal} setShowModal={setShowAddPostScrappingModal}/>
                    </div>

                    {postData && postData.content  && <DataTable data={postData.content} headTableData={columns} />}
                    
                </Card>)}
                <Pagination
                        defaultCurrent={currentPage}
                        total={postData.totalPages*10}
                        onChange={(page)=>{
                            changeCurrentPageOfPosts(page)
                        }}
                        showSizeChanger={false}
                    />
                    {postData.content === undefined && <h2 className={classes.loadingText}>INCORRECT API REQUEST!</h2>}
        </div>
    )
}

export default Posts;