
import {message} from 'antd';
import API from '../api'


export const registerUser = (payload) => async (dispatch, getState) => {
    try {
        const response = await API.post(`${process.env.REACT_APP_BACKEND_API}users/sign-up`, payload);
        if(response.status === 200 || response.status === 201){
            message.success('The request was completed, please check the email!', [5]);
        }
    } catch (err) {
        message.config({
            maxCount: 1,
        });
        message.error(err.response.data.message,[4]);
        throw new Error(err);
    }
};

