import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import  classes from './DeactivateProfile.module.css'
import {
    Button,
    Col,
    Form,
    Input,
    Row,
} from 'antd';
import {setDeactivateError} from "../../redux/slices/authSlice";



import { inputs } from "./deactivateProfileForm";
import {deActivateUser} from "../../redux/actions/userAction";
import {selectDeactivateError} from "../../redux/slices/authSlice";

const DeactivateProfile: React.FC = () => {
    const [password, setPassword] = useState(null);

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();

    const deactivateError = useSelector(selectDeactivateError);
    const [buttonClicked, setButtonClicked] = useState(true);



    const handleKeyDown: (event) => void = (event) => {
        if (event.key === "Enter") {
            deactivateUser(event);
        }
    };

    const deactivateUser: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {

        form.validateFields().then((values) => {
            // Check if the required field is filled
            if (values.requiredField) {
                return;
            }

            setButtonClicked(true);

            const payload = {
                password: password
            };

            dispatch(deActivateUser(payload)).then(()=> {
                setPassword(null);
                navigate("/login");
            })
        }).catch((error) => {
            console.error('Validation failed:', error);
        });
    };
    const [form] = Form.useForm();

    const passwordHandler = (e) => {
        setPassword(e.target.value);
        setButtonClicked(false);
        dispatch(setDeactivateError(null))
    }

    return (

        <div className={classes.mainDiv}>
            <Row>
                <Col span={24}>
                    <div className={classes.formDiv}>
                        <div className={classes.registerTitle}>
                            <h4>Ky hap do te mbyll profilin tuaj ne platforme</h4>
                        </div>
                        <Form
                            form={form}
                            name="register"
                            onFinish={deactivateUser}
                            scrollToFirstError>
                            <Form.Item
                                name="oldPassword"
                                label="Vendos fjalekalimin"
                                validateStatus={deactivateError ? 'error' : ''}
                                help={deactivateError}
                                className={classes.styledInput}
                                {...inputs.password}
                                // style={{width: '400px'}}
                            >
                                <Input.Password onChange={passwordHandler} placeholder='Vendos fjalekalimin tuaj'/>
                            </Form.Item>
                            <Form.Item
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                                style={{width: '100%', paddingTop: '1.5rem'}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{width: '100%'}}
                                    onKeyDown={handleKeyDown}
                                    onClick={deactivateUser}
                                    disabled={buttonClicked}
                                >
                                    Ruaj Ndryshimet
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>

    );
};

export default DeactivateProfile;
