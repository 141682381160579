import API from "../api";
import {message} from "antd";
import {logoutReducer, setDeactivateError} from "../slices/authSlice";
import {setUsers, setUsersCurrentPage} from "../slices/userSlices";
import {setUserId} from "../slices/authSlice";
export const changeUsersCurrentPage = (page) => async (dispatch, getState) => {
    dispatch(setUsersCurrentPage(page));
}

export const fetchUsers = (page) => async (dispatch, getState) => {
    try {
        const token = getState().user.token;

        const response = await API.get(`${process.env.REACT_APP_BACKEND_API}users?page=${page-1}`, {
            headers: { 'Authorization': 'Bearer ' + token}
        });
        dispatch(setUsers(response.data));

    } catch (err) {
        if(err.response.data.code === 401){
            dispatch(logoutReducer());
        }
        throw new Error(err);
    }
};

export const updateUsername = (payload) => async (dispatch, getState) => {

    try {
        const token = getState().user.token;
        const headers = {'Authorization': 'Bearer ' + token}
        const response =  await API.put(`${process.env.REACT_APP_BACKEND_API}users/self`,  payload, {headers: headers});

    } catch (err) {
        message.error('Emri nuk u ndrrua!')
        throw new Error(err);
    }
};


export const getCurrentUser = () => async (dispatch, getState) => {
    try {
        const token = getState().user.token;

        const headers = {'Authorization': 'Bearer ' + token}
        const response =  await API.get(`${process.env.REACT_APP_BACKEND_API}users/current-user`,  {headers: headers});
        dispatch(setUserId(response.data));
    } catch (err) {
        message.error('The password is not changed!')
        throw new Error(err);
    }
};

export const deActivateUser = (payload) => async (dispatch, getState) => {
    try {
        const token = getState().user.token;

        const headers = {'Authorization': 'Bearer ' + token}

        const response = await API.delete(`${process.env.REACT_APP_BACKEND_API}users/self/delete`,
            {
                headers: headers,
                data: payload
            });
            if(response.status === 200) {
                dispatch(logoutReducer());
            } else {
                message.error('Llogaria nuk u fshi!')
            }
    } catch (err) {
        dispatch(setDeactivateError('Fjalëkalimi i gabuar, provo përsëri!'))
        throw new Error(err);
    }
};


