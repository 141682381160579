import React, {useEffect, useState} from "react";
import classes from './AddEditPostModal.module.css'
import {useDispatch, useSelector} from "react-redux";
import {createPost} from "../../redux/actions/postsActions";

import {fetchCategories} from "../../redux/actions/categoriesActions";
import {selectCategoriesData} from "../../redux/slices/categoriesSlice";
import {fetchSource} from "../../redux/actions/sourcesActions";
import {selectSourceData} from "../../redux/slices/sourceSlice";

import {UploadOutlined} from '@ant-design/icons'
import {Button, DatePicker, Form, Input, Modal, Select, Upload} from 'antd';
import type {UploadProps} from 'antd/es/upload/interface';
import type {AppDispatch} from '../../redux/store';
import dayjs from 'dayjs';

interface Props{
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

}

const AddPostModal: React.FC<Props> = ({ showModal, setShowModal }) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();


    const categoriesData = useSelector(selectCategoriesData);

    const sourcesData = useSelector(selectSourceData);

    useEffect(()=>{
        dispatch(fetchCategories());
        dispatch(fetchSource());
    },[dispatch]);



    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('')
    const [sourceId, setSourceId] = useState('');
    const [categoryIds, setCategoryIds] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [originalPostUrl, setOriginalPostUrl] = useState('');
    const [publishDate, setPublishDate] = useState(dayjs() );

    const [selectedFile, setSelectedFile] = useState(null);
    const [isValidURL, setIsValidURL] = useState(true);

    const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const subtitleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubTitle(event.target.value);
    }

    const sourceIdChangeHandler = (event) => {
        setSourceId(event);
    }

    const categoryChangeHandler = (event) => {
        setCategoryIds([]);
        setCategoryIds([event])
    }

    const originalPostUrlChangeHandler = (event) => {
        const inputValue = event.target.value;
        setOriginalPostUrl(inputValue);
        if(inputValue === ''){
            setIsValidURL(true)
        } else {
            setIsValidURL(isUrl(inputValue));
        }
    }


    const handleOk = () => {

        const formData = new FormData();
        formData.append('file', selectedFile);
        console.log("data", publishDate)

        const payload = {
            title,
            imageUrl,
            subTitle,
            originalPostUrl,
            categoryIds,
            sourceId,
            publishedDate : publishDate ? publishDate : dayjs().toISOString()
        }


        dispatch(createPost(payload, formData)).then(()=>{
            setShowModal(false)
            setTitle('');
            setSubTitle('');
            setSourceId('');
            setImageUrl('');
            setOriginalPostUrl('');
            setFileList(null);
        });

    }

    const handleCancel = ()=> {
        setIsValidURL(true);
        setOriginalPostUrl('');
        setFileList(null);
        form.resetFields();
        setShowModal(false);
    }

    const selectCategoryOption = [];
    for (let i = 0; i < categoriesData.content?.length; i++) {
        selectCategoryOption.push({
            label: categoriesData.content[i].name,
            value: categoriesData.content[i].id
        });
    }

    const selectSourceOption = [];
    for (let i = 0; i < sourcesData.content?.length; i++) {
        selectSourceOption.push({
            label: sourcesData.content[i].name,
            value: sourcesData.content[i].id
        });
    }



    const [fileList, setFileList] = useState([]);

    const onChangeDateHandler = (value) => {
        setPublishDate(value?.toISOString() === null ? dayjs() : value?.toISOString());
    };


    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            let newFileList = [...info.fileList].slice(-1);


            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList(newFileList);

            setSelectedFile(newFileList[0].originFileObj);
        }
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    }

    function isUrl(str) {
        const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return urlRegex.test(str);
    }

    return (
        <Modal destroyOnClose={true} okText="Add Post" centered bodyStyle={{height: 620}} open={showModal}
               onOk={form.submit}
               onCancel={handleCancel} width={800}
               okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed'}, disabled: !isValidURL  }}>
            <div className={classes.modalHeader}>
                <h2>Add Posts</h2><h4>Here you can add posts</h4>


            </div>
            <div className={classes.body}>

                <Form
                    preserve={false}
                    id='post-editor-form'
                    name='basic'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    form={form}
                    onFinish={handleOk}

                >
                    <Form.Item
                        // label="Title"
                        name="title"
                        className={classes.formItem}
                        rules={[{ required: true, message: 'Please input title!' }]}
                    >
                        <Input id='title' className={classes.titleArea} name='title' value={title} placeholder='Post title (required)'
                               onChange={titleChangeHandler} style={{resize: 'none'}} />
                    </Form.Item>

                    <Form.Item
                        // label="Subtitle"
                        name="subtitle"
                        className={classes.formItem}
                        rules={[{ required: true, message: 'Please input subtitle!' }]}
                    >
                        <Input id='subtitle' className={classes.subtitleArea} name='subtitle' placeholder='Subtitle (required)'
                               value={subTitle} onChange={subtitleChangeHandler} style={{ resize: 'none'}}  />
                    </Form.Item>

                    <Form.Item
                        // label="Original url"
                        name="originalPostUrl"
                        className={classes.formItem}
                        rules={[{ required: true, message: 'Please input original post url!' }]}
                    >
                        <Input className={classes.originalPostUrlArea}
                               value={originalPostUrl}
                               placeholder='Original Post Url'
                               name='originalPostUrl'
                               onChange={originalPostUrlChangeHandler}
                               style={{resize: 'none' }} />
                    </Form.Item>
                    <div className={classes.isValidUrl}>
                        {!isValidURL && (
                            <p style={{ color: 'red',marginTop:"10px" }}>Input is not a valid URL</p>
                        )}
                    </div>

                    <Form.Item
                        label='Date and time'
                        rules={[{ required: true, message: 'Please select a time!' }]}
                        className={classes.datePicker}
                    >
                        <DatePicker
                            showTime={{ defaultValue: dayjs() }}
                            defaultValue={dayjs()}
                            disabledDate={disabledDate}
                            allowClear = {false}
                            onChange={onChangeDateHandler}
                        />
                    </Form.Item>

                    <div className={classes.secondRow}>

                        <div className={classes.dropdownsSelect}>

                            <Form.Item
                                label="Category"
                                name="categories"
                                className={classes.selectInputForm}
                                style={{}}
                                rules={[{ required: true, message: 'Please select category!' }]}
                            >
                                <Select
                                    className={classes.selectInput}
                                    defaultValue="Select Category"
                                    bordered={false}
                                    style={{
                                        width: 150,
                                        marginBottom:"20px"
                                    }}
                                    onChange={categoryChangeHandler}
                                    options={selectCategoryOption}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Sources"
                                name="sources"
                                className={classes.selectInputForm}
                                rules={[{ required: true, message: 'Please select source!' }]}
                            >
                                <Select
                                    className={classes.selectInput}
                                    defaultValue="Select Source"
                                    bordered={false}
                                    style={{
                                        width: 150,
                                        marginBottom:"20px"

                                    }}
                                    onChange={sourceIdChangeHandler}
                                    options={selectSourceOption}
                                />
                            </Form.Item>
                            <Form.Item
                                label="File"
                                name="uploadImage"
                                className={classes.fileInput}
                                rules={[{ required: true, message: 'Please select a image!' }]}
                            >
                                <Upload listType={"text"} {...props}>
                                    <Button className={classes.uploadIcon} icon={<UploadOutlined />} >Click to Upload</Button>
                                    <Upload listType={"picture-card"} style={{marginBottom:0}} {...props}>
                                    </Upload>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
            </Form>
            </div>
        </Modal>
    );
}

export default AddPostModal;



