import { setAd, setAdsCurrentPage } from "../slices/adsSlice";
import { message } from "antd";
import { setCards } from "../slices/cardSlice";
import { logoutReducer } from "../slices/authSlice";
import { isAdminReducer } from "../slices/authSlice";
import API from "../api";

export const changeAdsCurrentPage = (page) => async (dispatch, getState) => {
  dispatch(setAdsCurrentPage(page));
};

export const createAd =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = { Authorization: "Bearer " + token };
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}ads`,
        payload,
        { headers: headers }
      );

      if (response.status === 200) {
        if (imagePayload) {
          dispatch(adImageUpload(imagePayload, response.data.id)).then(
            (res) => {
              message.success("Reklama është shtuar me sukses !");
              dispatch(changeAdsCurrentPage(1));
              if (isAdminReducer) {
                dispatch(fetchAllAds(getState().adsData));
              } else {
                dispatch(fetchMyAds(getState().adsData));
              }
            }
          );
        } else {
          message.success("AD ADDED without Image");
          dispatch(changeAdsCurrentPage(1));
          if (isAdminReducer) {
            dispatch(fetchAllAds(getState().adsData));
          } else {
            dispatch(fetchMyAds(getState().adsData));
          }
        }
      } else {
        message.info("Ju lutemi provoni përsëri, reklama nuk u shtua!");
      }
    } catch (err) {
      console.error(err.response.data); // log the error message
      throw new Error(err);
    }
  };

export const fetchAllAds = (page, active) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    let activeParameter = "";

    if (active !== undefined) {
      activeParameter = active ? "isActive=true" : "isActive=false";
    }

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}ads/all?${activeParameter}&page=${
        page - 1
      }`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setAd(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchMyAds = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}my-ads`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setAd(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const adImageUpload = (payload, id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    console.log(token);

    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.patch(
      `${process.env.REACT_APP_BACKEND_API}ads/${id}/feature-image`,
      payload,
      { headers: headers }
    );
    console.log(response);
    if (response.status === 200) {
      dispatch(changeAdsCurrentPage(1));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteAd = (id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.delete(
      `${process.env.REACT_APP_BACKEND_API}ads/${id}`,
      { headers }
    );

    if (response.status === 200) {
      message.success("Reklama është fshirë me sukses!");

      // Update the state to remove the deleted ad0
      dispatch(deleteAd(id));

      // Check if the user is an admin
      const isAdmin = getState().user.isAdmin;

      // Dispatch appropriate actions based on user role
      if (isAdmin) {
        dispatch(changeAdsCurrentPage(1));
        dispatch(fetchAllAds(getState().adsData));
      } else {
        dispatch(changeAdsCurrentPage(1));
        dispatch(fetchMyAds(getState().adsData));
      }
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const activateAd = (payload, id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.patch(
      `${process.env.REACT_APP_BACKEND_API}ads/${id}/status/activate`,
      payload,
      { headers: headers }
    );
    if (response.status === 200) {
      message.success("Ad is activated!");
      dispatch(changeAdsCurrentPage(1));
      dispatch(fetchAllAds(getState().adsData));
    }
    console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchActiveAds = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}my-ads/active`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setAd(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const fetchAllActiveAds = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}my-ads/active`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setAd(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const fetchInActiveAds = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}my-ads/inactive`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setAd(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const deActivateAd = (id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    console.log(token);

    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.patch(
      `${process.env.REACT_APP_BACKEND_API}ads/${id}/status/deactivate`,
      { headers: headers }
    );
    if (response.status === 200) {
      message.success("Ad is deActivated!");
      dispatch(changeAdsCurrentPage(1));
      dispatch(fetchAllAds(getState().adsData));
    }
    console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAd =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };
      const response = await API.put(
        `${process.env.REACT_APP_BACKEND_API}ads/admin/${payload.id}`,
        payload,
        { headers: headers }
      );
      if (response.status === 200) {
        if (imagePayload) {
          dispatch(adImageUpload(imagePayload, payload.id)).then((res) => {
            message.success("Ad edited!");
            dispatch(fetchAllAds(getState().adsData));
            dispatch(changeAdsCurrentPage(1));
          });
        } else {
          message.success("Ad edited!");
          dispatch(fetchAllAds(getState().adsData));
          dispatch(changeAdsCurrentPage(1));
        }
      } else {
        message.info("Please try again!");
      }
    } catch (err) {
      if (err.response.status >= 400) {
        message.info("Ad is not edited!");
      }
      throw new Error(err);
    }
  };

export const updateTheAD =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };
      const response = await API.put(
        `${process.env.REACT_APP_BACKEND_API}ads/${payload.id}`,
        payload,
        { headers: headers }
      );
      if (response.status === 200) {
        if (imagePayload) {
          dispatch(adImageUpload(imagePayload, payload.id)).then((res) => {
            message.success(
              "Kjo reklamë nuk do të shfaqet në aplikacion derisa administratori ta konfirmojë."
            );

            dispatch(fetchMyAds(getState().adsData));
            dispatch(changeAdsCurrentPage(1));
          });
        } else {
          message.success(
            "Kjo reklamë nuk do të shfaqet në aplikacion derisa administratori ta konfirmojë."
          );

          dispatch(fetchMyAds(getState().adsData));
          dispatch(changeAdsCurrentPage(1));
        }
      } else {
        message.info("Ju lutemi provoni përsëri!");
      }
    } catch (err) {
      if (err.response.status >= 400) {
        message.info("Ad is not edited!");
      }
      throw new Error(err);
    }
  };

// Make sure to import the correct action

export const fetchCardStats = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}ads/statistics`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );

    console.log(response.data);

    // Assuming setCards is the correct action from your slice
    dispatch(setCards(response.data));
  } catch (err) {
    if (err.response?.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(changeAdsCurrentPage(1));
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};
