import {message} from 'antd';
import API from '../api'
import {setPasswordError} from "../slices/authSlice";

export const resetPassword = (payload, passwordToken) => async () => {
    try {
        const response = await API.put(`${process.env.REACT_APP_BACKEND_API}users/reset-password?token=${passwordToken}`, payload );
        if(response.status === 200){
            message.success('Fjalekalimi u resetua me sukses!');
        }
    } catch (err) {
        message.error('Fjalekalimi nuk u resetua provoni perseri!');
        throw new Error(err);
    }
};


export const forgotPassword = (payload) => async () => {
    try {
        const response = await API.post(`${process.env.REACT_APP_BACKEND_API}users/forgot-password`, payload);
        if(response.status === 200){
            message.success('Kërkesa u plotësua, ju lutemi kontrolloni emailin!');
        }
    } catch (err) {
            message.error('Kërkesa nuk mund të dërgohej, ju lutemi provoni përsëri!');
        throw new Error(err);
    }
};

export const changePassword = (payload) => async (dispatch) => {
    try {
        const response = await API.put(`${process.env.REACT_APP_BACKEND_API}self/users/change-password`, payload );
        if(response.status === 200){
            message.success('Fjalekalimi u ndrrua me sukses!!');
        }
    } catch (err) {
        dispatch(setPasswordError('Fjalëkalimi i vjetër nuk përputhet!'))
        message.error('Përdorni një fjalëkalim të ri');
        throw new Error(err);
    }
}

