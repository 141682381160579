import React, {useEffect, useState} from 'react';
import Card from '../../../src/components/UI/Card'
import classes from "../Sources/Sources.module.css";
import DataTable from "../../components/Table/DataTable";
import {fetchSource} from "../../redux/actions/sourcesActions";
import {selectSourceData} from "../../redux/slices/sourceSlice";
import {useDispatch, useSelector} from "react-redux";
import AddSourcesModal from "../../components/Modal/AddSourcesModal";
import noImage from "../../assets/images/noImage.jpg";
import {Button, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import EditSourcesModal from "../../components/Modal/EditSourcesModal";
import {RiPencilFill} from "react-icons/ri";
import {BiTrash} from "react-icons/bi";

import DeleteSourceModal from "../../components/Modal/DeleteSourceModal";
import type {ColumnsType} from 'antd/es/table';

import type {AppDispatch} from '../../redux/store';


interface SourceData {
    key: React.Key;
    nr: number;
    sourceImage: string;
    name: string;
    url: string;
    id: string;
    dataIndex: string;
}

const Sources: React.FC = () => {

const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        setLoading(true); // set loading to true
        dispatch(fetchSource())
            .then(() => setLoading(false)); // set loading to false when the data has been fetched
    }, [dispatch]);

    const [loading, setLoading] = useState(false);
    const sourcesData = useSelector(selectSourceData);

    const [showAddSourcesModal, setShowAddSourcesModal] = useState(false);


    const [page] = useState(1);


    const [editingSource, setEditingSource] = useState(null);
    const [deletingSource, setDeletingSource] = useState(null);


    const [showEditSourcesModal, setShowEditSourcesModal] = useState(false);
    const [showDeleteSourceModal, setShowDeleteSourceModal] = useState(false);




const showEditModal = (record: any) => {
    setEditingSource({...record});
    setShowEditSourcesModal(true);
};

    const showDeleteModal = (id) =>{
        console.log(id);
        setShowDeleteSourceModal(true);
        setDeletingSource(id);
    }

    const columns: ColumnsType<SourceData> = [
    {
        title: 'Nr',
        dataIndex: 'nr',
        key: 'nr',
        width: '5%',
        align: 'center',
        render: ((value, item, index) => (page - 1) * 10 + index + 1),
    },
    {
        title: 'Image',
        dataIndex: 'sourceImage',
        key: 'sourceImage',
        width: '20%',
        render: (sourceImage) => (
            <img
                onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = noImage;
                }}
                src={sourceImage}
                alt={sourceImage}
            />
        ),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        width: '27%',
    },
    {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
        align: 'left',
        width: '27%',
    },
    {
        title: 'Actions',
        key: 'actions',
        width: '7.5%',

        render: (text : string, record : any) => (
            <div className={classes.actionButtons}>
                <Button
                    type="text"
                    icon={<RiPencilFill style={{color: '#242424', fontSize: 22}}/>}
                    onClick={() => showEditModal(record)}/>

                <Button
                    type="text"
                    icon={<BiTrash style={{color: '#E00202', fontSize: 23}}
                                   onClick={() => showDeleteModal(record.id)}
                    />}
                />

            </div>
        )
    }

];

return (
    <div className={classes.sourcesList}>


        {loading ? (
            <Spin size="large"
                  tip={'The source page is loading please wait!'}
                  style={{
                      marginTop:'15%'
                  }}
            />
        ) : (
        <Card className={classes.sourcesCard}>
            <EditSourcesModal showModal={showEditSourcesModal} setShowModal={setShowEditSourcesModal}
                              editingSourceRecord={{...editingSource}}/>
            <DeleteSourceModal showModal={showDeleteSourceModal} setShowModal={setShowDeleteSourceModal} sourceId={deletingSource}/>
            <div className={classes.sourcesCardHeader}>
                <div className={classes.sourcesListTitles}>
                    <h1 className={classes.sourcesListTitle}>Sources</h1>
                    <h2 className={classes.sourcesListSubtitle}>Here are all the sources in the database</h2>
                    <h2 className={classes.sourcesListSubtitle}>Total Sources: {sourcesData.totalElements}</h2>
                </div>
                <Button className={classes.addSource} type='primary' icon={<PlusOutlined/>} onClick={() => {
                    setShowAddSourcesModal(true);
                }} size="middle">Add Source</Button>
                <AddSourcesModal showModal={showAddSourcesModal} setShowModal={setShowAddSourcesModal}/>
            </div>
            {sourcesData.content && <DataTable data={sourcesData.content} headTableData={columns}/>}
            {sourcesData.content === undefined && <h2 className={classes.loadingText}>INCORRECT API REQUEST!</h2>}
        </Card>
            )}
    </div>
    )
}

export default Sources;

