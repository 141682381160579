import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {Button, Form, Input, Modal, Upload} from 'antd';
import {fetchCategories, updateCategory} from "../../redux/actions/categoriesActions";
import classes from "./AddEditCategoriesModal.module.css";
import {UploadOutlined} from "@ant-design/icons";
import type {AppDispatch} from '../../redux/store';
import {UploadProps} from "antd/es/upload/interface";

interface Props {
        showModal: boolean;
        setShowModal: (show: boolean)=> void;
        editingCategoryRecord: { name: string, id: string };
}


const EditCategoriesModal: React.FC<Props> = ({ showModal, setShowModal, editingCategoryRecord }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();
    const [editingCategory, setEditingCategory] = useState<{ name: string, id: string } | null>(null);
    const [fileList, setFileList] = useState<any[]>([]);


    const [saveChangesButton, setSaveChangesButton] = useState<boolean>(true);


     
    useEffect(() => {
        setEditingCategory(editingCategoryRecord)
        if (showModal) {
            const values = {
                name: editingCategoryRecord.name,
              
            }
            form.setFieldsValue(values);
        }
    }, [editingCategoryRecord, form, showModal]);

    useEffect(() => {
        return () => {
            form.resetFields();
            setEditingCategory(null);
        }
    }, []);
    
    const [selectedFile, setSelectedFile] = useState<any>(null);

    const handleOk = (editingCategory: any) => {

        const formData = new FormData();
        // formData.append('file', selectedFile);

        const payload = {
            id: editingCategory.id,
            name: editingCategory.name,
            // sourceImage: editingCategory.sourceImage
        }

        dispatch(updateCategory(payload))
            .then(() => dispatch(fetchCategories()))
        setSaveChangesButton(true);
        // setFileList(null);
        setShowModal(false);


        form
        .validateFields()
        .then(values => {
            // Your logic when form validation succeeds
        })
        .catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
        setSaveChangesButton(true);
        // setFileList(null);
  
    };

    // const props: UploadProps = {

    //     onRemove: (file) => {
    //         const index = fileList.indexOf(file);
    //         const newFileList = fileList.slice();
    //         newFileList.splice(index, 1);
    //         setFileList(newFileList);
    //     },
    //     beforeUpload: (file) => {
    //         setFileList([...fileList, file]);
    //         return false;
    //     },
    //     fileList,
    //     onChange: (info) => {
    //         setSaveChangesButton(false);
    //         let newFileList = [...info.fileList].slice(-1);


    //         // 2. Read from response and show file link
    //         newFileList = newFileList.map((file) => {
    //             if (file.response) {
    //                 // Component will show file.url as link
    //                 file.url = file.response.url;
    //             }
    //             return file;
    //         });

    //         setFileList(newFileList);

    //         setSelectedFile(newFileList[0].originFileObj);
    //     }

    // };
  

    return(
        <Modal destroyOnClose={true}  maskStyle={{ backgroundColor: "hsla(0, 3%, 13%,0.1)" }} centered bodyStyle={{height: 400}}
            open={showModal} onOk={form.submit} onCancel={handleCancel}
            width={800}
               okText="Save Changes"
               okButtonProps={{
                   // style: {backgroundColor: '#2561F9', borderColor: '#24a0ed' },

                   style: {backgroundColor: saveChangesButton ?  '#D2D7D3' : '#2561F9', color: saveChangesButton ? 'black' : 'white' },
                   disabled: saveChangesButton
               }}
        >
            <div className={classes.modalHeader}>
                <h2>Edit Category</h2><h4>Here you can edit categories</h4>
            </div>

            <Form
                preserve={false}
                className={classes.addCategoriesForm}
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                onFinish={() => handleOk(editingCategory)}
            >
                <Form.Item
                    // label='Title'
                    name="title"
                    className={classes.formItem}
                    rules={[{ required: true, message: 'Please input title!' }]}
                >
                    <Input id="name" placeholder="Category Name" defaultValue={editingCategory?.name} onChange={(e)=>{
                        setEditingCategory(prev =>{
                            setSaveChangesButton(false)
                            return{ ...prev, name:e.target.value}})
                    }} maxLength={254}  style={{ resize: 'none'}} required/>
                </Form.Item>

                {/* <Form.Item
                    label="File"
                    name="uploadImage"
                    className={classes.fileInput}
                    rules={[{message: 'Please select a image!' }]}
                >
                                           <Upload {...props} >
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                        </Upload>
                </Form.Item> */}
            </Form>

        </Modal>

    );

}

export default EditCategoriesModal;

