import React, {useEffect, useState} from "react";
import classes from "./Login.module.css";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUserRole, login} from "../../redux/actions/auth";
import {AppDispatch} from "../../redux/store";
import {
    setPasswordError,
    selectAuthenticated,
    selectEmailError,
    selectIsAdmin,
    setEmailError, selectPasswordError
} from "../../redux/slices/authSlice";
import {Image, message} from "antd";
import ok_frame2 from "../../assets/images/ok_frame2.png";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
} from 'antd';
import { HiArrowLeft } from "react-icons/hi2";
import { inputs } from "./loginForm";
import logo from "../../assets/images/logo.png";

const Login: React.FC = () => {
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const dispatch = useDispatch<AppDispatch>();
        const navigate = useNavigate();

        const isAuthenticated = useSelector(selectAuthenticated);
        const emailError = useSelector(selectEmailError);
        const passwordError = useSelector(selectPasswordError);
        const isAdmin = useSelector(selectIsAdmin);

        useEffect(() => {
            if (isAuthenticated) {
                if (isAdmin) {
                    navigate("/posts");
                    message.info("Ju jeni i kyqur si Admin!");
                } else {
                    navigate("/news");
                    message.info("Ju jeni i kyqur!");
                }
            }
            dispatch(setPasswordError(null))
            dispatch(setEmailError(null))

        }, [isAuthenticated, isAdmin]);


        const loginToApp = async (e: React.FormEvent<HTMLButtonElement>) => {

            await dispatch(getUserRole(email));

            if (isAdmin) {
                dispatch(login({email, password}))
                    .then((res) => {
                        if (res !== undefined) {
                            navigate("/posts");
                        } else {
                            setPassword('')
                        }
                    })
                    .catch(() => {
                        setPassword('')
                        dispatch(setPasswordError('Fjalëkalimi gabim!'))
                    });
            } else {
                dispatch(login({email, password}))
                    .then((res) => {
                        console.log(res)
                        if (res !== undefined) {
                            navigate("/adslist");
                        } else {
                            setPassword('')
                        }
                    })
                    .catch((error) => {
                        setPassword('')
                        dispatch(setPasswordError('Fjalëkalimi gabim!'))
                    });
            }
        };

        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                console.log('Enter pressed');
                loginToApp(event);
            }
        }

        const [form] = Form.useForm();

        return (
                <div className={classes.mainDiv}>
                    <Row>
                        <Col span={24} className={classes.backButton}>
                            <div className={classes.circleButton} onClick={() => navigate("/news")} >
                                <HiArrowLeft
                                    fontSize='1.2rem'
                                    color='white'
                                />
                            </div>
                            <div className={classes.backTitle} onClick={() => navigate("/news")} >
                                <h2>Rikthehu ne faqe</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14} className={classes.lefLogoColumn}>
                            <div className={classes.logoDiv}>
                                <div style={{
                                    width: '70%',
                                    height: '70%',
                                    position: 'relative',
                                    backgroundImage: `url(${ok_frame2})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }} className={classes.logoTextDiv}>
                                    <Image
                                        style={{width: '5rem', height: '5rem', position: 'relative', zIndex: 2}}
                                        preview={false}
                                        src={logo}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        zIndex: 1,
                                    }}></div>
                                    <h2 >ONLINE KOSOVA</h2>
                                    <h3 >Burim i besueshëm i informacionit për të gjithë shqiptarët</h3>
                                </div>
                            </div>
                        </Col>
                        <Col  xs={24} sm={10}>
                            {!isAuthenticated && (
                            <div className={classes.LoginDiv}>
                                <div className={classes.LoginTitle}>
                                    <h1>Mirë se vini në <span style={{color:'#F4BF4E'}}>OK</span></h1>
                                    <h4>Vendosni detajet më poshtë</h4>
                                </div>
                                <Form
                                    size="large"
                                    form={form}
                                    name="login"
                                    onFinish={loginToApp}
                                    scrollToFirstError
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        validateStatus={emailError ? 'error' : ''}
                                        help={emailError}
                                        className={classes.styledInput}
                                        style={{width: '100%'}}
                                        {...inputs.email}
                                    >
                                        <Input placeholder='Vendosni email-in tuaj'  className={classes.emailInput}
                                                     onChange={(e) => setEmail(e.target.value)}/>
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label="Fjalekalimi"
                                        validateStatus={passwordError ? 'error' : ''}
                                        help={passwordError}
                                        className={classes.styledInput}
                                        {...inputs.password}
                                        style={{width: '100%', color:'red'}}
                                    >
                                        <Input.Password placeholder='Vendos fjalekalimin tuaj'
                                                      onChange={(e) => setPassword(e.target.value)}/>
                                    </Form.Item>
                                    <Form.Item
                                        labelCol={{span: 24}}
                                        wrapperCol={{offset:9}}
                                        style={{width: '100%' }}
                                    >
                                        <Link to={'/password/forgot'} style={{color: '#40A2DE', fontSize: '1.1rem',display:"flex",justifyContent: "right"}}>Harruat fjalëkalimin?</Link>
                                    </Form.Item>

                                    <Form.Item
                                        labelCol={{span: 24}}
                                        wrapperCol={{span: 24}}
                                        style={{width: '100%'}}
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{width: '100%'}}
                                            onKeyDown={handleKeyDown}
                                        >
                                            Kyquni
                                        </Button>
                                    </Form.Item>

                                </Form>

                                <div className={classes.footerLogin}>
                                    <p>
                                        Nuk keni llogari? <Link to='/register' style={{color: '#40A2DE'}}>Regjistrohuni</Link>
                                    </p>
                                </div>
                            </div>
                            )}
                        </Col>
                    </Row>
                </div>
        );
    }
;

export default Login;
