import React, {useEffect, useState} from "react";
import classes from './AddEditSourceModal.module.css'
import {useDispatch} from "react-redux";
import {fetchCategories} from "../../redux/actions/categoriesActions";
import {createSource, fetchSource} from "../../redux/actions/sourcesActions";
import {Button, Form, Input, Modal, Upload, message} from 'antd'
import {UploadOutlined} from "@ant-design/icons";
import type {AppDispatch} from '../../redux/store';
import {UploadProps} from "antd/es/upload/interface";


interface Props{
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddSourcesModal: React.FC<Props> = ({ showModal, setShowModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchSource());
    }, [dispatch]);

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [source_image, setSource_image]= useState('.img');

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isValidURL, setIsValidURL] = useState(true);

    const handleOk = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const payload = { name, url, source_image };
        dispatch(createSource(payload, formData)).then(response => {
            setShowModal(false);
            setConfirmLoading(false);
            setSource_image('.img');
            setFileList([]);
            message.success('Source added successfully')
        })
    }

    const handleCancel = ()=> {
        setIsValidURL(true);
        setUrl('');
        setFileList(null);
        form.resetFields();
        setShowModal(false);
    }

    const props: UploadProps = {

        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            let newFileList = [...info.fileList].slice(-1);


            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                }
                return file;
            });

            setFileList(newFileList);

            setSelectedFile(newFileList[0].originFileObj);
        }
    };

     const urlInputHandle = (event) => {
         const inputValue = event.target.value;
         setUrl(inputValue);
         if(inputValue === ''){
             setIsValidURL(true)
         } else {
             setIsValidURL(isValidURLFormat(inputValue));
         }


     }

    const isValidURLFormat = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    }

    return (
        <Modal
            destroyOnClose={true}
            okText="Add Source"
            title="Add Source"
            centered
            bodyStyle={{height: 500}}
            open={showModal}
            onOk={form.submit}
            onCancel={handleCancel}
            width={800}
            confirmLoading={confirmLoading}
            okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed' }, disabled: !isValidURL}}>

            <div className={classes.modalHeader}>
                <h2>Add Source</h2><h4>Here you can add sources</h4>
            </div>
            <Form
                preserve={false}
                className={classes.sourceForm}
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                onFinish={handleOk}>
                <Form.Item name="title" className={classes.formItem}
                           rules={[{ required: true, message: 'Please write name of URL!' }]}>
                    <Input
                        required
                        type='text'
                        id='title'
                        name='title'
                        value={name}
                        placeholder="Name of Source (required)"
                        className={classes.inputArea}
                        onChange={(e) => setName(e.target.value)}
                        style={{resize: 'none'}}/>
                </Form.Item>
                <Form.Item
                    name="url"
                    className={classes.formItem}
                    rules={[{ required: true, message: 'Please check a url!' }]}>
                    <Input
                        required
                        name='url'
                        value={url}
                        placeholder='Url (required)'
                        className={classes.inputArea}
                         // onChange={(e) => setUrl(e.target.value)}
                        onChange={urlInputHandle}
                    />
                </Form.Item>
                {!isValidURL && (
                    <p style={{ color: 'red' }}>Input is not a valid URL</p>
                )}
                <Form.Item
                    label="File"
                    name="uploadImage"
                    className={classes.fileInput}
                    rules={[{ required: true, message: 'Please select a image!' }]}>
                    <Upload listType={"text"} {...props}>
                        <Button className={classes.uploadICon} icon={<UploadOutlined />} >Click to Upload</Button>
                        <Upload listType={"picture-card"}  showUploadList={{
                            showRemoveIcon: false,
                            showPreviewIcon: false,
                            removeIcon: null,
                            previewIcon: null
                        }} {...props}>
                        </Upload>
                    </Upload>

                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddSourcesModal;



