import styles from "./Layout.module.css";
import { Outlet } from "react-router";
import Header from "./HomeHeader/Header";

import { Layout } from "antd";
import ADSlists from "../../adsPages/Ads/ADSlist";

const { Content } = Layout;

export default function AdsLayouts() {
  return (
    <Layout>
      <Layout className={styles.layout}>
        <Header />
        <Content className={styles.Content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
