import phone from "../../../assets/images/aboutUsPhone.png";
import "./AboutUs.css";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="aboutUs-section" id="about-us">
      <div className="aboutUs-left-section">
        <img src={phone} alt="" className="slide-in-left" />
      </div>
      <div className="aboutUs-right-section">
        <h1>
          Nga politika dhe <span className="yellowText">ngjarjet </span>{" "}
        </h1>
        <h1>
          aktuale tek sporti dhe argëtimi,<span className="yellowText"></span>
        </h1>
        <h1> ne i përfshijmë të gjitha. </h1>
        <h1>
          <span className="yellowText"></span>
        </h1>
        <div className="followUs-section slide-in-from-right">
          <h1>Na ndiqni në rrjetet sociale.</h1>
          <div className="line"></div>
          <div className="icons">
            <a href="https://www.facebook.com/LinkPlusIT/">
              <li className="iconStyle">
                <FaFacebookF />
              </li>
            </a>
            <a href="https://www.instagram.com/linkplus_it/">
              <li className="iconStyle">
                <FaInstagram />
              </li>
            </a>
            <a href="https://www.linkedin.com/company/linkplus-it/">
              <li className="iconStyle">
                <FaLinkedin />
              </li>
            </a>
            <a href="https://www.tiktok.com/@linkplusit">
              <li className="iconStyle">
                <FaTiktok />
              </li>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
