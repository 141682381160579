import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import classes from './SelectSources.module.css'
import logo from "../../assets/images/logo.png";
import {HiArrowLeft} from "react-icons/hi2";
import {Card, Col, Image, Row, Switch} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {selectSourceData} from "../../redux/slices/sourceSlice";
import {AppDispatch} from "../../redux/store";
import {fetchSource} from "../../redux/actions/sourcesActions";
import {selectAuthenticated} from "../../redux/slices/authSlice";
import {loginAnonymously} from '../../redux/actions/auth'

const SelectSources: React.FC = () => {
    const isAuthenticated = useSelector(selectAuthenticated);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const fetchData = () => {
            dispatch(fetchSource()).then(() => {
            })
        };

        isAuthenticated
        ? fetchData()
            : dispatch(loginAnonymously())
                .then(fetchData)
                .catch((error) => {
                    console.error('Anonymous login failed:', error);
                });


    }, [isAuthenticated]);

    const navigate = useNavigate();
    const sourcesData = useSelector(selectSourceData);

    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedCards, setSelectedCards] = useState<string[]>(localStorage.getItem('selectedSources') ? JSON.parse(localStorage.getItem('selectedSources')) : []);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedCards([]);
            setSelectAll(false);
        } else {
            const allCardIds = sourcesData.content.map(card => card.id);
            setSelectedCards(allCardIds);
            setSelectAll(true);
        }
    };

    useEffect(() => {
        const lsAllSelected = localStorage.getItem('allSelected');
        setSelectAll(lsAllSelected === 'true');
    },[])


    const handleCardClick = (id: string) => {
        const isSelected = selectedCards.includes(id);
        if (isSelected) {
            const updatedSelection = selectedCards.filter(cardId => cardId !== id);
            setSelectedCards(updatedSelection);
            setSelectAll(false);
        } else {
            setSelectedCards([...selectedCards, id]);
            if(sourcesData.totalElements == selectedCards.length+1){
                setSelectAll(true);
            }
        }
    };

    const onContinueHandler = () => {
        const payloadSourceString = selectedCards.join(',');
        const selectAllToString = JSON.stringify(selectAll);

        localStorage.setItem("selectedSources", JSON.stringify(selectedCards));
        localStorage.setItem('sourceIds', payloadSourceString);
        localStorage.setItem('allSelected', selectAllToString);

        navigate('/news');
    }

    return (
        <div className={classes.mainDiv}>
            <Row>
                <Col  span={24} className={classes.headerDiv}>
                    <div className={classes.backTitle} onClick={() => navigate("/news")}>
                        <div className={classes.circleButton} onClick={() => navigate("/news")}>
                            <HiArrowLeft
                                fontSize='1.2rem'
                                color='white'
                            />
                        </div>
                        <h2>Rikthehuni ne faqe</h2>
                    </div>
                    <div className={classes.headerLogo}>
                        <h2>Online</h2>
                        <img src={logo} alt="logoImage"/>
                        <h2>Kosova</h2>
                    </div>
                    <div style={{width: '33%'}}></div>
                </Col>
            </Row>
            <Row className={classes.centerDiv}>
                <Col span={18}>
                    <div className={classes.centerDivHeader}>
                        <h2>Top Kanalet</h2>
                        <h3>Zgjidhni kanalet tuaja të preferuara.</h3>
                    </div>
                </Col>
                <Col span={18} className={classes.toggleSwitchCol}>
                    <div className={classes.toggleSwitch}>
                        <h3>Të gjitha</h3>
                        <Switch
                            defaultChecked 
                            checked={selectAll}
                            onChange={handleSelectAll}
                           
                        />
                    </div>
                </Col>
                <Col span={18} className={classes.selectSourcesDiv}>
                    {sourcesData.content.map((item, index) => (
                        (index % 3 === 0) && (
                            <Row gutter={[16,{xs:0, sm: 0, md:8}]} key={index}>
                                {sourcesData.content.slice(index, index + 3).map((card, i) => (
                                    <Col  xs={{ span: 24 }}
                                          sm={{ span: 24 }}
                                          md={{span: 8}}
                                          key={card.id}>
                                        <Card
                                            onClick={() => handleCardClick(card.id)}
                                            className={classes.sourceCard}
                                            style={{
                                                marginBottom: '16px',
                                                backgroundColor: selectedCards.includes(card.id) ? '#2D60E3' : '#242424',
                                                borderRadius: '0.2rem',
                                                border: 'none',
                                                height: '3rem',
                                                padding: '1rem',
                                                transition: 'background-color 0.3s',
                                            }}
                                            bodyStyle={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                padding: '10px',
                                            }}
                                        >
                                            <div className={classes.sourceSection}>
                                                <Image
                                                    preview={false}
                                                    width={'2rem'}
                                                    height={'2rem'}
                                                    src={card.sourceImage}
                                                    style={{borderRadius: '50%', objectFit: 'cover'}}
                                                />
                                                <h4 style={{color: 'white'}}>
                                                    {card.name}
                                                </h4>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )
                    ))}
                </Col>
            </Row>
            <Row className={classes.confirmSection}>
                <Col span={6}>
                    <Card
                        onClick={() => onContinueHandler()}
                        className={classes.confirmCard}
                        bodyStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            padding: '10px',
                        }}
                    >
                        <div className={classes.confirmButton}>
                            <h4 style={{color: 'white'}}>
                                Vazhdoni
                            </h4>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default SelectSources;