import { createSlice } from "@reduxjs/toolkit";

interface AdsModel {
  id: string;
  adImage: any;
  url: string;
  adPackage: string;
  createdAt: string;
  createdByEmail: string;
  price: number;
  active: boolean;
}

interface AdsDataState {
  data: {
    length: number;
    // adImage: any;
    totalAds:string
    content: AdsModel[];
    totalPages: number;
    totalElements: number;
    size: number;
  };
  loading: boolean;
  currentPage: number;
}

const initialAdDataState: AdsDataState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    size: 0,
    length:0,
    totalAds:""
  },
  loading: false,
  currentPage: 1,
};

export const adsSlice = createSlice({
  name: "adsData",
  initialState: initialAdDataState,
  reducers: {
    addAdReducer: (state, action) => {
      state.data = action.payload;
    },
    setAd: (state, action) => {
      state.data = action.payload;
    },
    setAdLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAdsCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setAd, setAdLoading, setAdsCurrentPage } = adsSlice.actions;

export const selectAdData = (state: { adsData: AdsDataState }) => state.adsData.data;
export const selectAdLoading = (state: { adsData: AdsDataState }) => state.adsData.loading;
export const selectCurrentPageOfAds = (state: { adsData: AdsDataState }) => state.adsData.currentPage;

export default adsSlice.reducer;
