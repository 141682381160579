import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Radio, Row } from "antd";
import { fetchAllPackages } from "../../redux/actions/packagesAction";
import { selectPackagesData } from "../../redux/slices/packagesSlice";
import { AppDispatch } from "../../redux/store";
import { RadioChangeEvent } from "antd/lib/radio";

interface ADpackagesProps {
  onPackageChange: (selectedPackage: string) => void;
  preSelectedPackage: string;
}



const ADpackages: React.FC<ADpackagesProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const packagesData = useSelector(selectPackagesData);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [editPackage, setEditPackage] = useState(false);

  useEffect(() => {
    setSelectedPackage(props.preSelectedPackage)
    if(props.preSelectedPackage !== ''){
      setEditPackage(true);
    }
    dispatch(fetchAllPackages());

  }, [dispatch]);

  const packagesArr = useMemo(() => {
    return packagesData;
  }, [packagesData]);

  const handlePackageChange = (e: RadioChangeEvent) => {
    setSelectedPackage(e.target.value);
    props.onPackageChange(e.target.value);
  };

  const handleCardClick = (packageName: string) => {
    if(!editPackage){
      setSelectedPackage(packageName);
      props.onPackageChange(packageName);
    }
  };

  return (
    <div>
      <Radio.Group
        onChange={handlePackageChange}
        value={selectedPackage}
        style={{ display: "block" }}
        defaultValue={'BASIC'}
        disabled={true}
      >
        <Row gutter={30} style={{ marginTop: "5px" }}>
          {packagesArr?.map((p) => (
            <Col span={8} key={p.name}>
              <div onClick={() => handleCardClick(p.name)}>
                <div>
                <Card
                  bordered={selectedPackage === p.name}
                  style={{
                    border: selectedPackage === p.name ? "3px solid #0095FF" : "",
                  }}
                >
                  <Radio
                    value={p.name}
                    style={{ display: "flex", alignContent: "flex-end"  }}
                    defaultChecked={true}
                    disabled={editPackage}
                  />
                  <h4>{p.name}</h4>
                  <h3>€{p.price / 100}</h3>
                  <p>{p.impressions} përshtypje</p>
                </Card>
              </div>
              </div>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </div>
  );
};

export default ADpackages;
