import { Rule } from "antd/es/form"
import { ColProps } from "antd/es/grid/col";

type ValidationType = {
    password: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps,
    };
}


export const inputs: ValidationType = {
    password: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                required: true,
                message: 'Ju lutemi vendosni fjalekalimin!',
            }
        ]
    },
}