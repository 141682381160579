import React, {useState} from 'react';
import classes from './AddEditCategoriesModal.module.css'


import {Form, Input, Modal} from 'antd'


function AddPostByScrapping({showModal, setShowModal}){

    const [form] = Form.useForm();



    const [url, setUrl] = useState('');


    const handleOk =() => {

        setTimeout(() => {
            setShowModal(false);
        }, 2000);

    }

    const handleCancel = () =>{
        form.resetFields();
        setShowModal(false);

    }

    const urlChangeHandler = (event) => {
        setUrl(event.target.value);
    }

    return (
        <Modal destroyOnClose={true} okText="Add by Scrapping - send Request" centered bodyStyle={{height: 500}} open={showModal}
               onOk={form.submit}
               onCancel={handleCancel} width={800}>

            <div className={classes.modalHeader}>
                <h2>Add Post by Scrapping</h2><h4>Here you can put the link for scrapping</h4>
            </div>

            <div>

                <Form
                    preserve={false}
                    className={classes.addCategoriesForm}
                    name='basic'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    form={form}
                    onFinish={handleOk}
                >
                    <Form.Item
                        //label='Title;
                        name="url"
                        className={classes.formItem}
                        rules={[{ required: true, message: 'Please input title!' }]}
                    >
                        <Input placeholder="URL" id='url' className={classes.inputArea} name='title' value={url}
                               onChange={urlChangeHandler} style={{resize: 'none'}} required/>
                    </Form.Item>

                </Form>

            </div>


        </Modal>
    )

}

export default AddPostByScrapping;