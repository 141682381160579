import classes from "./TextInput.module.css"
import React, {useState} from "react";
import {BiHide, BiShow} from 'react-icons/bi'


const  TextInput = (props) => {

    const [type, setType] = useState(props.type);

    const clickHandler = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }

    return (<div className={classes.textInputContainer}>
            <input
                value={props.value}
                className={classes.textInput}
                type={type}
                id={props.id}
                placeholder={props.placeholder}
                required
                onChange={props.onChange}
            />
            {props.type === 'password' ? ( type ==="text" ?
                <BiHide className={classes.textInputIcon} onClick={clickHandler}/>
                : <BiShow className={classes.textInputIcon} onClick={clickHandler}/> ) : null
             }
        </div>)
}

export default TextInput;
