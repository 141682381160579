import React from "react";
import "./Terms.css";
import logo from "../../../assets/images/logo.png";
import { FaAngleLeft } from "react-icons/fa";

function Termsofuse() {
  const style = { color: "white", fontSize: "15px" };

  return (
    <div className="body-terms">
      <div id="return-button">
        <FaAngleLeft style={style} />
        <a href="/">Back</a>
      </div>
      <div className="content">
        <div className="body-header">
          <h1>Online Kosova</h1>
          <p>Terms of use</p>
        </div>
        <div className="text">
          <div>
            <h1>Acceptance of Terms</h1>
            <p>
              By using Online Kosova (the &quot;App&quot;), you agree to be
              bound by these Terms of Use. If you do not agree to these terms,
              please refrain from using the App.
            </p>
          </div>
          <br />
          <div>
            <h1>Use of the App</h1>
            <p>
              <strong>Account Creation:</strong> To access certain features of
              the App, you may be required to create an account. You are
              responsible for maintaining the confidentiality of your account
              information and agree to notify us immediately of any unauthorized
              use.
              <br />
              <strong>User Conduct:</strong> You agree not to use the App for
              any unlawful purpose or in violation of any applicable laws. You
              also agree not to: a. Post or transmit any content that is
              unlawful, harmful, threatening, abusive, or otherwise
              objectionable (this is specific to ads creation). b. Attempt to
              gain unauthorized access to any portion of the App.
            </p>
          </div>
          <br />
          <div>
            <h1>Intellectual Property</h1>
            <p>
              <strong>Ownership:</strong> The App and its original content,
              features, and functionality are owned by Online Kosova and are
              protected by international copyright, trademark, patent, trade
              secret, and other intellectual property or proprietary rights
              laws.
              <br />
              <strong>License:</strong> YOnline Kosova grants you a limited,
              non-exclusive, revocable license to use the App for your personal,
              non-commercial purposes and also if you intend to promote
              something through ads if you are a business owner.
            </p>
          </div>
          <br />
          <div>
            <h1>Limitation of Liability</h1>
            <p>
              <strong>Disclaimer:</strong>
              The App is provided as is without any warranties, express or
              implied. Online Kosova makes no representations or warranties
              regarding the accuracy or completeness of the content provided in
              the App.
              <br />
              <strong>Limitation of Liability:</strong> Online Kosova and its
              affiliates shall not be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              any way connected with the use of the App.
            </p>
          </div>
          <br />
          <div>
            <h1>Termination</h1>
            <p>
              Online Kosova reserves the right to terminate or suspend your
              access to the App, without notice, if you breach any of these
              Terms.
            </p>
          </div>
          <br />
          <div>
            <h1>Changes to Terms</h1>
            <p>
              Online Kosova reserves the right to update these Terms of Use at
              any time. You will be notified of any changes by posting the new
              Terms of Use on this page.
            </p>
          </div>
          <div>
            <br />
            <h1>Contact Us</h1>
            <p>
              If you have any questions or concerns about these Terms of Use,
              please contact us at{" "}
              <a href="mailto:onlinekosova@gmail.com">
                {" "}
                onlinekosova@gmail.com
              </a>
            </p>
          </div>
          <br />
          <div>Thank you!</div>
        </div>
        <div className="updated-text">
          {" "}
          <p>Last updated: 15.11.2023</p>
        </div>
      </div>
    </div>
  );
}

export default Termsofuse;
