import { createSlice } from "@reduxjs/toolkit";
import { PostModel } from "./postSlice";


interface NewsState {
    data: PostModel[];
    activePage: number;
    loading: boolean;
    activeNews: PostModel;
    activeIndex: number;
    totalElements: number;
    totalPages: number;
    searchValue: string;
    searchNewsData: {
        data: {
            content: PostModel[],
            totalPages: number,
            totalElements: number
        }
        currentPage: number;
    } ;
    saveNewsData : {
        data: {
            content: PostModel[],
            totalPages: number,
            totalElements: number
        }
        currentPage: number;
    };
}



const initialPostDataState: NewsState = {
    data: [],
    activePage: 0,
    loading: false,
    activeNews: null,
    activeIndex: 0,
    totalElements: 0,
    totalPages: 0,
    searchValue: '',
    searchNewsData: {
        data:{
            content: [],
            totalPages: 0,
            totalElements: 0
        },
        currentPage: 1
    },
    saveNewsData: {
        data: {
            content: [],
            totalPages: 0,
            totalElements: 0
        },
        currentPage: 1
    }
};

export const newsSlice = createSlice({
    name: 'newsData',
    initialState: initialPostDataState,
    reducers: {
        setNews: (state, action) => {
            state.data = action.payload.content;
            state.totalElements = action.payload.totalElements;
            state.totalPages = action.payload.totalPages;
        },
        setNextPageNews: (state, action) => {
            state.data = [ ...state.data, ...action.payload.content ];
            state.activePage = state.activePage + 1;
        },
        setFirstActiveNews: (state, action) => {
            state.activeNews = state.data[0];
            state.activeIndex = 0;
            state.activePage = 0;
        },
        nextActiveNews: (state, action) => {
            if (state.activeIndex + 1 < state.data.length) {
                state.activeNews = state.data[state.activeIndex + 1];
                state.activeIndex = state.activeIndex + 1;
            }
        },
        prevActiveNews: (state, action) => {
            if (state.activeIndex - 1 >= 0) {
                state.activeNews = state.data[state.activeIndex - 1];
                state.activeIndex = state.activeIndex - 1;
            }
        },
        setSearchValue : (state,action) => {
            state.searchValue = action.payload;
        },
        setSearchNewsData : (state,action) => {
            state.searchNewsData = action.payload;
        },
        setSearchNewsPage: (state, action) => {
            state.searchNewsData.currentPage = action.payload
        },
        setSaveNewsData : (state, action) => {
            state.saveNewsData = action.payload;
        },
        setNewsById: (state, action) => {
            state.data = [action.payload.data, ...state.data];
            state.totalElements = state.totalElements + 1;
        },

    }
});

export const { setNews, setNextPageNews, setFirstActiveNews, nextActiveNews, prevActiveNews, setSearchValue, setSearchNewsData, setSearchNewsPage, setSaveNewsData, setNewsById } = newsSlice.actions;

export const selectActiveIndexNews = (state: { news: NewsState }) => state.news.activeIndex;
export const selectNews = (state: { news: NewsState }) => state.news.data;
export const selectCurrentPageNews = (state: { news: NewsState }) => state.news.activePage;
export const selectActiveNews = (state: { news: NewsState }) => state.news.activeNews;
export const selectSearchValue = (state: { news: NewsState }) => state.news.searchValue;
export const selectSearchNewsData = (state: { news: NewsState }) => state.news.searchNewsData;
export const selectCurrentSearchPage =  (state: { news: NewsState }) => state.news.searchNewsData.currentPage;
export const selectSaveNewsData = (state: {news: NewsState}) => state.news.saveNewsData;

export default newsSlice.reducer;
