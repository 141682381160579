import React from "react";
import "./Terms.css";
import logo from "../../../assets/images/logo.png";
import { FaAngleLeft } from "react-icons/fa";

function Privacypolicy() {
  const style = { color: "white", fontSize: "15px" };

  return (
    <div className="body-terms">
      <div id="return-button">
        <FaAngleLeft style={style} />
        <a href="/">Back</a>
      </div>
      <div className="content">
        <div className="body-header">
          <h1>Online Kosova</h1>
          <p>Privacy Policy</p>
        </div>
        <div className="text">
          <div>
            <h1>Introduction</h1>
            <p>
              Welcome to Online Kosova! This Privacy Policy is designed to help
              you understand how we collect, use, disclose, and safeguard your
              personal information. By using our app, you agree to the terms
              outlined in this policy.
            </p>
          </div>
          <br />

          <div>
            <h1>Information We Collect</h1>
            <p>
              We collect the following information: <br />- Email and Name: We
              collect your email address and name when you create an account to
              enable you to save your favorite news.
            </p>
          </div>
          <br />

          <div>
            <h1>How We Use Your Information</h1>
            <p>
              We use the collected information for the following purposes:
              <br />
              - Personalization: To personalize your experience and provide
              content tailored to your preferences.
              <br />- Account Management: To manage your account, including
              enabling you to save and access your favorite news.
              <br />- Communication: To send you updates, notifications, and
              other information related to the app.
            </p>
          </div>
          <br />

          <div>
            <h1>Data Security</h1>
            <p>
              We prioritize the security of your information and take
              appropriate measures to protect it.
            </p>
          </div>
          <br />

          <div>
            <h1>Disclosure of Information</h1>
            <p>
              We do not sell, trade, or otherwise transfer your personally
              identifiable information to third parties. However, we may share
              aggregated, non-personal information with third parties for
              statistical purposes.
            </p>
          </div>
          <br />

          <div>
            <h1>Your Choices</h1>
            <p>
              You can manage your information through the app settings. You have
              the right to access, correct, or delete your personal information.
              If you have any concerns or requests regarding your data, please
              contact us at{" "}
              <a href="mailto:onlinekosova@gmail.com">onlinekosova@gmail.com</a>
            </p>
          </div>
          <br />

          <div>
            <h1>Children&#39;s Privacy</h1>
            <p>
              Our app is not intended for children under the age of 13. We do
              not knowingly collect or solicit personal information from
              children. If you are a parent or guardian and believe that your
              child has provided us with personal information, please contact
              us.
            </p>
          </div>
          <br />

          <div>
            <h1>Changes to This Privacy Policy</h1>
            <p>
              We reserve the right to update our Privacy Policy at any time. You
              will be notified of any changes by posting the new Privacy Policy
              on this page.
            </p>
          </div>
          <br />

          <div>
            <h1>Contact Us</h1>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at
              <a href="mailto:onlinekosova@gmail.com">
                {" "}
                onlinekosova@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="updated-text">
          {" "}
          <p>Last updated: 15.11.2023</p>
        </div>
      </div>
    </div>
  );
}

export default Privacypolicy;
