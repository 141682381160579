import {
  setNews,
  nextActiveNews,
  prevActiveNews,
  setFirstActiveNews,
  setNextPageNews,
  setSearchNewsData,
  setSearchNewsPage,
  setSaveNewsData,
  setNewsById,
} from "../slices/newsSlice";
import API from "../api";
import { message } from "antd";

export const fetchNews =
  (page, activeCategory, sourceIds, country) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const response = await API.get(
        `${process.env.REACT_APP_BACKEND_API}v3/posts/?page=${page}${
          activeCategory ? `&categoryId=${activeCategory}` : ``
        }${sourceIds ? `&sourceIds=${sourceIds}` : ``}${
          country ? `&country=${country}` : ``
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (page === 0) {
        dispatch(setNews(response.data));
      } else {
        dispatch(setNextPageNews(response.data));
      }
    } catch (err) {
      throw new Error(err);
    }
  };

export const nextActiveNewsAction = () => (dispatch) => {
  dispatch(nextActiveNews());
};

export const prevActiveNewsAction = () => (dispatch) => {
  dispatch(prevActiveNews());
};

export const setFirstActiveNewsAction = () => (dispatch) => {
  dispatch(setFirstActiveNews());
};

export const searchNews = (page, search) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}posts?page=${page}&title=${search}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    dispatch(setSearchNewsData(response));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const changeSearchNewsPage = (page) => async (dispatch, getState) => {
  dispatch(setSearchNewsPage(page));
};

export const fetchSavedNews = (page) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}users/self/posts?page=${page}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    dispatch(setSaveNewsData(response));
  } catch (err) {
    throw new Error(err);
  }
};

export const addSavedNews = (id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = { Authorization: "Bearer " + token };
    const response = await API.post(
      `${process.env.REACT_APP_BACKEND_API}users/self/posts/${id}/favorite`,
      { headers: headers }
    );
  } catch (err) {
    message.error(err.response.data.message);
    throw new Error(err);
  }
};

export const removeSavedNews = (id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = { Authorization: "Bearer " + token };
    const response = await API.delete(
      `${process.env.REACT_APP_BACKEND_API}users/self/posts/${id}/favorites`,
      { headers: headers }
    );
  } catch (err) {
    message.error(err.response.data.message);
    throw new Error(err);
  }
};

export const fetchNewsById = (id) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}posts/${id}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    dispatch(setNewsById(response));
    dispatch(setFirstActiveNews());
  } catch (err) {
    throw new Error(err);
  }
};
