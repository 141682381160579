import React, {useState, useEffect} from 'react';
import {Drawer} from 'antd';
import {IoIosArrowForward, IoIosCloseCircleOutline, IoMdLogOut} from "react-icons/io";
import classes from './SideDrawer.module.css'
import {useNavigate} from "react-router-dom";
import {logoutReducer, selectIsAdmin} from "../../redux/slices/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {auth} from "../../firebase";
import {AppDispatch} from "../../redux/store";
import {fetchSavedNews} from "../../redux/actions/newsActions";

interface SideDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    isAuthenticated: boolean
}

const SideDrawer: React.FC<SideDrawerProps> = ({isOpen, onClose, isAuthenticated}) => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const isAdmin = useSelector(selectIsAdmin);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const showDrawer = () => {
        setOpen(true);
    };

    const navigateToAdmin = () => {
        isAuthenticated && isAdmin ?
            navigate('/posts')
            : navigate('/adslist')
    }

    const logoutOfApp = () => {
        dispatch(logoutReducer());
        auth.signOut();
        navigate('/login')
    }

    const savedNewsFunction = () => {
      dispatch(fetchSavedNews(0)).then(() => navigate('/savednews'));
    }

    return (
        <>
            <Drawer
                placement="right"
                onClose={onClose}
                open={open}
                style={{backgroundColor: 'black'}}
                bodyStyle={{color: 'white'}}
                className={classes.styledDrawer}
                closeIcon={<IoIosCloseCircleOutline style={{fontSize: '30px', color: 'white', float: 'right'}}/>}
            >
                {isAuthenticated ?
                    <div className={classes.drawerDiv}>
                        <div
                            className={classes.drawerContent}
                            onClick={() => {
                                navigate('/editprofile');
                            }}
                        >
                            <h2>Edito profilin</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent} onClick={savedNewsFunction} >
                            <h2>Lajmet e ruajtura</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent} onClick={() => {
                            navigate('/selectstate')
                        }}>
                            <h2>Selekto burimin e lajmeve</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent} onClick={() => {
                            navigate('/selectsources')
                        }}>
                            <h2>Selekto kanalet e preferuara</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div
                            className={classes.drawerContent}
                            onClick={navigateToAdmin}
                        >
                            <h2>Reklamo biznesin tuaj</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent}>
                            <h2 style={{color: '#E00202'}} onClick={logoutOfApp}>Largohu nga platforma </h2>
                            <IoMdLogOut style={{fontSize: '20px', color: '#E00202'}}/>
                        </div>
                    </div>
                    :
                    <div className={classes.drawerDiv}>
                        <div className={classes.drawerContent} onClick={() => navigate("/login")}>
                            <h2>Kyqu ne platforme</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent} onClick={() => {
                            navigate('/selectstate')
                        }}>
                            <h2>Selekto burimin e lajmeve</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent} onClick={() => {
                            navigate('/selectsources')
                        }}>
                            <h2>Selekto kanalet e preferuara</h2>
                            <IoIosArrowForward/>
                        </div>
                        <div className={classes.drawerContent}>
                            <h2>Reklamo biznesin tuaj</h2>
                            <IoIosArrowForward/>
                        </div>
                    </div>
                }
            </Drawer>
        </>
    );
};

export default SideDrawer;
