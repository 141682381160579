import React, {useEffect} from "react";

import classes from './RedirectUrl.module.css'
import logo from "../../assets/images/logo.png";
import {Spin} from "antd";

const RedirectUrl: React.FC = () => {

    useEffect(() => {
        const urlParam = new URLSearchParams(window.location.search).get('url');

        if (urlParam) {
            setTimeout(() => {
                window.location.href = decodeURIComponent(urlParam);
            }, 2000);
        }

    }, []);


    return (
        <div className={classes.redirectDiv}>
            <img className={classes.redirectLogo} src={logo} alt="logo" />
            <h1>Online Kosova </h1>
            <h5>Burim i besueshëm i informacionit për të gjithë shqiptarët.</h5>
            <Spin
                size="large"
                tip={'Redirecting to the source!'}
                style={{
                    marginTop:'2%'
                }}
            />


        </div>

    );
};

export default RedirectUrl;
