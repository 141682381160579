import React, {useEffect, useState} from "react";
import classes from './VerifyEmail.module.css'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

import {Spin} from "antd";
import {HiCheckCircle} from 'react-icons/hi'
import {verifyEmail} from "../../redux/actions/verifyEmail";

const VerifyEmail: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading]= useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get("token");
        if(token){
            dispatch(verifyEmail(token)).then(() => setLoading(false));
        }
    })

    return (
        <div className={classes.verifyPage}>
            {loading ? (
                <div className={classes.loadingText}>
                <Spin
                    size="large"
                />
                <h2>Verifying Email</h2>
                </div>
            ) : (
                <div className={classes.verifiedText}>
                    <HiCheckCircle style={{color: '#285ADC', fontSize: '5rem'}}/>
                    <h1>Verified</h1>
                    <h2>Your Email has been successfully verified.
                        Now you can enjoy full acces to our platform.</h2>
                </div>
                )}
        </div>
    );
};

export default VerifyEmail;
