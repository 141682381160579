import React, { useState } from "react";
import classes from './ForgotPassword.module.css'
import logo from "../../assets/images/logo.png";
import TextInput from "../../components/TextInput/TextInput";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {changePassword, forgotPassword} from "../../redux/actions/passwordActions";
import {Button, Form, Input, message} from "antd";
import { inputs } from "./forgotPasswordForm";


const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch<AppDispatch>();

    const [buttonDisabled, setButtonDisabled]= useState(false);

    const onSubmit = ()=> {
        if(email) {
            setButtonDisabled(true)
            dispatch(forgotPassword({ email }));
        } else {
            setButtonDisabled(true)
            message.info('Ju lutemi kontrolloni e-mailin!')
        }
    }

    const emailHandler = (e) => {
        setButtonDisabled(false)
        setEmail(e.target.value);
    }

    const handleKeyDown: (event) => void = (event) => {
        if (event.key === "Enter") {
        }
    };

    const [form] = Form.useForm();

    return (
        <div className={classes.logIn}>
            <img className={classes.logInLogo} src={logo} alt="logo" />
            <h1>Online Kosova </h1>
            <Form
                form={form}
                name="register"
                onFinish={onSubmit}
                scrollToFirstError>
                <Form.Item
                    name="email"
                    className={classes.styledInput}
                    style={{width: '100%'}}
                    {...inputs.email}
                >
                    <Input placeholder='Vendosni email-in tuaj'  className={classes.emailInput}
                           onChange={emailHandler}/>
                </Form.Item>
                <Form.Item
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    style={{width: '100%', maxWidth:'400px'}}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{width: '100%'}}
                        onKeyDown={handleKeyDown}
                        onClick={onSubmit}
                        disabled={buttonDisabled}
                    >
                        Send Email
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
