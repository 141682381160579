import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BiTrash } from 'react-icons/bi';
import {RiPencilFill} from 'react-icons/ri'

import Card from '../../components/UI/Card';
import classes from '../Categories/Categories.module.css';
import DataTable from '../../components/Table/DataTable';
import { fetchCategories } from '../../redux/actions/categoriesActions';
import { selectCategoriesData } from '../../redux/slices/categoriesSlice';
import AddCategoriesModal from '../../components/Modal/AddCategoriesModal';
import EditCategoriesModal from '../../components/Modal/EditCategoriesModal';
import DeleteCategoryModal from "../../components/Modal/DeleteCategoryModal";
import noImage from '../../assets/images/noImage.jpg';
import type { ColumnsType } from 'antd/es/table';


import type {AppDispatch} from '../../redux/store';
import DeleteAlbanianCategoryModal from '../../components/Modal/DeleteAlbanianCategory';
import { fetchAlbanianCategories } from '../../redux/actions/categoriesAlbaniaAction';

interface CategoryData {
    key : React.Key;
    title: string;
    dataIndex: string;
    width: string;
    render: any;

}


const AlbanianCategories: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        setLoading(true); // set loading to true
        dispatch(fetchAlbanianCategories())
            .then(() => setLoading(false)); // set loading to false when the data has been fetched
    }, [dispatch]);


    const [editingCategory, setEditingCategory] = useState(null);
    const [deletingCategory, setDeletingCategory] = useState(null);

    const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

    const [loading, setLoading] = useState(false);

    // const showEditModal = (record: any) => {
    //     setShowEditCategoriesModal(true);
    //     setEditingCategory({ ...record });
    // };

    const showDeleteModal = (id) =>{
        console.log(id);
        setShowDeleteCategoryModal(true);
        setDeletingCategory(id);
    }



    const categoriesData = useSelector(selectCategoriesData);
    
    const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);
    const [page] = useState(1);
    

    const columns: ColumnsType<CategoryData> = [
        {
            className: classes['column-nr'],
            title: 'Nr',
            dataIndex: 'nr',
            key: 'nr',
            width: '6rem',
            align: 'center',
            render: (value: any, item: any, index: any) => (page - 1) * 10 + index + 1,
        },
        {
            className: classes['column-image'],
            title: 'Image',
            dataIndex: 'categoryImage',
            key: 'categoryImage',
            width: '20rem',
            align: 'center',
            render: (categoryImage: string) => (
                <img
                    onError={(e: any) => {
                        e.target.onerror= null;
                        e.target.src = noImage;
                    }}
                    src={categoryImage}
                    alt={categoryImage}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '20rem',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '5rem',
            render: (text: string, record: any) => (
                <div className={classes.actionButtons}>
                    {/* <Button
                        type="text"
                        icon={<RiPencilFill style={{ color: '#242424', fontSize: 22 }} />}
                        onClick={() => showEditModal(record)}
                    /> */}
                    <Button
                        type="text"
                        icon={
                            <BiTrash
                                style={{ color: '#E00202', fontSize: 23 }}
                            />
                        }
                        onClick={() => showDeleteModal(record.id)}
                    />

                </div>
            ),
        },
    ];

    return(
        <div className={classes.categoriesList}>

            {loading ? (
                <Spin size="large"
                      tip={'The category page is loading please wait!'}
                          style={{
                          marginTop:'15%'
                      }}
                />
            ) : (
            <Card className={classes.categoriesCard}>
                            {/* <EditCategoriesModal showModal={showEditCategoriesModal} setShowModal={setEditingCategory}
                              editingCategoryRecord={{...editingCategory}}/> */}
                <DeleteAlbanianCategoryModal showModal={showDeleteCategoryModal} setShowModal={setShowDeleteCategoryModal} categoryId={deletingCategory}/>
                <div className={classes.categoriesCardHeader}>
                    <div className={classes.categoriesCardTitles}>
                        <h1 className={classes.categoriesListTitle}>Category</h1>
                        <h2 className={classes.categoriesListSubtitle}>Here are all categories in the database</h2>
                        <h2 className={classes.categoriesListSubtitle}>Total Categories: {categoriesData.totalElements}</h2>

                    </div>
                    {/* <Button className={classes.AddCategory} type='primary' icon={<PlusOutlined />} onClick={()=>{setShowAddCategoriesModal(true);}}  size="middle">Add Category</Button> */}
                    {/* <AddCategoriesModal setShowModal={setShowAddCategoriesModal} showModal={showAddCategoriesModal} /> */}

                </div>
                {categoriesData.content && <DataTable data={categoriesData.content} headTableData={columns} />}
                {categoriesData.content === undefined && <h2 className={classes.loadingText}>INCORRECT API REQUEST!</h2>}
                {/*{categoriesData.content?.length === 0 && <h2 className={classes.loadingText}>NO SOURCES FOUND! </h2>}*/}
            </Card>
                )}
        </div>
    )
}
export default AlbanianCategories;

