import { Rule } from "antd/es/form"
import { ColProps } from "antd/es/grid/col";

type ValidationType = {
    name: {
        label: string;
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps,
    };
    email: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps;
    };
    password: {
        rules: Rule[],
        tooltip: string,
        wrapperCol: ColProps,
        labelCol: ColProps,
    };
    confirmPassword: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps;
    };
    agreement: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps;
    };
}



export const inputs: ValidationType = {
    name: {
        label: "Emri dhe Mbiemri",
        rules: [{required: true, message: 'Ju lutemi vendosni Emrin!', whitespace: true}],
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
    },
    email: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                type: 'email',
                message: 'Ju lutemi vendosni E-mail valid!',
            },
            {
                required: true,
                message: 'Ju lutemi vendosni E-mail-in!',
            }
        ],
    },
    password: {
        tooltip: "Fjalekalimi duhet te permbaje te pakten nje shkronje te madhe, numer dhe nje simbol!",
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                required: true,
                message: 'Ju lutemi vendosni fjalekalimin!',
            },
            {
                validator: (_, value) => {
                    if (value && /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        'Fjalekalimi duhet te permbaje te pakten nje shkronje te madhe, numer dhe nje simbol!'
                    );
                },
            }
        ]
    },
    confirmPassword: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                required: true,
                message: 'Ju lutemi konfirmoni fjalekalimin!',
            },
            ({getFieldValue}) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ju lutemi vendosni fjalekalimin e njejte!'));
                },
            }),
        ]
    },
    agreement: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Ju lutemi rishikoni kushtet Terms and conditions')),
            }
        ]
    }
}