import styles from "./Layout.module.css";
import {Outlet} from "react-router";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

import {Layout} from 'antd';

const { Content } = Layout;

export default function Layouts() {
    return (
        <Layout className={styles.mainLayouts}>
                <Sidebar/>
            <Layout className={styles.layout}>
                <Header/>
                <Content className={styles.Content}>
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>

    );
};
