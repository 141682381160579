import React, {useEffect, useState} from 'react';
import Card from '../../../src/components/UI/Card'
import classes from "../Sources/Sources.module.css";
import DataTable from "../../components/Table/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {Pagination, Spin} from "antd";
import type {ColumnsType} from 'antd/es/table';
import type {AppDispatch} from '../../redux/store';
import {selectCurrentPageOfUsers, selectUserData} from "../../redux/slices/userSlices";
import {changeUsersCurrentPage, fetchUsers} from "../../redux/actions/userAction";
import  {FaTimesCircle, FaCheckCircle} from 'react-icons/fa';

interface UsersData {
    key: React.Key;
    nr: number;
    id: string;
    fullName: string;
    email: string;
    verified: boolean;

}
const Users: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();

    const currentPage = useSelector(selectCurrentPageOfUsers);

    const [loading, setLoading] = useState(false);
    const usersData = useSelector(selectUserData);

    useEffect(() => {
        dispatch(fetchUsers(currentPage))
            .then(() => setLoading(false)); // set loading to false when the data has been fetched
    }, [currentPage, dispatch])

    const columns: ColumnsType<UsersData> = [
        {
            title: 'Nr',
            dataIndex: 'nr',
            key: 'nr',
            width: '5%',
            align: 'center',
            render: ((value, item, index) => (currentPage - 1) * usersData.size + index + 1),
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            align: 'left',
            width: '27%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            width: '27%',
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            align: 'left',
            width: '27%',
            render: (verified) => (
                <div>{verified ? (<FaCheckCircle size={20} color='green'/>) : (<FaTimesCircle size={20} color='red'/>)}</div>
            )
        },

    ];

    const changeCurrentPageOfUsers = (page: number) => {
        dispatch(changeUsersCurrentPage(page));
    }
    return (
        <div className={classes.sourcesList}>
            {loading ? (
                <Spin size="large"
                      tip={'The source page is loading please wait!'}
                      style={{
                          marginTop: '15%'
                      }}
                />
            ) : (
                <Card className={classes.sourcesCard}>
                    <div className={classes.sourcesCardHeader}>
                        <div className={classes.sourcesListTitles}>
                            <h1 className={classes.sourcesListTitle}>Users</h1>
                            <h2 className={classes.sourcesListSubtitle}>Here are all the users in the database</h2>
                            <h2 className={classes.sourcesListSubtitle}>Total Users: {usersData.totalElements}</h2>

                        </div>

                    </div>
                    {usersData?.content && <DataTable data={usersData?.content} headTableData={columns}/>}
                    <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={20}
                        total={usersData.totalElements}
                        onChange={(page) => {
                            changeCurrentPageOfUsers(page)
                        }}
                        showSizeChanger={false}
                    />
                </Card>
            )}
        </div>
    )
}

export default Users;

