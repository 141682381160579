import {createSlice} from "@reduxjs/toolkit";
import { CategoryModel} from "./categoriesSlice";
import {SourceModel} from "./sourceSlice";

export interface PostModel {
    id: string;
    subTitle: string;
    title: string;
    name: string;
    sourceImage: string;
    originalPostUrl: string;
    imageUrl: string;
    categories: CategoryModel[];
    source: SourceModel;
    country:string;
    publishedDate: string;
    isFavorite: boolean;
}

interface PostDataState {
    data: {
        content: PostModel[],
        totalPages: number,
        totalElements: number
    };
    loading: boolean;
    currentPage: number;
}

const initialPostDataState: PostDataState = {
    data: {
        content: [],
        totalPages: 0,
        totalElements: 0
    },
    loading: false,
    currentPage: 1
};

export const postSlice = createSlice({
    name: 'postsData',
    initialState: initialPostDataState,
    reducers: {
        addPostReducer: (state, action) => {
            state.data = action.payload;
        },
        setPost: (state, action) => {
            state.data = action.payload;
        },
        setPostLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPostsCurrentPage: (state, action) => {
            state.currentPage = action.payload
        }
    },
});

export const { setPost, setPostLoading, setPostsCurrentPage} = postSlice.actions;


export const selectPostData = (state: {postsData: PostDataState}) => state.postsData.data;
export const selectPostLoading = (state: {postsData: PostDataState}) => state.postsData.loading;
export const selectCurrentPageOfPosts = (state: {postsData: PostDataState}) => state.postsData.currentPage;

export default postSlice.reducer;
