import styles from "./Header.module.css";
import {logoutReducer, selectUserInfo} from "../../../redux/slices/authSlice";
import {auth} from "../../../firebase";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {HiUser} from 'react-icons/hi'
import { Dropdown, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export default function Header() {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const logoutOfApp = () => {
        dispatch(logoutReducer());
        auth.signOut();
        navigate('/login')
    }

     const currentUser = useSelector(selectUserInfo);

    const items = [
        {
            key: '1',
            label: (
        <Button onClick={logoutOfApp} type="link" style={{width:'100%', textAlign:'left'}}>Sign Out</Button>
)
        }
    ]

    return (
        <div className={styles.Header}>
            <div>
                <Dropdown menu={{ items }}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space style={{color: "#2561F9"}}>
                            <HiUser style={{ fontSize: "1.3rem" }}/>
                            <span  style={{color: "#3E435D"}}>{currentUser.fullName}</span>
                            <DownOutlined  style={{color: "#000000"}} />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};
