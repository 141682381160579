import { createSlice } from "@reduxjs/toolkit";

interface UserModel {
    id: string;
    fullName: string;
    email: string;
    verified: boolean;
}

interface UserDataState {
    data: {
        content: UserModel[],
        totalPages: number,
        totalElements: number,
        size: number,
    };
    loading: boolean;
    currentPage: number;
}

const initialUserDataState: UserDataState = {
    data: {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0
    },
    loading: false,
    currentPage: 1
};

export const userSlice = createSlice({
    name: 'usersData',
    initialState: initialUserDataState,
    reducers: {
        addUsersReducer: (state, action) => {
            state.data = action.payload;
        },
        setUsers: (state, action) => {
            state.data = action.payload;
        },
        setUsersCurrentPage: (state, action) => {
            state.currentPage = action.payload
        }
    }
});

export const {setUsers, setUsersCurrentPage} = userSlice.actions;

export const selectUserData = (state: { usersData: UserDataState }) => state.usersData?.data;

export const selectCurrentPageOfUsers = (state: { usersData: UserDataState }) => state.usersData.currentPage;

export default userSlice.reducer;


