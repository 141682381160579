import React from "react";
import classes from "./Table.module.css";
import { Table } from "antd";
import styled from 'styled-components';

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: transparent;
    border: none;
    font-size: 0.8rem;
    text-align: left;
  }
  .ant-table-cell {
    border: none;
  }

  .ant-table-cell {
    border: none;
  }
  :where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-ph9edi).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none ;
  }
  .ant-table-row:hover{
    font-weight: 400;
  }

`;


interface DataTableProps {
    headTableData: any;
    data: any;
}

const DataTable: React.FC<DataTableProps> = (props) => {
    const { headTableData, data } = props;
    return (
        <div className={classes.tableWrapper}>
            <StyledTable
                dataSource={data}
                columns={headTableData}
                size={"middle"}
                pagination={false}
            />
        </div>
    );
};

export default DataTable;
