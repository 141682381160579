import axios from 'axios';
import {getAuth} from "firebase/auth";

// const baseURL = process.env.BASE_URL;
const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API
    // baseURL: develop
});

instance.interceptors.request.use(
    async (config) => {
        const currentUser = getAuth().currentUser;

        if (currentUser) {
            const token = await currentUser.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const currentUser = getAuth().currentUser;

        if (
            currentUser &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true;
            try {
                await currentUser.getIdToken(true);
                return instance(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;