import React, {useEffect, useState} from "react";
import classes from "../../components/Modal/DeleteModal.module.css"
import {useDispatch} from "react-redux";
import { deleteAd } from "../../redux/actions/adsAction";
import { CSSProperties } from 'react';


import {Modal, Button} from 'antd';



import type {AppDispatch} from '../../redux/store';

interface Props{
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    adId: string
}

const DeleteAdModal: React.FC<Props> = ({ showModal, setShowModal, adId }) => {


    const dispatch = useDispatch<AppDispatch>();
    const [deleteAdId, setDeleteAdId] = useState(null);


    useEffect(()=>{
        setDeleteAdId(adId);
    }, [showModal]);

    const handleOk = () => {

        dispatch(deleteAd(deleteAdId))

        setShowModal(false);
    };

    const handleCancel = () =>{
        setShowModal(false);
        setDeleteAdId(null);
    }

    const centerStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <Modal destroyOnClose={true} okText="Submit" centered bodyStyle={{height: 130}} open={showModal}
               maskStyle={{ backdropFilter: 'blur(8px)' }}
               // onOk={handleOk}
               onCancel={handleCancel}
               width={800}
               okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed' } }}
               footer={
                   <div style={centerStyle}>
                       <Button key="cancel" onClick={handleCancel}>
                           Cancel
                       </Button>
                       <Button key="submit" type="primary" onClick={handleOk}>
                           Submit
                       </Button>
                   </div>
               }
        >
            <div className={classes.modalHeader}>
                <h2>Delete the Ad?</h2>
                <br/>
                <h4>This will disappear from your ADs List and anywhere else. You wont be able to recover it.</h4>
            </div>
        </Modal>
    );
}

export default DeleteAdModal;



