import React, { useMemo } from "react";
import classes from "./ADSData.module.css";
import { useState } from 'react';
import { Table,Modal,Input, Card, ConfigProvider } from "antd";
import { Progress, Space } from 'antd';
import { selectPackagesData } from "../../redux/slices/packagesSlice";
import { useSelector } from "react-redux";
// import Packages from "../../components/AdsPackages/Packages"


interface DataTableProps {
    headTableData: any;
    data: any;
}


const ADSDataTable: React.FC<DataTableProps> = (props) => {
    const { headTableData, data } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const packagesData = useSelector(selectPackagesData);
    const [activeRecord, setActiveRecord] = useState(null);
    function formatDate(dateStr) {
      const date = new Date(dateStr);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  
  } 
  const packagesArr = useMemo(() => {
    return packagesData;
  }, [packagesData]);
  
    const closeModal = () => {
      setIsModalVisible(false);
    };
    const impressions = activeRecord?.impressionsReached || 0;  // Ensure that activeRecord is not null or undefined

    return (
        <div className={classes.tableWrapper}>
            <Table dataSource={data} columns={headTableData}  size="middle"  onRow={(record) => {
          return {
            onDoubleClick: () => {
              setActiveRecord(record);
              setIsModalVisible(true);           
            },
          };
        }}/>
        
        <ConfigProvider
    theme={{
      components: {
        Modal: {
          colorBgElevated: '#F0F4FC' // here
        },
       
      },
    }}
  >
      
        <Modal
     
       
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        className={classes.modalStyle}
        centered  bodyStyle={{height:"720px"}} 
        maskStyle={{ backdropFilter: 'blur(4px) brightness(105%) saturate(80%)'}}
        width={"750px"}>
          <h4 style={{textAlign:"center"}}>
                          Detajet e reklamës suaj</h4>
           <div style={{marginTop:"30px"}} ><img id="responsive" src={activeRecord?.adImage}  className={classes.imageContainer}/>
           <div className={classes.phoneContainer}/>
           </div>
           
        <div className={classes.divCards}>
            <p style={{marginBottom:"10px"}}>URL</p>
            <Card  className={classes.cardModal} style={{    
    backgroundColor: 'white',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height:"60px",
    width:"330px",
    textOverflow: 'ellipsis'}}>{activeRecord?.url}</Card>
            <p style={{marginBottom:"10px"}}>Data e publikimit</p>
            <Card  className={classes.cardModal} style={{backgroundColor:"white",   height:"60px",
    width:"330px"}} >{formatDate(activeRecord?.createdAt)}</Card>
            <p style={{marginBottom:"10px"}}>
                           Përshtypjet e arritura</p>
                          
             {activeRecord?.adPackage === 'BASIC' ? (
            <Progress percent={activeRecord?.impressionsReached/5} size={[300, 15]} style={{paddingBottom:"20px"}}   format={(percent) => `${impressions}/500  `}/> 
            ) : activeRecord?.adPackage === 'STANDARD' ? (
              <Progress percent={activeRecord?.impressionsReached/10} size={[300, 15]} style={{paddingBottom:"20px"}}   format={(percent) => `${impressions}/1000  `}/> 
            ) : (
              <Progress percent={activeRecord?.impressionsReached/20} size={[300, 15]} style={{paddingBottom:"20px"}} format={(percent) => `${impressions}/2000  `}/> 
            )}
            <p style={{marginBottom:"10px"}}>Paketa e përshtypjeve </p>
            {activeRecord?.adPackage === 'BASIC' ? (
                <Card className={classes.cardPackage}>
                 <h4>BAZIK</h4>
                 <h3 style={{fontSize:"20px"}}>€ 05.00</h3>   
                <p style={{fontSize:"15px"}}>500 përshtypje</p>
                </Card>
            ) : activeRecord?.adPackage === 'STANDARD' ? (
                <Card className={classes.cardPackage} >
                 <h4>STANDART</h4>
                 <h3 style={{fontSize:"20px"}}>€ 08.50</h3>   
                <p>1000 përshtypje</p>
                </Card>
            ) : (
                <Card className={classes.cardPackage}>
                 <h4>PREMIUM</h4>
                 <h3 style={{fontSize:"20px"}}>€ 15.00</h3>   
                <p>2000 përshtypje</p>
                </Card>
            )}
        </div>

       
        </Modal>
        </ConfigProvider>
        </div>

    );
};

export default ADSDataTable;
