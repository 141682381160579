import React, {useEffect, useState} from "react";
import classes from './CreateModalAdvertisment.module.css'
import {useDispatch, useSelector} from "react-redux";

import {Button, DatePicker, Form, Input, Modal, Select, Upload} from 'antd';
import type {UploadProps} from 'antd/es/upload/interface';
import type {AppDispatch} from '../../redux/store';

import upload from "../../assets/images/upload.png";
import ADpackages from "../../CreateAD/ADpacakges/ADpackages";
import {createAd} from "../../redux/actions/adsAction";


const { Dragger } = Upload;

interface Props{
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;

}

const CreateAdvertisement: React.FC<Props> = ({ showModal, setShowModal }) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();

    type NewADState = {
        selectedPackage: string;
    };

    const [selectedPackage, setSelectedPackage] = useState<string>("");

    const handleCancel = ()=> {
        setIsValidURL(true);
        setSelectedFile(null);
        setFileList(null);
        form.resetFields();
        setShowModal(false);
    }
    const [url, setUrl] = useState('');
    const [adImage, setAdImage] = useState('image.png');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isValidFile, setisValidFile] = useState(true);
    const [isValidURL, setIsValidURL] = useState(true);
    const [fileList, setFileList] = useState([]);



    const handlePackageChange = (selectedPackage: string) => {
        if(selectedPackage){
            setSelectedPackage(selectedPackage);
        }
        else {
            const errorMessage = 'Please select a package!';
            console.error(errorMessage);
        } };

    const originalAdUrlChangeHandler = (event) => {
        const inputValue = event.target.value;
        setUrl(inputValue);
        if (inputValue === '') {
            setIsValidURL(true)
        } else {
            setIsValidURL(isUrl(inputValue));
        }
    };

    const handleOk = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        console.log('imageUrl', adImage)
        const payload = {
            adImage,
            url,
            adPackage: selectedPackage,

        }
        dispatch(createAd(payload, formData)).then(()=>{
            setShowModal(false);
            setUrl('');
            setSelectedPackage("");
            setFileList([]);
            setSelectedFile(null);
        });

    };

    function isUrl(str) {
        const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return urlRegex.test(str);
    }

    const props: UploadProps = {

        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
        onChange: (info) => {
            const newFileList = [...info.fileList].slice(-1);
            // 2. Read from response and show file link
            newFileList.forEach((file) => {
                if (file.response) {
                    setAdImage(file.response.url);
                }

            });

            setFileList(newFileList);
            setSelectedFile(newFileList[0]?.originFileObj);

        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const isOkButtonDisabled = !isValidURL || !selectedPackage ||!selectedFile ;

    return (
        <Modal destroyOnClose={true} okText="Create ad" centered bodyStyle={{height: 650}} open={showModal}
               onOk={form.submit}
               onCancel={handleCancel} width={1200}
               okButtonProps={{ style: { backgroundColor: '#2561F9', borderColor: '#24a0ed'}, disabled: isOkButtonDisabled}}>
            <div className={classes.modalHeader}>
                <h2>Add Ads</h2><h4>Here you can add Ads</h4>
            </div>
            <div>
                <div className={classes.mainContainer}>
                    <div className={classes.leftContainer}>
                            <div className={classes.phoneContainer}>
                                {selectedFile ? (
                                    <div>
                                        <img
                                            src={URL.createObjectURL(selectedFile)}
                                            alt="ad image"
                                            className={classes.uploadImageBox}
                                        />
                                        {/*<div className={classes.phoneContainer} style={{top:"0px"}}/>*/}
                                    </div>
                                ) : (
                                    <div/>
                                )}
                            </div>

                    </div>
                    <div className={classes.rightContainer}>
                        <Form
                            preserve={false}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            form={form}
                            onFinish={handleOk}
                            className={classes.card}
                            style={{
                                width: '700px',
                                float: "right",
                                justifyContent: "center",
                                backgroundColor: "#F0F4FC",

                            }}>
                            <div style={{ justifyContent: "space-evenly", padding: "10px", width: '95%' }}>
                                <h3
                                    style={{
                                        padding: "10px",
                                        fontWeight: "normal",

                                    }} >
                                    The URL in this file will guide the user to the main
                                    advertisement page
                                </h3>
                                <div className={classes.isValidUrl}>
                                    {!isValidURL && (
                                        <p style={{ color: 'red' }}>Input is not a valid URL</p>
                                    )}
                                </div>
                                <Form.Item  name="url" className={classes.formItem} rules={[{ required: true, message: 'Please input original post url!' }]}>
                                    <Input
                                        onChange={originalAdUrlChangeHandler}
                                        value={url}
                                        size="large"
                                        placeholder="Enter the URL"
                                        id='url'
                                        name='url'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: 3,
                                            marginBottom: "5px",
                                            height: "72px",
                                        }}
                                    />
                                </Form.Item>

                                <div className={classes.App}>
                                    <Form.Item name="image" className={classes.formItem} rules={[{ required: true, message: 'Please select an image!' }]}>
                                        <div className={classes.fileUploadBox} >

                                            <Dragger listType={"text"} {...props} name="image" id="name" >
                                                <p className="ant-upload-drag-icon">
                                                    <img src={upload} style={{height:"50px",width:"80px"}}/>
                                                </p>
                                                <p className="ant-upload-text">
                                                    Drag and drop your image,or upload it</p>
                                                <p className="ant-upload-hint">
                                                    Image size should be 1080×1920 less than 1MB
                                                </p>
                                            </Dragger>

                                        </div>
                                    </Form.Item>
                                </div>
                                <h2 style={{marginTop: "10px",fontWeight: "normal"}} > Select package</h2>
                                <div className={classes.AdPackagesSection}>
                                    <ADpackages onPackageChange={handlePackageChange} preSelectedPackage={''} />
                                    {!selectedPackage && <span style={{ color: 'red' }}></span>}
                                </div>

                                {/*<Button*/}
                                {/*    onClick={form.submit}*/}
                                {/*    disabled={!isValidURL || !selectedPackage ||!selectedFile }*/}
                                {/*    className={classes.button}*/}
                                {/*    style={{*/}
                                {/*        borderRadius: 5,*/}
                                {/*        float: "right",*/}
                                {/*        marginTop: "40px",          }}*/}
                                {/*    size="large"*/}
                                {/*>*/}
                                {/*    Continue to Payment <RightOutlined />*/}
                                {/*</Button>*/}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CreateAdvertisement;



