import { initializeApp } from 'firebase/app';
// Your web app's Firebase configuration
import {
    getAuth,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword
} from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyD_ScS7t9O6MZs4FKCp8T3JTvlhfidWhko",
    authDomain: "online-kosova.firebaseapp.com",
    projectId: "online-kosova",
    storageBucket: "online-kosova.appspot.com",
    messagingSenderId: "1094862209250",
    appId: "1:1094862209250:web:ad5f1643d10461e8015ebb"
};

//init firebase app
initializeApp(firebaseConfig);

//init services
const auth = getAuth();

export {
    auth,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut
}
