import { Rule } from "antd/es/form"
import { ColProps } from "antd/es/grid/col";

type ValidationType = {
    oldPassword: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps,
    };
    password: {
        rules: Rule[],
        tooltip: string,
        wrapperCol: ColProps,
        labelCol: ColProps,
    };
    confirmPassword: {
        rules: Rule[],
        wrapperCol: ColProps,
        labelCol: ColProps;
    }
}


export const inputs: ValidationType = {
    oldPassword: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                required: true,
                message: 'Ju lutemi vendosni fjalekalimin!',
            }
        ]
    },
    password: {
        tooltip: "Fjalekalimi duhet te permbaje te pakten nje shkronje te madhe, numer dhe nje simbol!",
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                // required: true,
                // message: 'Ju lutemi vendosni fjalekalimin!',
            },
            {
                validator: (_, value) => {
                    if (value && /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/.test(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        'Fjalekalimi duhet te permbaje te pakten nje shkronje, numer dhe nje simbol'
                    );
                },
            }
        ]
    },
    confirmPassword: {
        wrapperCol: {
            span: 24,
        },
        labelCol: {
            span: 24,
        },
        rules: [
            {
                required: true,
                message: 'Ju lutemi konfirmojeni fjalekalimin tuaj!',
            },
            ({getFieldValue}) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ju lutemi vendosni fjalekalimin e njejte!'));
                },
            }),
        ]
    },
}