import "./Footer.css";
import logo from "../../../assets/images/logo.png";
import ScrollUpButton from "../../../components/ScrollUpButton/ScrollUpButton";
// // import DownloadButtons from "../../../components/DownloadButton/DownloadButtons";
// // import { FaMailBulk, FaPhone } from "react-icons/fa";
// // import { Link } from "react-router-dom";
// // import { Modal } from "antd";
import { useState } from "react";
import Termsofuse from "./Termsofuse";
import Privacypolicy from "./Privacypolicy";
import Faq from "./Faq";

const Footer = () => {
  return (
    <div className="footerSection">
      <div className="slide-in-right">
        <img src={logo} alt="" />
      </div>
      <div className="firstSection">
        <h1>
          Online <span className="yellow">Kosova</span>
        </h1>
        <p>Burim i besueshëm i informacionit për të gjithë shqiptarët.</p>
        <div className="contactUS">
          <h2>
            <span className="yellowText">Kontakt</span>
          </h2>
          <div>
            <span className="yellowText"></span>
            <a href="mailto:info.onlinekosova@gmail.com">
              info.onlinekosova@gmail.com
            </a>
            <br />
            <span className="yellowText"></span>
            <a href="tel:+38349212155">+383 49 21 21 55</a>
          </div>
        </div>
      </div>
      <div className="secoundSection">
        <div className="logo">
          <p className="rights">Online Kosova - @2023 All rights reserved.</p>
        </div>
        <div>
          <a href="/terms-of-use">Terms of use</a>
        </div>
        <div>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
        <div>
          <a href="/faq">FAQ</a>
        </div>
        <ScrollUpButton />
      </div>
    </div>
  );
};

export default Footer;
