import React, { useState} from "react";
import {message, Modal, Input} from "antd";
import classes from './ShareModal.module.css'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton
} from "react-share";
import {FaFacebook, FaLinkedin, FaTwitter, FaViber, FaWhatsapp} from "react-icons/fa";
import copy from "copy-to-clipboard";


interface Props {
    showModal : boolean;
    setShowModal: (value: boolean) => void;
    shareUrl: any;
}

const ShareModal: React.FC<Props> = ({ showModal , setShowModal, shareUrl}) => {

    const [title, setTitle] = useState('Online Kosova');
    const handleCancel = () => {
        setShowModal(false);
    };
    const handleCopyLink = () => {
        copy(shareUrl);
        message.success('Link copied to clipboard!');
        setShowModal(false);
    };

    return (
            <Modal
                bodyStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    borderRadius: '0px',
                    height:'300px',
                    width:'100%'
                }}
                className={classes.styledModal}
                destroyOnClose={true}
                okButtonProps={{style: {display: 'none'}}}
                cancelButtonProps={{style: {display: 'none'}}}
                open={showModal}
                onCancel={handleCancel}
                style={{display:'flex', alignItems:'center', justifyContent:'center', width:'80%'}}
                centered
            >
                <div className={classes.shareModal}>
                    <div className={classes.shareModalTitle}>
                        <h2>Ndani këtë me komunitetin tuaj social:</h2>
                    </div>
                    <div className={classes.shareSocialMedia}>
                        <FacebookShareButton url={shareUrl} title={title} className={classes.modalIcon} onClick={()=> setShowModal(false)}>
                            <FaFacebook className={classes.modalIcon} color={'#1877F2'}/>
                        </FacebookShareButton>

                        <LinkedinShareButton url={shareUrl} title={title}  className={classes.modalIcon} onClick={()=> setShowModal(false)}>
                            <FaLinkedin className={classes.modalIcon} color={'#2867B2'}/>
                        </LinkedinShareButton>

                        <TwitterShareButton url={shareUrl} title={title} className={classes.modalIcon} onClick={()=> setShowModal(false)}>
                            <FaTwitter className={classes.modalIcon} color={'#1DA1F2'}/>
                        </TwitterShareButton>

                        <WhatsappShareButton url={shareUrl} title={title} className={classes.modalIcon}  onClick={()=> setShowModal(false)}>
                            <FaWhatsapp className={classes.modalIcon} color={'#25D366'}/>
                        </WhatsappShareButton>

                        <ViberShareButton url={shareUrl} title={title} className={classes.modalIcon} onClick={()=> setShowModal(false)}>
                            <FaViber className={classes.modalIcon} color={'#2867B2'}/>
                        </ViberShareButton>
                    </div>
                    <div onClick={handleCopyLink} className={classes.shareUrlInput}>
                        <h3>ose kopjoni linkun</h3>
                        <Input placeholder={shareUrl} suffix="Copy" disabled/>
                    </div>
                </div>
            </Modal>
    )

}
export default ShareModal;