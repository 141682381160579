import React, { useEffect ,useState} from "react";

import { useSelector, useDispatch } from "react-redux";
import { fetchAllAds, fetchMyAds ,fetchActiveAds,fetchInActiveAds, changeAdsCurrentPage} from "../../redux/actions/adsAction";
import classes from './ADS.module.css'
import Card from '../../components/UI/Card';
import AdsDataTable from "../AdsTable/ADSDataTable";
import type { AppDispatch } from "../../redux/store";
import { selectAdData, selectAdLoading, selectCurrentPageOfAds } from "../../redux/slices/adsSlice";
import noImage from "../../assets/images/noImage.jpg";
import {Button, Pagination} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {BiTrash} from 'react-icons/bi';

import { Select,Space } from 'antd';
import {ColumnsType} from "antd/es/table";
import { activateAd } from "../../redux/actions/adsAction";
import DeleteAdModal from "./DeleteAdModal"
import { selectAuthenticated, selectIsAdmin } from "../../redux/slices/authSlice";
import { RiPencilFill } from "react-icons/ri";
import EditAdsModal from "../../components/Modal/EditAdsModal";
import EditAd from "../EditAd";




interface AdsData {
  id: number;
  key:React.Key;
  title: string;
  dataindex: string;
  width: string;
  textWrap: any;
  ellipsis: boolean;
  content: any;
  nr: number;
  activate:boolean
  
}


const ADSlists: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAdmin = useSelector(selectIsAdmin);
  const AdsData = useSelector(selectAdData);
  const isAuthenticated = useSelector(selectAuthenticated);
  const activeAds=useSelector(selectAdData);
  const inactiveAds=useSelector(selectAdData);
  const adData = useSelector(selectAdData);
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

}

const [activeRowStatus, setActiveRowStatus] = useState({});
const [showEditAdModal, setShowEditAdModal] = useState(false);
const [editingAd, setEditingAd] = useState(null);
const [filterOption, setFilterOption] = useState("all");
const currentPage = useSelector(selectCurrentPageOfAds);
const showEditModal = (record) => {
  setShowEditAdModal(true);
  setEditingAd(record);
};
useEffect(() => {
  setLoading(true);
  if (isAdmin) {
    if (filterOption === "active") {
      dispatch(fetchActiveAds())
        .then(() => setLoading(false));
    } else if (filterOption === "inactive") {
      dispatch(fetchInActiveAds())
        .then(() => setLoading(false));
    } else {
      dispatch(fetchAllAds())
        .then(() => setLoading(false));
    }
  } else {
    if (filterOption === "active") {
      dispatch(fetchActiveAds())
        .then(() => setLoading(false));
    } else if (filterOption === "inactive") {
      dispatch(fetchInActiveAds())
        .then(() => setLoading(false));
       }   else{
    dispatch(fetchMyAds())
      .then(() => setLoading(false));
        }
  }
}, [dispatch, isAdmin,adData?.totalAds]);



const updateAdActiveStatus = (id: number, active: boolean) => {
  dispatch(activateAd({ active: !active }, id))
    .then(() => {
      setActiveRowStatus((prevStatus) => ({
        ...prevStatus,
        [id]: !active,
      }));
    })
    .catch((error) => {
      console.error('Error updating active status:', error);
    });
};




    const [showAddAdPage, setShowAddAdPage] = useState(false);
    const [showDeleteAdModal, setShowDeleteAdModal]= useState(false);
    const [deletingAd, setDeletingAd] = useState(null);
    const [loading, setLoading] = useState(false);
    const showDeleteModal = (id) =>{
        setShowDeleteAdModal(true);
        setDeletingAd(id);
    }


  const columns:ColumnsType<  
    AdsData> = [
      {
        title: 'Imazh',
        dataIndex: 'adImage',
        key: 'adImage',
        width: '6rem',
        render: (adImage, record) => (
          <img
            key={record.id}
            onError={(e: any) => {
              console.error('Error loading image:', e.target.src);
              e.target.onerror = null;
              e.target.src = noImage; // Use a default image URL or handle it as needed
            }}
            src={adImage}
            alt={adImage}
          />
        )
      },
      
    {
        title: 'Linku',
        dataIndex: 'url',
        key: 'url',
        ellipsis: true,
        width:280
    },
    {
        title: 'Paketa ', 
        dataIndex: 'adPackage',
        key: 'adPackage',
        width:150
    },
    {
        title: 'Pershtypjet',
        dataIndex: 'impressionsReached',
        key: 'impressionsReached',
        width:250,
        render: (impressions) => (
          <div>{impressions} Përshtypje</div>
        )
    },
    {
      title: 'Data e publikimit',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:150,
      ellipsis: true,
      render: (publishedDate) => (
        <div>{formatDate(publishedDate)}</div>
    )
    },
    {
      title: 'Çmimi',
      dataIndex: 'price',
      key: 'price',
      width:70,
      render: (price) => (
        <div>{price/100}€ </div>
      )

    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width:100,
      render: (text: string, record: any) => {
        
          return (
            // <div className={classes.actionButtons}>
              <Button
                className={classes.cancelProps}
                type="text"
                disabled={true}
                style={{ color: 'gray' }}
              >
                {record.active ? 'AKTIVE' : 'JOAKTIVE'}
              </Button>
            // </div>
          );
        
      },
    },
    {
      title: 'Veprimet',
      dataIndex: '',
      key: '',
      width:120,
      
      render: (text: string, record: any) => {
    
        if (isAdmin) {
          return (
            <div className={classes.actionButtons}>
              {record.active ?(
                <Button
                  className={classes.cancelProps}
                  type="text"
                  style={{ color: 'gray' }}
                  // onClick={handleDeactivate}
                  disabled={record.active}
                >
                 Inactive
                </Button>
              ) : (
                <Button
                  className={classes.cancelProps}
                  type="text"
                  style={{ color: 'gray' }}
                  onClick={() => updateAdActiveStatus(record.id, record.active)}
                  disabled={record.active}
                >
                  Activate
                </Button>
              )}
              <Button
                className={classes.cancelProps}
                icon={<BiTrash style={{ color: 'red', fontSize: 23  }} onClick={() => showDeleteModal(record.id)} />}
              />
               <Button type="text"
                                    icon={<RiPencilFill style={{color: '#242424', fontSize: 22}}/>}
                                    onClick={()=>showEditModal(record)} />
            </div>
          );
        } else {
          return (
            <div className={classes.actionButtons}>
              {record.active ? (
                <Button
                  className={classes.cancelProps}
                  icon={<BiTrash style={{ color: 'red', fontSize: 23 }} onClick={() => showDeleteModal(record.id)} /> }

                />
                
                
              ) : (
                <Button className={classes.cancelProps} disabled={true} icon={<BiTrash style={{ color: 'gray', fontSize: 23 }} />} />
                
              )}
               <Button type="text"
                                    icon={<RiPencilFill style={{color: '#242424', fontSize: 22}}/>}
                                    onClick={()=>showEditModal(record)} />
            </div>
          );
        }} 

      
    }
  ];   
  const changeCurrentPageOfAds = (page: number) => {
    dispatch(changeAdsCurrentPage(page));
}
    return (
      <div className={classes.postsLists}>
          <Card className={classes.postsListsCard}>
          <DeleteAdModal showModal={showDeleteAdModal} setShowModal={setShowDeleteAdModal} adId={deletingAd}/>
          <EditAd showModal={showEditAdModal} setShowModal={setShowEditAdModal} editingAdRecord={editingAd} />
              <div className={classes.postsListCardHeader}>
                  <div className={classes.postsListCardTitles}>
                    <Space wrap>
                    <Select
                      defaultValue="
                      Të dhënat e reklamave"
                      style={{ width: 230 }}
                      bordered={false}
                      size="large"
                      options={[
                        { value: "all", label: "Të gjitha reklamat" },
                        { value: "active", label: "Reklamat aktive" },
                        { value: "inactive", label: "Reklamat joaktive" },
                      ]}
                      onChange={value => setFilterOption(value)}
                    />

                    </Space>
                  </div>
                  <div style={{display:"flex" ,float:"right",marginRight:"20px"}}>
                  <Button className={classes.addByScrappingButton} type='primary' icon={<PlusOutlined />}  size="large" style={{borderRadius:"0px"}} key='/newad' href='newad'>Krijo reklamë</Button> 
                  </div>
                 
              </div>
            
              {loading && <h2 className={classes.loadingText}>Loading</h2>}
              {AdsData && AdsData.content && (<AdsDataTable data={filterOption === "all" ? AdsData.content : filterOption === "active" ? activeAds.content : inactiveAds.content} headTableData={columns}/> )}
              {AdsData.content === undefined && <h2 className={classes.loadingText}>INCORRECT API REQUEST!</h2>}
              {AdsData.content?.length === 0 && <h2 className={classes.loadingText}>NO SOURCES FOUND! </h2>}
              {/* <Pagination
                        defaultCurrent={currentPage}
                        // defaultPageSize={}
                        total={AdsData.totalPages*10}
                        onChange={(page) => {
                            changeCurrentPageOfAds(page)
                        }}
                        // showSizeChanger={false}
                    /> */}
          </Card>
      </div>
)

 
};

export default ADSlists;
