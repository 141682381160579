import React from "react";
import "./Terms.css";
import logo from "../../../assets/images/logo.png";
import { FaAngleLeft } from "react-icons/fa";

function Faq() {
  const style = { color: "white", fontSize: "15px" };

  return (
    <div className="body-terms">
      <div id="return-button">
        <FaAngleLeft style={style} />
        <a href="/">Back</a>
      </div>
      <div className="content">
        <div className="body-header">
          <h1>
            Step-by-step guide on how to delete your account in <br />
            the Online Kosova app:
          </h1>
        </div>
        <div className="text">
          <div>
            <h1>Login to Your Account:</h1>
            <p>
              ● Open the Online Kosova app on your device. <br />● Log in with
              the registered account for which you want to delete.
            </p>
          </div>
          <br />

          <div>
            <h1>Navigate to the Hamburger Menu:</h1>
            <p>
              ● Once logged in, look for the hamburger menu. This is typically
              represented by three horizontal lines stacked on top of each
              other, located at the top-right corner of the app.
            </p>
          </div>
          <br />

          <div>
            <h1>Locate and Select "Delete Account" Option:</h1>
            <p>
              ● Within the hamburger menu, scroll or navigate to find the
              "Delete Account" option.
            </p>
          </div>
          <br />

          <div>
            <h1>Enter Password Connected to Your Email:</h1>
            <p>
              ● After selecting the "Delete Account" option, the app may prompt
              you to enter the password of your connected email address
              associated with your account.
            </p>
          </div>
          <br />

          <div>
            <h1>Save Changes:</h1>
            <p>
              ● Once you've entered the password, there should be a "Save
              Changes" button. <br />
              Click on it to confirm your decision to delete the account.
            </p>
          </div>
          <br />

          <div>
            <h1>Confirmation Message:</h1>
            <p>
              ● After saving changes, the app should display a confirmation
              message indicating that your account has been deleted.
            </p>
          </div>
          <br />

          <div>
            <h1>Note:</h1>
            <p>
              ● It's crucial to mention that you can only delete your account if
              you are already registered and logged in. <br />
              ● Deleting your account is usually an irreversible action, and all
              associated data may be permanently removed. <br />● If you
              encounter any issues or have questions about the account deletion
              process, it's advisable to contact the app's support or customer
              service for assistance in this email: <br />{" "}
              <a href="mailto:info.onlinekosova@gmail.com">
                info.onlinekosova@gmail.com
              </a>
            </p>
          </div>
          <br />

          <div>
            <h1>The types of data that are deleted:</h1>
            <p>
              ● Email <br />
              ● Name and Surname <br />
              ● Saved Posts <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
