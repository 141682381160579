import { setPackages, setPackagesLoading } from "../slices/packagesSlice";
import { message } from "antd";
import { logoutReducer } from "../slices/authSlice";
import API from '../api'

export const fetchAllPackages = () => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    dispatch(setPackagesLoading(true));
    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}ads/packages`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log("res data", response.data);
    dispatch(setPackages(response.data));
    dispatch(setPackagesLoading(false));
  } catch (err) {
    if (err.response.data.code === 401) {
      message.config({
        maxCount: 1,
      });
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};
