import React, { useEffect, useState } from 'react';
import {  Input, Button, Form,Upload, Card, Radio, message } from 'antd';
import type { UploadProps } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { createAd } from '../redux/actions/adsAction';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classes from "./NewAd.module.css"
import ADpackages from './ADpacakges/ADpackages';
import upload from "../assets/images/upload.png"
import Header from '../components/AdsLayout/HomeHeader/Header'
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


const { Dragger } = Upload;

const NewAD: React.FC = () => {

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  type NewADState = {
    selectedPackage: string;
  };
  

 const [selectedPackage, setSelectedPackage] = useState<string>("");
  
const handlePackageChange = (selectedPackage: string) => {
  if(selectedPackage){
    setSelectedPackage(selectedPackage);
      }
  else {
   const errorMessage = 'Please select a package!';
    console.error(errorMessage);
   } };
    
  const [url, setUrl] = useState('');
  const [adImage, setAdImage] = useState('image.png');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isValidFile, setisValidFile] = useState(true);
  const [isValidURL, setIsValidURL] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [adPackage, setAdPackage] = useState([]);
  const[createdAt,setCreatedAt] =useState([]);
  const navigate = useNavigate();


  const originalAdUrlChangeHandler = (event) => {
    const inputValue = event.target.value;
    setUrl(inputValue);
    if (inputValue === '') {
      setIsValidURL(true)
    } else {
      setIsValidURL(isUrl(inputValue));
    }
  };

  const handleOk = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log('imageUrl', adImage)
    const payload = {
        adImage,
        url,
        adPackage: selectedPackage,

    }
    dispatch(createAd(payload, formData)).then(
        (res) => {
            setUrl('');
            setSelectedPackage("");
            setFileList([]);
            navigate('/adslist');
        }
    );
};



  function isUrl(str) {
    const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    return urlRegex.test(str);
  }

  const props: UploadProps = {

    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    onChange: (info) => {
      const newFileList = [...info.fileList].slice(-1);
      // 2. Read from response and show file link
      newFileList.forEach((file) => {
        if (file.response) {
          setAdImage(file.response.url);
        }
     
      });

      setFileList(newFileList);
      setSelectedFile(newFileList[0]?.originFileObj);
      
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div>
      <Header showAdsCards={false} />
     
        <div style={{paddingBottom:"100px"}}>
           <div style={{paddingBottom:"110px"}}>
            <Form
                preserve={false}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                onFinish={handleOk}
                className={classes.card}
                style={{
                    float: "right",
                    justifyContent: "center",
                    backgroundColor: "#F0F4FC",
                    paddingLeft:"30px",
                    paddingRight:"30px",
                    marginRight: "110px",
                    marginTop: "100px",
                    bottom:"1000px"
                }}>
                <div style={{ justifyContent: "space-evenly", padding: "20px" }}>
                    <h2
                        style={{
                            paddingBottom: "20px",
                            fontWeight: "normal",
                        }} >
                          URL-ja në këtë dosje do ta udhëzojë përdoruesin në 
                        <br />
                        faqen kryesore
                                    të reklamimit
                    </h2>
                    <div className={classes.isValidUrl}>
                        {!isValidURL && (
                            <p style={{ color: 'red' }}>Input is not a valid URL</p>
                        )}
                </div>
                    <Form.Item  name="url" className={classes.formItem} rules={[{ required: true, message: 'Ju lutemi shkruani url-në origjinale të postimit!' }]}>
                    <Input
                        onChange={originalAdUrlChangeHandler}
                        value={url}
                        size="large"
                        placeholder="Shkruani URL-në"
                        id='url'
                        name='url' 
                        style={{
                            width: "661px",
                            borderRadius: 3,
                            marginBottom: "10px",
                            height: "72px",
                        }}
                    />
                    </Form.Item>
                    
                    <div className={classes.App}>
                    <Form.Item name="image" className={classes.formItem} rules={[{ required: true, message: 'Please select an image!' }]}>
                        <div className={classes.fileUploadBox} >
                         
                            <Dragger listType={"text"} {...props} name="image" id="name" >
                                <p className="ant-upload-drag-icon">
                                    <img src={upload} style={{height:"70px",width:"80px"}}/>
                                </p>
                                <p className="ant-upload-text">
                               Merreni dhe zhvendoseni imazhin tuaj ,apo shkarkojeni</p>
                                <p className="ant-upload-hint">
                                Madhësia e imazhit duhet të jetë 1080×1920 më pak se 1 MB
                                </p>
                            </Dragger>
                         
                        </div>
                        </Form.Item>
                    </div>

                    <h2 style={{marginBottom: "30px",fontFamily: "Poppins",fontWeight:"400"}} > Zgjidhni paketën</h2>

                    <div>

                    <ADpackages onPackageChange={handlePackageChange} preSelectedPackage={''}/>
                    {!selectedPackage && <span style={{ color: 'red' }}></span>}
                   </div>
                   <h4
                                    style={{
                                        paddingTop:"20px",
                                      fontSize:"17px",
                                      
                                    
                                    }} >
                                   Informatë!
                                </h4>
                                <h4
                                    style={{
                                        paddingTop:"10px",
                                      fontSize:"17px",
                                      fontStyle:"italic",
                                      fontWeight:300
                                    }} >
                                  Kjo reklamë nuk do të shfaqet në aplikacion derisa administratori ta konfirmojë.
                                </h4>
                    <Button
                   
                       onClick={form.submit }
                       disabled={!isValidURL || !selectedPackage ||!selectedFile }
                       className={classes.button}
                        style={{
                            borderRadius: 5,
                            float: "right",
                            marginTop: "30px",          }}
                            size="large"
                        >
                        Vazhdoni te pagesa <RightOutlined />
                    </Button>
                    
                  </div>
            </Form>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ position: "relative" }}>
                <div className={classes.phoneContainer}>
                  {selectedFile ? (
                    <div>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="ad image"
                      className={classes.uploadImageBox}
                      style={{backgroundSize:"cover"}}
                    />
                     <div className={classes.phoneContainer} style={{top:"0px"}}/>
                     </div>
                   ) : (
                   <div/>
                  )}
         </div>

       </div>
    </div>
</div>
</div>
);};

export default NewAD;

