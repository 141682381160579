import { setPost, setPostsCurrentPage } from "../slices/postSlice";

import { message } from "antd";
import { logoutReducer } from "../slices/authSlice";
import API from "../api";

export const changePostsCurrentPage = (page) => async (dispatch, getState) => {
  dispatch(setPostsCurrentPage(page));
};

export const createAlbanianPost =
  (payload, imagePayload) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = { Authorization: "Bearer " + token };
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}posts`,
        payload,
        { headers: headers }
      );

      if (response.status === 200) {
        if (imagePayload) {
          dispatch(
            postAlbanianImageUpload(imagePayload, response.data.id)
          ).then((res) => {
            message.success("POST ADDED!");
            dispatch(changePostsCurrentPage(1));
            dispatch(fetchAlbanianPosts(getState().postsData.currentPage));
          });
        } else {
          message.success("POST ADDED!");
          dispatch(changePostsCurrentPage(1));
          dispatch(fetchAlbanianPosts(getState().postsData.currentPage));
        }
      } else {
        message.info("Please try again, post not added!");
      }
    } catch (err) {
      message.error(err.response.data.message);
      throw new Error(err);
    }
  };

export const fetchAlbanianPosts = (page) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;

    const response = await API.get(
      `${process.env.REACT_APP_BACKEND_API}v3/posts?country=AL&page=${
        page - 1
      }`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    console.log(response.data);
    dispatch(setPost(response.data));
  } catch (err) {
    if (err.response.data.code === 401) {
      dispatch(logoutReducer());
    }
    throw new Error(err);
  }
};

export const postAlbanianImageUpload =
  (payload, id) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };
      const response = await API.post(
        `${process.env.REACT_APP_BACKEND_API}upload/${id}`,
        payload,
        { headers: headers }
      );
      console.log(response);
    } catch (err) {
      throw new Error(err);
    }
  };

export const deleteAlbanianPost = (payload) => async (dispatch, getState) => {
  try {
    const token = getState().user.token;
    const headers = {
      Authorization: "Bearer " + token,
    };
    const response = await API.delete(
      `${process.env.REACT_APP_BACKEND_API}posts/${payload}`,
      { headers }
    );
    if (response.status === 200) {
      message.success("Posti eshte fshire!");
      dispatch(fetchAlbanianPosts(getState().postsData.currentPage));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAlbanianPost =
  (payload, imagePayload) => async (dispatch, getState) => {
    console.log("OK asd", getState().postsData.currentPage);
    try {
      const token = getState().user.token;
      const headers = {
        Authorization: "Bearer " + token,
      };

      const response = await API.put(
        `${process.env.REACT_APP_BACKEND_API}posts/${payload.id}`,
        payload,
        { headers }
      );

      if (response.status === 200) {
        console.log("___________________________________", response);
        if (imagePayload) {
          dispatch(postAlbanianImageUpload(imagePayload, payload.id)).then(
            (res) => {
              message.success("POST edited!");
              dispatch(fetchAlbanianPosts(getState().postsData.currentPage));
            }
          );
        } else {
          message.success("POST edited!");
          dispatch(fetchAlbanianPosts(getState().postsData.currentPage));
        }
      } else {
        message.info("Please try again!");
      }
    } catch (err) {
      if (err.response.status >= 400) {
        message.info("Posti nuk eshte ndryshu!");
      }
      throw new Error(err);
    }
  };

export const searchAlbanianPosts =
  (page, search) => async (dispatch, getState) => {
    try {
      const token = getState().user.token;

      const response = await API.get(
        `${process.env.REACT_APP_BACKEND_API}posts?page=${
          page - 1
        }&title=${search}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      console.log(response.data);
      dispatch(setPost(response.data));
    } catch (err) {
      if (err.response.data.code === 401) {
        dispatch(logoutReducer());
      }
      throw new Error(err);
    }
  };
