import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import classes from "./AdsCards.module.css";
import activeads from "../../../assets/images/activeads.png";
import totalimp from "../../../assets/images/totalimp.png"
import impression from "../../../assets/images/impression.png";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardStats } from '../../../redux/actions/adsAction';
import { AppDispatch } from '../../../redux/store';
import { selectCardStats } from '../../../redux/slices/cardSlice';

interface CardData {
  title: string;
  image: string;
  description: string | number;
}

const initialCardData: CardData[] = [
  {
    title: "Numri total i reklamave",
    image: activeads,
    description: "Loading...",
  },
  {
    title: "Numri i reklamave aktive",
    image: totalimp,
    description: "Loading...", 
  },
  {
    title: "Numri total i përshtypjeve",
    image: impression,
    description: "Loading...", 
  },
];

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cardStats = useSelector(selectCardStats);
  const [cardData, setCardData] = React.useState<CardData[]>(initialCardData);

  useEffect(() => {
    // Fetch card statistics data when the component mounts
    dispatch(fetchCardStats());
  }, [dispatch]);


  useEffect(() => {
    // Update card data when cardStats change
    if (cardStats) {
      const updatedCardData = [
        {
          ...cardData[0],
          description: cardStats.totalAds,
        },
        {
          ...cardData[1],
          description: cardStats.totalActiveAds,
        },
        {
          ...cardData[2],
          description: cardStats.totalImpressionsReached,
        },
      ];
      setCardData(updatedCardData);
    }
  }, [cardStats]);

  return (
    <div className={classes.antCard}>
      <Row gutter={16} className={classes.sitecardwrapper}>
        {cardData.map((card: CardData) => (
          <Col span={6} key={card.title}>
            <Card
              title={card.description}
              bordered={false}
              className={classes.card}
              extra={
                <img
                  src={card.image}
                  style={{
                    width: "3.5rem",
                    height: "6rem",
                    justifyContent: "center",
                    display: "flex",
                    alignContent: "center",
                    textAlign: "center",
                    paddingTop: '40px'
                  }}
                  alt={card.title}
                />
              }
              headStyle={{
                backgroundColor: '#E6F5FA',
                borderRadius: 0,
                fontSize: "20px"
              }}
              bodyStyle={{
                backgroundColor: '#E6F5FA',
                borderRadius: 0,
                fontSize: "20px"
              }}
            >
              <p className={classes.p}>{card.title}</p>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default App;


