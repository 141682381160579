import {createSlice} from "@reduxjs/toolkit";
import {CategoryModel} from "./categoriesSlice";
import {SourceModel} from "./sourceSlice";

export interface latestPostByCategoryModel {
    id: string;
    subTitle: string;
    title: string;
    name: string;
    sourceImage: string;
    originalPostUrl: string;
    imageUrl: string;
    categories: CategoryModel[];
    source: SourceModel;
    country:string;
    publishedDate: string;
}


interface LatestPostByCategoryState {
    data: {
        content: latestPostByCategoryModel[];
    }
}

const initialLastPostByCategoryDataState : LatestPostByCategoryState = {
    data: {
        content: [],
    },
}


export const latestPostByCategorySlice = createSlice({
    name: 'latestPostByCategoryData',
    initialState: initialLastPostByCategoryDataState,
    reducers: {
        setLatestPostByCategory: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLatestPostByCategory} = latestPostByCategorySlice.actions;
export const selectLatestPostByCategory = (state: {latestPostByCategoryData: LatestPostByCategoryState}) => state.latestPostByCategoryData.data;

export default latestPostByCategorySlice.reducer;
