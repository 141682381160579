
import {message} from 'antd'
import API from '../api'

export const verifyEmail = (verifyToken) => async (dispatch, getState) => {
    try {
        const response = await API.put(`${process.env.REACT_APP_BACKEND_API}users/verify/${verifyToken}`);
        console.log(response.data);
    } catch (err) {
            message.error('Email is not verified, please try again!')
        throw new Error(err);
    }
};
